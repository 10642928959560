import React, {useState, useEffect} from 'react';
import {Card, message, Button, Input, Form, Divider, Row, Col} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import {connect} from 'react-redux';
import './index.css'

interface IProps extends RouteComponentProps {
  allRole: any[]
}

//s12
const GeneralConfig: React.FC<IProps> = (props: IProps) => {
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [integralData, setintegralData] = useState<any>({});

  const initData = async () => {
    const res = await Request.getData()
    let {data} = res
    setintegralData(data)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initData()
  }, [])
  const onFinish = (formVal: any) => {
    let obj = {"config_value": JSON.stringify(formVal)}
    Request.edit(obj).then((res) => {
      if (res.code == 0) {
        message.success("保存成功")
        initData()
      } else {
        message.error(res.msg)
      }
    })
  };

  return (
      <>
        <div className="diamonds">
          <Skeleton loading={showSkeleton}>
            <Card className="mb15">
              <Form
                  labelCol={{span: 8}}
                  wrapperCol={{span: 16}}
                  onFinish={onFinish}
                  requiredMark={false}
                  initialValues={{...integralData}}
              >
                <div className="public-title">
                  <span>余额服务费配置</span>
                  <Button type="primary" htmlType="submit">保存</Button>

                </div>
                <Divider/>
                <Row className="sign">
                  <Col>提现手续费</Col>
                  <Col>
                    <Form.Item name="balance_service_fee_rate">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>，每日可提现次数</Col>
                  <Col>
                    <Form.Item name="balance_withdraw_day_count">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}}/>
                    </Form.Item>
                  </Col>
                  <Col>次（0为不限制），</Col>
                  <Col>最小数量</Col>
                  <Col>
                    <Form.Item name="balance_withdraw_min_fee">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}}/>
                    </Form.Item>
                  </Col>
                  <Col>元</Col>
                </Row>
                <br/>
                <div className="public-title">
                  <span>店铺提现服务费</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>手续费</Col>
                  <Col>
                    <Form.Item name="store_balance_service_fee_rate">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>，每日可提现次数</Col>
                  <Col>
                    <Form.Item name="store_balance_withdraw_day_count">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}}/>
                    </Form.Item>
                  </Col>
                  <Col>次（0为不限制），</Col>
                  <Col>最小数量</Col>
                  <Col>
                    <Form.Item name="store_balance_withdraw_min_fee">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}}/>
                    </Form.Item>
                  </Col>
                  <Col>元</Col>
                </Row>
                <br/>
                <div className="public-title">
                  <span>余额转绿色积分</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>手续费</Col>
                  <Col>
                    <Form.Item name="balance_to_green_integral_service_fee_rate">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>最小数量</Col>
                  <Col>
                    <Form.Item name="balance_to_green_integral_min_free">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} min={0}/>
                    </Form.Item>
                  </Col>
                  <Col>(0代表不限制)</Col>
                </Row>
                <br/>
                <div className="public-title">
                  <span>绿色积分转余额</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>手续费</Col>
                  <Col>
                    <Form.Item name="green_integral_to_balance_service_fee_rate">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>最小数量</Col>
                  <Col>
                    <Form.Item name="green_integral_to_balance_min_free">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} min={0}/>
                    </Form.Item>
                  </Col>
                  <Col>(0代表不限制)</Col>
                </Row>
                <br/>
                <div className="public-title">
                  <span>绿色积分转好友</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>手续费</Col>
                  <Col>
                    <Form.Item name="green_integral_to_friends_fee_rate">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>最小数量</Col>
                  <Col>
                    <Form.Item name="green_integral_to_friends_min_free">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} min={0}/>
                    </Form.Item>
                  </Col>
                  <Col>(0代表不限制)</Col>
                </Row>

                <br/>
                <div className="public-title">
                  <span>店铺余额转绿色积分</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>手续费</Col>
                  <Col>
                    <Form.Item name="store_balacen_to_green_integral_rate">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>最小数量</Col>
                  <Col>
                    <Form.Item name="store_balacen_to_green_integral_min_free">
                      <Input type="number" style={{width: "150px", margin: "0 8px"}} min={0}/>
                    </Form.Item>
                  </Col>
                  <Col>(0代表不限制)</Col>
                </Row>
              </Form>
            </Card>
          </Skeleton>
        </div>
      </>
  );
};
const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(GeneralConfig)