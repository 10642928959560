import axios from '../../../axios'

function getList(params: any) {
  return axios.ajax({
    url: '/store/getStoreList',
    method: 'post',
    data: params,
  })
}

function add(params: any) {
  return axios.ajax({
    url: '/store/addGood',
    method: 'post',
    data: params,
  })
}

function edit(params: any) {
  return axios.ajax({
    url: '/store/editGood',
    method: 'post',
    data: params,
  })
}

function updateStoreAdminStatus(params: any) {
  return axios.ajax({
    url: '/store/updateStoreAdminStatus',
    method: 'post',
    data: params,
  })
}

function getGoodInfo(params: { good_id: string | number }) {
  return axios.ajax({
    url: '/store/getGoodInfo',
    method: 'post',
    data: params,
  })
}

function deleteStore(params: any) {
  return axios.ajax({
    url: '/store/deleteStore',
    method: 'post',
    data: params,
  })
}

function updateFalseSaleNumber(params: any) {
  return axios.ajax({
    url: '/store/falseSaleVolumeGood',
    method: "post",
    data: params
  })
}

function updateStore(params: any) {
  return axios.ajax({
    url: '/store/updateStore',
    method: "post",
    data: params
  })
}

function updateStoreFreeze(params: any) {
  return axios.ajax({
    url: '/store/updateStoreFreeze',
    method: "post",
    data: params
  })
}

function getStoreGoodList(params: any) {
  return axios.ajax({
    url: '/store/getStoreGoodList',
    method: "post",
    data: params
  })
}

function auditStatus(params: any) {
  return axios.ajax({
    url: '/store/auditStatus',
    method: "post",
    data: params
  })
}

export default {
  getList,
  add,
  edit,
  updateStoreAdminStatus,
  getGoodInfo,
  deleteStore,
  updateFalseSaleNumber,
  updateStore,
  updateStoreFreeze,
  getStoreGoodList,
  auditStatus,
}
