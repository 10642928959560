import axios from "../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/order/getOrderList',
        method: "post",
        data: params,
    })
}
function importOrderUpdateData(params: any) {
    return axios.ajax({
        url: '/order/importOrderUpdate',
        method: "post",
        data: params,
    })
}
function getActDetails(params: any) {
    return axios.ajax({
        url: '/order/getOrderInfo',
        method: "post",
        data: params,
    })
}
function getLogisticsData(params: any) {
    return axios.ajax({
        url: '/order/getExpress',
        method: "post",
        data: params,
    })
}
//发货
function editOrderExpress(params: any) {
    return axios.ajax({
        url: '/order/orderDelivery',
        method: "post",
        data: params,
    })
}
function updateOrderExpress(params: any) {
    return axios.ajax({
        url: '/order/updateExpress',
        method: "post",
        data: params,
    })
}
//修改发货地址
function editaddress(params: any) {
    return axios.ajax({
        url: '/order/editOrderAddress',
        method: "post",
        data: params,
    })
}

function refundOrder(params: any) {
    return axios.ajax({
        url: '/order/refundOrder',
        method: "post",
        data: params,
    })
}
export default {
    getList,
    getActDetails,
    getLogisticsData,
    editOrderExpress,
    updateOrderExpress,
    editaddress,
    importOrderUpdateData,
    refundOrder
}