import React, {useRef, useState, useEffect} from 'react';
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Radio,
  Select,
  Button,
  Modal,
  Table,
  Popconfirm,
  message,
  Pagination,
  Breadcrumb,
} from "antd";
import SkeletonDetail from "../../../components/Skeleton/SkeletonDetail"
import Request from "./Request"
import {RouteComponentProps} from "react-router-dom";
import ProTable, {ActionType} from "@ant-design/pro-table";
import utils from "../../../utils/utils";
import {
  getCategory
} from '@src/config/getAsyncConfig';
import './index.less'

interface IProps extends RouteComponentProps {
  allRole: any[];
  dispatch: any;
  tabs: any;
  dropScope: any;
}

const requiredSpan = <span style={{color: "red"}}>*</span>;
let GOOD_CHECK_ROW: any[] = [];
const AppDetail: React.FC<IProps> = (props: IProps) => {
  const redref = useRef<ActionType>();

  //@ts-ignore
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [isView, setisView] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<any>({
    cover_img: ""
  })
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [goodList, setGoodList] = useState<any[]>([{}]);
  const [TBgoodList, setTBGoodList] = useState<any[]>([]);
  const [count, setCount] = useState<any>();
  const [goodVisible, setGoodVisible] = useState<boolean>(false);
  const [selectedGoodRows, setSelectedGoodRows] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<any>();
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [redGoodVisible, setRedGoodVisible] = useState<boolean>(false);

  const [pageNo, setPageNo] = useState<any>(10);
  const [current, setCurrent] = useState<any>(1);
  useEffect(() => {
    getCategory().then(res => {
      setCategoryList(res.data)
    })
    func()
  }, [])
  //同步hooks
  const func = utils.useSyncCallback(() => {
    getDetails()
  });
  const getDetails = async () => {
    let res = await Request.getAppConfigInfo({"type": 4});
    let detailData = res.data
    if (!detailData.cover_img) {
      detailData.cover_img = ''
    }

    setDetailData(detailData)
    form.setFieldsValue(detailData);
    setShowSkeleton(false)
    getgoodList()
  }
  //获取活动商品
  const getgoodList = utils.useSyncCallback(async () => {
    let obj = {
      pageSize: pageNo,
      current: current,
      type: 4,
    }
    const res = await Request.getGoodRedNewList(obj);
    let TBarr = [...TBgoodList]
    if (res.data.list) {
      res.data.list.forEach(item => {
        TBarr.forEach(ele => {
          if (ele.id == item.id) {
            item.sort = ele.sort
          }
        })
      })
    }
    setGoodList(res.data.list)
    setCount(res.data.count)
  })
  //表单提交
  const goodFinish = async (formVal: any) => {
    let res = await Request.editAppConfig({config_value: JSON.stringify(formVal), type: 4});
    message.success("保存成功")
    window.history.back()
  }
  //删除
  const delGoodList = async (index: number) => {
    let newGood = [...goodList];
    newGood.splice(index, 1);
    Request.deleteRedGood({ids: goodList[index].id}).then(res => {
      if (res.code == 0) {
        message.success("删除成功")
        setSelectedGoodRows([]);
        getgoodList()
      } else {
        message.success(res.msg)
      }
    })
    setGoodList(newGood);
  };
  const getMenu = (_: any, item: any) => {
    const {menuRender} = utils;
    let index
    index = goodList.findIndex((good) => good.store_id === item.store_id);
    let menu = [];
    menu.push(
        <Popconfirm
            title="确定要删除吗?"
            onConfirm={() => {
              delGoodList(index);
            }}
        >
          <Button
              danger
              type="link"
              style={{padding: 0}}
              className="color-danger"
          >
            删除
          </Button>
        </Popconfirm>
    );
    return <>{menuRender(menu)}</>
  };

  const rowSelectionTable = {
    selectedGoodRows,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedGoodRows(selectedRowKeys)
    },
  }

  //批量删除
  const del = async () => {
    let id_arr: any;
    if (!selectedGoodRows) {
      message.error("请选择需要操作的数据");
      return;
    }
    id_arr = selectedGoodRows
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: "danger",
      onOk() {
        Request.deleteRedGood({ids: id_arr.toString()}).then(res => {
          if (res.code == 0) {
            message.success("删除成功")
            setSelectedGoodRows([]);
            getgoodList()
          } else {
            message.success(res.msg)
          }
        })
      },
    });
  }

  const selectGood = () => {
    setGoodVisible(true);
    form2.setFieldsValue({
      q: '',
      category_id: '',
      material_id: '',
    })
  };


  const pageChange = (val, value2) => {
    setPageNo(value2)
    setCurrent(val)
    getgoodList()
  }

// 保存活动商品权重
  const saveGood = async () => {
    let res
    let arr = [...TBgoodList]
    res = await Request.editSort({ids: JSON.stringify(arr)});
    if (res.code == 0) {
      message.success("保存成功")
      window.history.back()
    } else {
      message.success(res.msg)
    }

  }
  //打开红包商品弹窗
  const redGoodVisibleModal = () => {
    setSelectedRows([])
    GOOD_CHECK_ROW = [];
    setRedGoodVisible(!redGoodVisible)
  }

  //选择红包商品添加
  const aditSubmit = async () => {
    GOOD_CHECK_ROW.forEach(item => {
      item.sort = 0
      delete item.store_image
      delete item.store_name
      delete item.business_hours_start
      delete item.business_hours_end
      delete item.category_name
    })
    let obj = {
      json_data: JSON.stringify(GOOD_CHECK_ROW),
      type: 4
    }
    const res = await Request.editGoodRedNewList(obj);
    if (res.code == 0) {
      redGoodVisibleModal()
      setGoodVisible(false)
      getgoodList()
    }
  };


  const redWeightChange = async (v, index, record) => {
    let arr = [...goodList]
    let TBarr = [...TBgoodList]
    v.persist()
    arr[index].store_sort = v.target.value
    setGoodList(arr)
    let num = TBarr.findIndex((n) => n.id == record.id)
    if (num == -1) {
      TBarr.push({id: record.id, store_sort: v.target.value})
    } else {
      TBarr[num].store_sort = v.target.value
    }
    setTBGoodList(TBarr)
  }
  const columns = [
    {title: 'ID', dataIndex: 'id', width: 40},
    {
      title: '店铺信息', width: 220, render: (_, record) => {
        return <div className="content">
          <img src={record.store_image ? record.store_image : require("../../../resources/assets/none.png")} alt=""
               style={{width: "50px", height: "50px"}}/>
          <div style={{width: "300px"}}>
            <div title={record.store_name} className="white-space1">{record.store_name}</div>
          </div>
        </div>
      }
    },
    {
      title: '分类', width: 150, render: (_, record) => {
        return <div>{record.category_name}</div>
      }
    },
    {title: '店铺开始营业时间', dataIndex: 'business_hours_start', width: 150},
    {title: '店铺结束营业时间', dataIndex: 'business_hours_end', width: 150},
    {title: '创建时间', dataIndex: 'created_at', width: 140},
    {
      title: "权重", width: 120, dataIndex: "store_sort", render: (value, record, index) => {
        return <Input value={value} onChange={v => redWeightChange(v, index, record)}></Input>
      }
    },
    {title: '操作', width: 120, dataIndex: 'store_id', render: getMenu}
  ];

  const redModalcolumns = [
    {
      title: "店铺名称",
      dataIndex: "store_name",
      hideInTable: true,
      renderFormItem: () => {
        return (
            <Input/>
        );
      },
    },
    {title: 'ID', width: 30, dataIndex: 'store_id', search: false},
    {
      title: '店铺', width: 220, search: false, render: (_, record) => {
        return <div className="content">
          <img src={record.store_image ? record.store_image : require("../../../resources/assets/none.png")} alt=""
               style={{width: "50px", height: "50px"}}/>
          <div style={{width: "300px", margin: "10px 0px 0px 0px"}}>
            <div title={record.store_name} className="white-space1">{record.store_name}</div>
          </div>
        </div>
      }
    },
    {
      title: '分类', width: 120, render: (_, record) => {
        return <div>{record.category_name}</div>
      }
    },
    {
      title: "分类",
      width: 60,
      hideInTable: true,
      dataIndex: "category_id",
      renderFormItem: () => {
        return (
            <Select allowClear showSearch optionFilterProp="children" placeholder="请选择">
              {utils.getOptionList(categoryList, "category_id", "category_name")}
            </Select>
        );
      },
    },
    {title: '店铺开始营业时间', width: 80, dataIndex: 'business_hours_start', search: false},
    {title: '店铺结束营业时间', width: 80, dataIndex: 'business_hours_end', search: false},
  ];

  const formItemLayout = {
    labelCol: {span: 5, offset: 3},
    wrapperCol: {span: 12},
  };

  const onSelectRedGood = (selected: any, changeRows: any, checkAll: boolean) => {
    if (selected) {
      GOOD_CHECK_ROW = [...GOOD_CHECK_ROW, ...changeRows];
    } else {
      if (checkAll) {
        GOOD_CHECK_ROW = [];
      } else {
        changeRows.forEach((item: any) => {
          let index = GOOD_CHECK_ROW.findIndex((i) => {
            return i.store_id == item.store_id;
          });
          GOOD_CHECK_ROW.splice(index, 1);
        });
      }
    }

    setSelectedRows(GOOD_CHECK_ROW.map((i) => i.store_id));
  };

  const RedgoodRowSelection = {
    selectedRowKeys: selectedRows,
    onSelect: (record: any, selected: any) => {
      onSelectRedGood(selected, [record], false);
    },
    onSelectAll: (selected: any, record: any, changeRows: any) => {
      onSelectRedGood(selected, changeRows, true);
    },
  };
  return (
      <>
        <PageHeader
            title="以太热搜"
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>运营</Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="#">APP板块</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>APP板块详情</Breadcrumb.Item></Breadcrumb>}
        >

        </PageHeader>
        <div className="wrapContent">
          <SkeletonDetail loading={showSkeleton}>
            <Form requiredMark={false}  {...formItemLayout}
                  onFinish={goodFinish}
                  scrollToFirstError={true}
                  form={form}
                  initialValues={{...detailData}}
            >
              <Card title="基本信息" className="mb15">
                <Row>
                  <Col span={8}>
                    <Form.Item label="是否显示" name="switch">
                      <Radio.Group disabled={isView}>
                        <Radio value="0">显示</Radio>
                        <Radio value="1">隐藏</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button type="primary" htmlType="submit" disabled={isView}>保存</Button>
                </div>
              </Card>

              <Card title="店铺" className="mb15"
                    extra={<Button className="public-title-button" style={{background: "#41CDE0"}} onClick={() => {
                      selectGood();
                    }}>新增店铺</Button>}>
                <Button key="del" className="mb15" danger onClick={() => del()}>批量删除</Button>
                <Table rowKey="id"
                       className="mb20"
                       columns={columns}
                       rowSelection={{
                         ...rowSelectionTable,
                       }}
                       scroll={{y: 540}}
                       dataSource={goodList}
                       pagination={false}
                />
                <Pagination showQuickJumper showSizeChanger
                            showTotal={(total, range) => `第${range[0]}-${range[1]}条/总共${total} 条`} current={current}
                            pageSize={pageNo} className="flex flex-end" total={count} onChange={pageChange}/>
              </Card>

              <div className="text-center">
                <Button onClick={() => window.history.back()} className="mr20" disabled={isView}>返回</Button>
                <Button type="primary" onClick={saveGood} disabled={isView}>保存（权重数据）</Button>
              </div>
            </Form>

            <Modal
                destroyOnClose
                title="店铺选择"
                visible={goodVisible}
                onCancel={() => setGoodVisible(!goodVisible)}
                footer={false}
                width={1300}>

              <ProTable
                  rowKey="store_id"
                  tableStyle={{padding: '0 24px 0 24px'}}
                  columns={redModalcolumns}
                  actionRef={redref}
                  scroll={{y: 540}}
                  rowSelection={RedgoodRowSelection}
                  request={async (params: any) => {
                    if (!params.category_id) {
                      delete params.category_id
                    }
                    params.type = 4
                    const res = await Request.getStoreList1(params);
                    return {
                      data: res.data.list,
                      total: res.data.count,
                      success: true,
                      pageSize: params.pageSize,
                      current: params.pageSize
                    }
                  }
                  }
                  toolBarRender={false}
                  pagination={{
                    defaultPageSize: 10,
                  }}
                  options={{density: false, fullScreen: false, reload: false, setting: false}}
              />
              <div style={{textAlign: "center"}}>
                <Button onClick={() => redGoodVisibleModal()}>取消</Button>
                <Button
                    onClick={() => {
                      aditSubmit();
                    }}
                    disabled={GOOD_CHECK_ROW.length == 0}
                    type="primary"
                    style={{marginLeft: "20px"}}
                >
                  确认
                </Button>
              </div>
            </Modal>
          </SkeletonDetail>
        </div>
      </>
  );
};

export default AppDetail