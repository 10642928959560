import axios from "../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/record/getRecordStoreWithdrawList',
        method: "post",
        data: params,
        isShowLoading: false
    })
}

function edit(params: {
    order_id: string | number,
    status: string | number,
    remark: string
}) {

    return axios.ajax({
        url: '/record/doStoreWithdraw',
        method: "post",
        data: params
    })
}

function revocation(params: any) {
    return axios.ajax({
        url: '/record/storeWithdrawRevocation',
        method: "post",
        data: params,
        isShowLoading: false
    })

}

export default {
    getList,
    edit,
    revocation
}