// src/components/NavLeft/index.js
import React, { useEffect, useState } from "react";
import { Menu } from "antd"; //导入子组件菜单
import { NavLink } from "react-router-dom";
import "./index.less";
import { connect } from "react-redux"; // 连接器
import { saveConfig, switchTabs } from "./../../redux/action"; //事件行为
import Utils from "../../utils/utils";
import Icon from "../Icon"
import Request from "./Request"
const SubMenu = Menu.SubMenu;

interface IsProps {
  tabs?: any
  dispatch: any
}

const NavLeft: React.FC<IsProps> = (props: IsProps) => {
  const [funData, setfunData] = useState<any>([])
  const [Key, setKey] = useState<any>([])
  const [menuTreeNode, setMenuTreeNode] = useState<any>([])
  const [menuTreeNode2, setMenuTreeNode2] = useState<any>([])
  const handleClick2 = (obj:any) =>{
    let arr = []
    funData.forEach(item => {
      if(item.ename == obj.key){
        arr.push(item)
        setKey([item.ename])
        const menuTreeNode = renderMenu(arr);
        setMenuTreeNode(menuTreeNode)
        func()
      }
    });   
  }
  useEffect(() => {
    
    Request.getMyfun().then(res => {
      if (res.code === 0) {
        const { dispatch } = props;
        dispatch(saveConfig(res));
        setfunData(res.data)
        //通过递归(遍历)实现菜单(是一个List)的渲染
        const menuTreeNode2 = renderMenu2(res.data);
        //通过setState存入state
        setMenuTreeNode2(menuTreeNode2)
        // let obj={
        //   key:res.data[0].key
        // }
        // let arr = []
        // res.data.forEach(item => {
        //   if(item.key == obj.key){
        //     arr.push(item)
        //     setKey([item.key.toString()])
        //     const menuTreeNode = renderMenu(arr);
        //     setMenuTreeNode(menuTreeNode)
        //   }
        // }); 
        getOpenKeys()
      }
    })
  }, [])
  //菜单渲染
  const renderMenu = (data: any) => {
    // let hash = window.location.pathname
    let hash = window.location.hash.substr(1)
    let index = hash.indexOf("?")
    if (index !== -1) hash = hash.substring(0, index)
    if (data && data.length > 0) {
      const { dispatch } = props;
      return data.map((item: any) => {
        if (hash === item.ename) {
          dispatch(switchTabs({ key: item.ename, title: item.title }));
        }
        if (hash.indexOf("/good/copy") !== -1) {
          dispatch(switchTabs({ key: hash, title: "复制商品到团长专区" }));
        }
        if (hash.indexOf("/good/detail") !== -1) {
          dispatch(switchTabs({ key: hash, title: "商品详情" }));
        }
        if (hash.indexOf("/marketing/flash_good") !== -1) {
          dispatch(switchTabs({ key: hash, title: "抢购商品列表" }));
        }
        //如果item有子元素,遍历自己,再次调用,直到子节点加载完毕
        if (item.children) {
          return (
            <Menu.ItemGroup  title={item.title} key={item.key}>
              {renderMenu(item.children)}
            </Menu.ItemGroup>
          );
        }
        return (
          <Menu.Item title={item.title}   key={item.ename}>
            <NavLink to={item.ename}>{item.title}</NavLink>
          </Menu.Item>
        );
      });
    }
  };
  //菜单渲染
  const renderMenu2 = (data: any) => {
    // let hash = window.location.pathname
    let hash = window.location.hash.substr(1)
    let index = hash.indexOf("?")
    if (index !== -1) hash = hash.substring(0, index)
    if (data && data.length > 0) {
      const { dispatch } = props;    
      return data.map((item: any) => {
        if (hash === item.ename) {
          dispatch(switchTabs({ key: item.ename, title: item.title }));
        }
        if (hash.indexOf("/good/copy") !== -1) {
          dispatch(switchTabs({ key: hash, title: "复制商品到团长专区" }));
        }
        if (hash.indexOf("/good/detail") !== -1) {
          dispatch(switchTabs({ key: hash, title: "商品详情" }));
        }
        if (hash.indexOf("/marketing/flash_good") !== -1) {
          dispatch(switchTabs({ key: hash, title: "抢购商品列表" }));
        }
        //如果item有子元素,遍历自己,再次调用,直到子节点加载完毕
          return (
            <Menu.Item icon={<Icon style={{ color: "#999", fontSize: "15px" }} type={item.icon} />}  className={Key[0]==item.ename?"titleselect":''} title={item.title} key={item.ename}>
              {/* {renderMenu(item.children)} */}
              {item.title}
            </Menu.Item>
          );
      });
    }
  };
  const homeHandleClick = () => {
    const { dispatch } = props
    dispatch(switchTabs({ key: "/home", title: "首页" }))
  }

  const handleClick = (obj: any) => {
    const { dispatch } = props
    dispatch(switchTabs({ key: obj.key, title: obj.item.props.title }))
  }

  const getOpenKeys = Utils.useSyncCallback(() => {
    // let hash = window.location.pathname
    let hash = window.location.hash
    let key = hash.substring(hash.indexOf("/") + 1, hash.indexOf("/", 2))
    setKey([key])
    let arr = []
    funData.forEach(item => {
      if(item.ename == key){
        arr.push(item)
        // setKey([item.key])
        const menuTreeNode = renderMenu(arr);
        setMenuTreeNode(menuTreeNode)
      }
    });
    func()
  })
  
  //同步hooks
  const func = Utils.useSyncCallback(() => {
    const menuTreeNode2 = renderMenu2(funData);
    setMenuTreeNode2(menuTreeNode2)
  });
  let { activeKey } = props.tabs
  return (
    <div className="nav-left">
      <Menu
        className="nav-menu nav-first"
        // defaultOpenKeys={getOpenKeys()}
        onClick={handleClick2}
        selectedKeys={[activeKey]}
        theme="dark"
        mode="inline"
      >
        {menuTreeNode2}
      </Menu>
      <Menu
        className="nav-menu nav-second"
        // defaultOpenKeys={getOpenKeys()}
        onClick={handleClick}
        openKeys={Key}
        selectedKeys={[activeKey]}
        theme="dark"
        mode="inline"
      >
        {menuTreeNode}
      </Menu>
    </div>
  );

}

const mapStateToProps = (state: { tabs: any; }) => {
  return {
    tabs: state.tabs
  };
};

export default connect(mapStateToProps)(NavLeft);
