import axios from '../../../axios'

function getList(params: any) {
  return axios.ajax({
    url: '/good/getStoreGoodList',
    method: 'post',
    data: params,
  })
}

function add(params: any) {
  return axios.ajax({
    url: '/good/addGood',
    method: 'post',
    data: params,
  })
}

function edit(params: any) {
  return axios.ajax({
    url: '/good/editGood',
    method: 'post',
    data: params,
  })
}

function adminStatus(params: any) {
  return axios.ajax({
    url: '/good/adminStatus',
    method: 'post',
    data: params,
  })
}

function auditStatus(params: any) {
  return axios.ajax({
    url: '/good/auditStatus',
    method: 'post',
    data: params,
  })
}


function getStoreGoodInfo(params: { store_good_id: string | number }) {
  return axios.ajax({
    url: '/good/getStoreGoodInfo',
    method: 'post',
    data: params,
  })
}

function goodDel(params: { good_id: string | number }) {
  return axios.ajax({
    url: '/good/deleteGood',
    method: 'post',
    data: params,
  })
}

function updateFalseSaleNumber(params: any) {
  return axios.ajax({
    url: '/good/falseSaleVolumeGood',
    method: "post",
    data: params
  })
}

export default {
  getList,
  add,
  edit,
  adminStatus,
  getStoreGoodInfo,
  auditStatus,
  updateFalseSaleNumber
}
