import React, {useState, useEffect} from 'react';
import {Card, message, Button, Form, Divider, Row, Col, Switch} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import {connect} from 'react-redux';
import './index.css'

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const Group: React.FC<IProps> = (props: IProps) => {
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [integralData, setintegralData] = useState<any>({});

  const initData = async () => {
    const res = await Request.getData()
    let {data} = res
    setintegralData(data)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onFinish = (formVal: any) => {

    Request.edit({"config_value": JSON.stringify(formVal)}).then((res) => {
      if (res.code == 0) {
        message.success("保存成功")
        initData()
      } else {
        message.success(res.msg)
      }
    })
  };

  return (
      <>
        <div className="diamonds">
          <Skeleton loading={showSkeleton}>
            <Card className="mb15">
              <Form onFinish={onFinish} requiredMark={false} initialValues={{...integralData}}>
                <div className="public-title">
                  <span>商家提交资料审核配置</span>
                  <Button type="primary" htmlType="submit">保存</Button>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>审核开关：</Col>
                  <Col className="ml10">
                    <Form.Item valuePropName="checked" name="store_is_auth">
                      <Switch checkedChildren="开" unCheckedChildren="关"/>
                    </Form.Item>
                  </Col>
                  <Col>
                    <span className="font5 color-danger" style={{margin: "0 8px"}}>
                      （开：商家提交的资料，需要走一遍人工审核才可以开店。关：商家提交资料直接审核通过，直接开店）
                    </span>
                  </Col>
                </Row>
                <div className="public-title">
                  <span>商家店铺余额提现审核</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>审核开关：</Col>
                  <Col className="ml10">
                    <Form.Item valuePropName="checked" name="store_withdrawal_is_auth">
                      <Switch checkedChildren="开" unCheckedChildren="关"/>
                    </Form.Item>
                  </Col>
                  <Col>
                    <span className="font5 color-danger" style={{margin: "0 8px"}}>
                      （开：商家余额提现审核需要后台审核。关：商家余额提现审核直接打款到账，如果打款失败自动到后台待审核状态里面。自动打款平台-e宝-商户号:10089423727）
                    </span>
                  </Col>
                </Row>

                <div className="public-title">
                  <span>用户余额提现审核</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>审核开关：</Col>
                  <Col className="ml10">
                    <Form.Item valuePropName="checked" name="member_withdrawal_is_auth">
                      <Switch checkedChildren="开" unCheckedChildren="关"/>
                    </Form.Item>
                  </Col>
                  <Col>
                    <span className="font5 color-danger" style={{margin: "0 8px"}}>
                      （开：用户余额提现审核需要后台审核。关：用户余额提现审核直接打款到账，如果打款失败自动到后台待审核状态里面。自动打款平台-众乐邦-sign:b3027a3c07904396）
                    </span>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Skeleton>
        </div>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(Group)