import axios from "../../../axios"

function getList() {
    return axios.ajax({
        url: '/store/getCategoryList',
        method: "post",
        data: {
            type: 0
        },
    })
}

function add(params: any) {
    return axios.ajax({
        url: '/store/addCategory',
        method: "post",
        data: params
    })
}

function edit(params: any) {
    return axios.ajax({
        url: '/store/editCategory',
        method: "post",
        data: params
    })
}

function deleteData(params: { category_id: number | string }) {
    return axios.ajax({
        url: '/store/deleteCategory',
        method: "post",
        data: params
    })
}

export default {
    getList,
    add,
    edit,
    deleteData
}