import React from 'react';
import Router from "./router"; //全局引入Router文件
import './index.less';
import './App.less';
import './style/common.less'
import {Provider} from "react-redux"; // 添加<Provider />项目根组件
import configureStore from "./redux/store/configureStore";
import {ConfigProvider} from "antd"
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';

const store = configureStore();
function App() {
    return (
        <Provider store={store}>
            <ConfigProvider locale={zhCN}>
                <Router/>
            </ConfigProvider>
        </Provider>
    );
}

export default App;
