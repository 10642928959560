import axios from "../../../axios"

function getDetails() {
    return axios.ajax({
        url: '/index',
        method: "post",
        isShowLoading: false
    })
}


export default {
    getDetails,
}