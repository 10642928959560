import React, {useState, useEffect, useRef} from 'react';
import {Card, message, Button, Input, Form, Divider, Row, Col} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import {connect} from 'react-redux';
import './index.css'

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const GeneralConfig: React.FC<IProps> = (props: IProps) => {
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [integralData, setintegralData] = useState<any>({});

  const initData = async () => {
    const res = await Request.getData()
    let {data} = res
    setintegralData(data)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initData()
  }, [])
  const onFinish = (formVal: any) => {
    let obj = {"config_value": JSON.stringify(formVal)}
    Request.edit(obj).then((res) => {
      if (res.code == 0) {
        message.success("保存成功")
        initData()
      } else {
        message.error(res.msg)
      }
    })
  };

  return (
      <>
        <div className="diamonds">
          <Skeleton loading={showSkeleton}>
            <Card className="mb15">
              <Form
                  labelCol={{span: 8}}
                  wrapperCol={{span: 16}}
                  onFinish={onFinish}
                  requiredMark={false}
                  initialValues={{...integralData}}
              >
                <div className="public-title">
                  <span>下载落地页配置</span>
                  <Button type="primary" htmlType="submit">保存</Button>
                </div>
                <Divider/>
                <Form.Item name="android_app_down_url" label="Android" labelCol={{span: 1}}>
                  <Input type="text"/>
                </Form.Item>
                <Form.Item name="ios_app_down_url" label="ios" labelCol={{span: 1}}>
                  <Input type="text"/>
                </Form.Item>
              </Form>
            </Card>
          </Skeleton>
        </div>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(GeneralConfig)