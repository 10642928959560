import axios from "../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/member/getMemberList',
        method: "post",
        data: params,
        isShowLoading: false
    })
}

function editWallet(params: { mid: number, type: number | string, value: number | string, remark: string }, url: string) {
    return axios.ajax({
        url,
        method: "post",
        data: params
    })
}


function getUserDetails(params: any) {
    return axios.ajax({
        url: '/member/getMemberInfo',
        method: "post",
        data: params
    })
}

function editMemberIdentity(params: any) {
    return axios.ajax({
        url: '/member/editMemberIdentity',
        method: "post",
        data: params
    })
}
export default {
    getList,
    editWallet,
    getUserDetails,
    editMemberIdentity
}