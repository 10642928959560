import { MinusOutlined } from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import React, { useState } from 'react'

interface IsProps {
  value?: any[]
  type?: string
  disabled?: boolean
  onChange?: (value?: any[]) => void
}

const InputGroup: React.FC<IsProps> = (props: IsProps) => {

  const { value = ["", ""], disabled = false, onChange = () => { }, type = "text" } = props

  const [start, setStart] = useState(value[0])
  const [end, setEnd] = useState(value[1])

  const handleChange = (value: string, type: 1 | 2) => {
    if (type === 1) {
      setStart(value)
      onChange([value, end])
    }
    if (type === 2) {
      setEnd(value)
      onChange([start, value])
    }
  }

  return (
    <>
      <Row>
        <Col span={11}>
          <Input type={type} value={start} placeholder="请输入" disabled={disabled} onChange={(e) => {
            handleChange(e.target.value, 1)
          }} />
        </Col>
        <Col span={2} style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", color: "#999999" }}><MinusOutlined /></Col>
        <Col span={11}>
          <Input type={type} value={end} placeholder="请输入" disabled={disabled} onChange={(e) => {
            handleChange(e.target.value, 2)
          }} />
        </Col>
      </Row>
    </>
  )
}

export default InputGroup