import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {Descriptions, Row, Col, Card} from 'antd';
import Utils from "../../../../utils/utils";
import {RouteComponentProps} from 'react-router-dom';
import './index.less'

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any,
  userInfo: any
}
const UserDetail: React.FC<IProps> = (props: IProps) => {
  let userData = props?.userInfo
  useEffect(() => {
    func()
  }, []);
  const func = Utils.useSyncCallback(() => {

  });
  return (
      <>
        <div className="wrapContent">
          <Card>
            <Descriptions title="用户信息">
              <Descriptions.Item label="手机号">{userData?.mobile}</Descriptions.Item>
              <Descriptions.Item label="上级手机号">{userData?.parent_mobile}</Descriptions.Item>
              <Descriptions.Item label="上级昵称">{userData?.parent_nickname}</Descriptions.Item>
              <Descriptions.Item label="星级别">
                {userData && userData.level > 0 ? userData.level + '星' : '普通'}
              </Descriptions.Item>
              <Descriptions.Item label="注册时间">{userData?.created_at}</Descriptions.Item>
              <Descriptions.Item label="最后登录时间">{userData?.last_logged_at}</Descriptions.Item>
            </Descriptions>
            <div className="capital">
              <Row className="font-weight">资金信息</Row>
              <Row>
                <Col span={8} className="border-right border-bottom text-center lineheight30">
                  余额：￥{userData?.balance}
                </Col>
                <Col span={8} className="border-right border-bottom text-center lineheight30">
                  用户冻积分：{userData?.lock_integral}
                </Col>
                <Col span={8} className="text-center border-bottom lineheight30">
                  商家冻积分：{userData?.lock_store_integral}
                </Col>
                <Col span={8} className="border-right border-bottom text-center lineheight30">
                  开通店主积分：{userData?.lock_customer_integral}
                </Col>
                <Col span={8} className="border-right border-bottom text-center lineheight30">
                  绿色积分：{userData?.green_integral}
                </Col>
                <Col span={8} className="border-right border-bottom text-center lineheight30">
                  幸福值：{userData?.happy_integral}
                </Col>
                <Col span={8} className="border-right border-bottom text-center lineheight30">
                  冻结状态：{userData?.is_freeze == 0 ? '正常': '冻结'}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </>
  );
}

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(UserDetail);