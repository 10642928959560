import axios from '../../../axios'

function getList(params: any) {
  return axios.ajax({
    url: '/good/getGoodList',
    method: 'post',
    data: params,
  })
}

function add(params: any) {
  return axios.ajax({
    url: '/good/addGood',
    method: 'post',
    data: params,
  })
}

function edit(params: any) {
  return axios.ajax({
    url: '/good/editGood',
    method: 'post',
    data: params,
  })
}

function ChangeStatusData(params: { good_id: string; good_status: number }) {
  return axios.ajax({
    url: '/good/statusGood',
    method: 'post',
    data: params,
  })
}

function getGoodInfo(params: { good_id: string | number }) {
  return axios.ajax({
    url: '/good/getGoodInfo',
    method: 'post',
    data: params,
  })
}

function goodDel(params: { good_id: string | number }) {
  return axios.ajax({
    url: '/good/deleteGood',
    method: 'post',
    data: params,
  })
}

function updateFalseSaleNumber(params: any) {
  return axios.ajax({
    url: '/good/falseSaleVolumeGood',
    method: "post",
    data: params
  })
}

export default {
  getList,
  add,
  edit,
  ChangeStatusData,
  getGoodInfo,
  goodDel,
  updateFalseSaleNumber
}
