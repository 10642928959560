import React, {useState} from "react";
import {Breadcrumb, Tabs, PageHeader} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import InviteFriend from "./invite_friend"
import Goodhot from "./good_hot"
import Storehot from "./store_hot"


import {connect} from 'react-redux';
import utils from '@src/utils/utils';

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const GeneralConfig: React.FC<IProps> = (props: IProps) => {
  const {getMenuRole} = utils
  const {allRole} = props
  const [tabKey, setTabKey] = useState<any>("0");
  const configStatus = [];
  if (getMenuRole("config/getInviteFriends", allRole)) {
    configStatus.push({tabkey: 0, title: "邀请好友配置"});
  }
  if (getMenuRole("config/getGoodHotKeyword", allRole)) {
    configStatus.push({tabkey: 1, title: "商品热词"});
  }
  if (getMenuRole("config/getStoreHotKeyword", allRole)) {
    configStatus.push({tabkey: 2, title: "店铺热词"});
  }

  const tabChange = (key: any) => {
    setTabKey(key)
  }
  return (
      <>
        <PageHeader
            title='通用配置'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>系统配置</Breadcrumb.Item>
              <Breadcrumb.Item>通用配置</Breadcrumb.Item></Breadcrumb>}
            footer={
              <Tabs defaultActiveKey="0" onChange={tabChange}>
                {configStatus.map((statusItem: any, index: number) => {
                  return (
                      <TabPane
                          closable={false}
                          tab={statusItem.title}
                          key={statusItem.tabkey}
                      ></TabPane>
                  );
                })}
              </Tabs>
            }
        >

        </PageHeader>
        <div className="wrapContent">
          {tabKey == 0 && (<InviteFriend {...props} />)}
          {tabKey == 1 && (<Goodhot {...props}/>)}
          {tabKey == 2 && (<Storehot {...props}/>)}
        </div>
      </>
  );
};


const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(GeneralConfig)