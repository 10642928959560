import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  PageHeader,
  Breadcrumb,
  Row,
  Select,
  message,
  Steps,
  Modal,
  Input
} from "antd";
import Request from "./Request";
import {RouteComponentProps} from "react-router-dom";
import {withAliveScope} from "react-activation";
import {getExpressAll} from "@src/config/getAsyncConfig"
import Skeleton from '../../../components/Skeleton';
import utils from "../../../utils/utils";
import './detail.less'

const {Step} = Steps;

interface IProps extends RouteComponentProps {
  allRole: any[];
  dispatch: any;
  tabs: any;
  dropScope: any;
}


const ActivityDetails: React.FC<IProps> = (props: IProps) => {
  const [form2] = Form.useForm();
  //@ts-ignore
  let order_id = props?.match?.params?.id || "new";

  const [orderId] = useState<string | number>(order_id);
  const [orderData, setOrderData] = useState<any>(order_id);
  const [expressList, setExpressList] = useState<any[]>([]);
  const [visiblefh, setVisiblefh] = useState<boolean>(false);
  const [logisticsData, setLogisticsData] = useState<any[]>([]);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)

  useEffect(() => {
    func()
    getExpressAll().then(res => {
      setExpressList(res.data.list)
    })
  }, []);
  const visibleModalfh = () => {
    setVisiblefh(!visiblefh);
  };
  //修改发货
  const deliver = async () => {
    form2.setFieldsValue({express_company_id: orderData.express_company_id, express_no: orderData.express_no})
    visibleModalfh();
  };
  const func = utils.useSyncCallback(() => {
    Request.getActDetails({order_id: orderId}).then((res) => {
      let data = res.data
      console.log(res)
      if (data.pay_unit_fee && data.pay_good_fee) {
        data.pay_yh_fee = data.pay_good_fee - data.pay_unit_fee
      }
      if (data.order_status == 9) {
        data.step = 5
      } else {
        data.step = data.order_status
      }
      logistics(data)
      setOrderData(data)
      setShowSkeleton(false)
    });
  });
  const logistics = async (ordata) => {
    const res = await Request.getLogisticsData({order_id: ordata.order_id, mid: ordata.mid, order_type: 1});
    setLogisticsData(res.data.express)
  }
  const formSubmit = (formVal: any) => {
    formVal.order_id = orderData.order_id
    expressList.forEach(item => {
      if (item.express_code == formVal.express_code) {
        formVal.express_name = item.express_name
      }
    })
    Request.updateOrderExpress(formVal).then(() => {
      visibleModalfh();
      func()
      message.success("操作成功");
    });
  };
  const viewLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
  };
  return (
      <div className="detailwrap">
        <Skeleton loading={showSkeleton}>
          <PageHeader
              breadcrumb={<Breadcrumb><Breadcrumb.Item>订单管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="#">订单列表</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>物流详情</Breadcrumb.Item></Breadcrumb>}
              className="site-page-header-responsive mb15"
              title='物流详情'
          >
          </PageHeader>
          <div className="wrapContent orderexpress">
            <Card>
              <span className="mr20 font18">订单编号：{orderData?.order_no}</span><span
                className="font18">下单时间：{orderData?.created_at}</span>
              <Row className="mt20">
                <Col span={5} style={{padding: "20px 10px", border: "1px solid #ccc"}}>
                  <div className="goodimg mb10">
                    <img src={orderData?.good_main_media_url} alt=""/>
                    <div className="goodnum text-center">
                      共1件商品
                    </div>
                  </div>
                  <div className="mb10">快递公司：{orderData?.express_name}</div>
                  <div className="mb10">快递单号：{orderData?.express_no}
                    {(orderData?.order_status == 3) && <a onClick={deliver} className="ml5">修改</a>}
                  </div>
                  <div>发货时间：{orderData?.send_express_at}</div>
                </Col>
                <Col span={19} style={{padding: "20px 30px"}} className="expressborder">
                  <Row className="logisticsTitle">
                  </Row>
                  <Row>
                    <Col span={4} className="mt20">
                      {logisticsData.map((item: any, index: any) => {
                        return <div key={index} className="logisticsTime" style={{minHeight: "48px"}}>{item.time}</div>
                      })}
                    </Col>
                    <Col span={15} className="mt20">
                      <Steps progressDot current={0} direction="vertical">
                        {logisticsData.map((item: any, index: any) => {
                          return <Step key={index} title={item.context}/>
                        })}
                      </Steps>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Modal
                destroyOnClose
                title="物流修改"
                visible={visiblefh}
                onCancel={visibleModalfh}
                footer={false}
                width={500}
            >
              <Form {...viewLayout} requiredMark={false} onFinish={formSubmit} form={form2}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                        label="物流公司"
                        name="express_code"
                        rules={[{required: true}]}
                    >
                      <Select
                          allowClear
                          showSearch
                          optionFilterProp="label"
                          placeholder="请选择"
                      >
                        {
                          (() => {
                            return expressList.map(item => {
                              return <Select.Option key={item.express_code} label={item.express_name}
                                                    value={item.express_code}>{item.express_name}</Select.Option>
                            })
                          })()
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                        label="物流单号"
                        name="express_no"
                        rules={[{required: true}]}
                    >
                      <Input placeholder="请输入物流单号"/>
                    </Form.Item>
                    <Form.Item {...{wrapperCol: {offset: 3}}}>
                      <Button className="mr20" onClick={visibleModalfh}>
                        取消
                      </Button>
                      <Button type="primary" htmlType="submit">
                        确认
                      </Button>

                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </div>
        </Skeleton>
      </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole,
    tabs: state.tabs,
  };
};

export default connect(mapStateToProps)(withAliveScope(ActivityDetails));
