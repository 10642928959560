import axios from 'axios';//*导入axios插件
import { Modal, message } from "antd";
import Utils from "../utils/utils";
import qs from 'qs'

const CancelToken = axios.CancelToken;
let httpRequestList = []
axios.defaults.headers.common["content-type"] = "application/x-www-form-urlencoded;charset=UTF-8";
let token = Utils.getToken()
if (token) {
    axios.defaults.headers.common["token"] = token;
}

// loading 相关 ------- start --------
let loadingRequestCount = 0;

function showLoading() {
    if (loadingRequestCount === 0) { //多个请求只展示一个loading
        document.getElementById('ajaxLoading').style.display = 'block'
    }
    loadingRequestCount++
}

function closeLoading() {
    if (loadingRequestCount <= 0) {
        return;
    }
    loadingRequestCount--
    if (loadingRequestCount === 0) {
        document.getElementById('ajaxLoading').style.display = 'none'
    }
}

// loading 相关 ------- end --------

//导出一个对象供其他对象进行使用
export default class Axios {
    static ajax(options) {//封装axios   定义为ajax请求,使用Promise
        const { isShowLoading = true } = options
        if (isShowLoading) {
            showLoading()
        }
        // let baseApi = 'http://127.0.0.1:8957/admin';
        let baseApi = process.env.REACT_APP_API_URL;
        return new Promise((resolve, reject) => {
            let { data = {} } = options
            axios({
                url: options.url,
                method: options.method || 'get',
                baseURL: baseApi,
                timeout: 1000 * 60 * 5,
                data: qs.stringify(data),
                cancelToken: new CancelToken(function executor(c) {
                    httpRequestList.push(c)
                })
            }).then((response) => {
                closeLoading()
                if (response.status === 200) {
                    let res = response.data;
                    if (res.code === 203 || res.code === "203") {
                        //登录超时
                        // this.clearRequest("203")
                        window.reactHistory.push("/login")
                    }
                    if (res.code === "0" || res.code === 0) {
                        resolve(res);
                    } else if (res.code === "405" || res.code === 405) {
                        //没有访问权限
                        // this.clearRequest("405")
                        window.reactHistory.push("/notpower")
                    } else {
                        message.error(res.msg)
                    }
                } else {
                    reject(response.data);
                }
            }).catch(err => {
                document.getElementById('ajaxLoading').style.display = 'none'
                if (err.message !== "405" || err.message !== "203") {
                    Modal.info({
                        title: "提示",
                        content: "请求超时,请联系产品部门"
                    });
                }
            });
        });
    }

    // 取消所有请求,取消后执行catch
    static clearRequest(err) {
        httpRequestList.forEach(req => req(err))
        httpRequestList = []
    }

    static setToken(token) {
        if (token) {
            axios.defaults.headers.common["token"] = token;
        } else {
            delete axios.defaults.headers.common["token"];
        }
    }
}
