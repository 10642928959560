import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select, Tree } from 'antd';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import { ListItem } from "./data"
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const Role: React.FC<IProps> = (props: IProps) => {

  const ref = useRef<ActionType>();

  const [title, setTitle] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [IsItem, setIsItem] = useState<ListItem>()
  const [FunList, setFunList] = useState<any[]>([])
  const [CheckID, setCheckID] = useState<any[]>([])

  const getMenu = (_: any, item: ListItem) => {
    const { allRole } = props
    const { getMenuRole, menuRender } = Utils
    let menu = []
    if (getMenuRole("sys/edit_role", allRole)) {
      menu.push(<a onClick={() => {
        edit(item)
      }}>编辑</a>)
    }

    if (getMenuRole("sys/delete_role", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    return menuRender(menu)
  }

  const getFunList = async () => {
    if (FunList.length <= 0) {
      const res = await Request.getFunList()
      setFunList(res.data)
    }
  }

  useEffect(() => {
    getFunList()
  }, [])

  const add = () => {
    setTitle("新建")
    getFunList()
    setCheckID([])
    setIsItem({
      role_id: "",
      role_name: "",
      role_ename: "",
      status: 1
    })
    setVisible(true)
  }


  const edit = async (item: ListItem) => {
    setTitle("编辑")
    setIsItem(item)
    const res = await Request.getRoleInfo({ role_id: item.role_id })
    setFunList(res.func_list)
    setCheckID(res.check_id)
    setVisible(true)
  }

  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: 'danger',
      onOk() {
        Request.deleteData({ role_id: item.role_id }).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }
  const onCheck = (checkedKeysValue: React.Key[],e) => {
    console.log(checkedKeysValue,e.halfCheckedKeys)
  };
  const visibleModal = () => {
    setVisible(!visible)
  }

  const formSubmit = (formVal: any) => {
    const callback = () => {
      visibleModal()
      ref.current!.reload()
      message.success("操作成功")
    }
    formVal.check_arr  = JSON.stringify(formVal.check_arr)
    if (IsItem?.role_id) {
      formVal.role_id = IsItem.role_id
      Request.edit(formVal).then(callback)
    } else {
      Request.add(formVal).then(callback)
    }
  }

  const columns: ProColumns<ListItem>[] = [
    { title: 'ID', dataIndex: 'role_id' },
    { title: '角色名称', dataIndex: 'role_name' },
    { title: '角色代码', dataIndex: 'role_ename' },
    { title: '状态', dataIndex: 'status', valueEnum: { "0": { status: "Success", text: "可用" }, "1": { status: "Error", text: "不可用" } } },
    { title: '操作', align: 'center', width: 160, dataIndex: 'id', render: getMenu }
  ];

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 }
  }

  return (
    <>
      <ProTable<ListItem>
        rowKey="role_id"
        tableStyle={{ padding: '0 24px 0 24px' }}
        columns={columns}
        actionRef={ref}
        search={false}
        headerTitle="角色列表"
        request={async () => {
          const res = await Request.getList();
          return {
            data: res.data,
            success: true
          }
        }}
        pagination={false}
        toolBarRender={() => [
          (Utils.getMenuRole("sys/add_role", props.allRole) && <Button key="button" icon={<PlusOutlined />} type="primary" onClick={add}> 新建 </Button>)
        ]}
        options={{ density: false, fullScreen: false, reload: true, setting: true }}
      />
      <Modal
        destroyOnClose
        title={title}
        visible={visible}
        onCancel={visibleModal}
        footer={false}
        width={800}>
        <Form
          {...formItemLayout}
          requiredMark={false}
          onFinish={formSubmit}
          initialValues={{
            role_name: IsItem?.role_name,
            role_ename: IsItem?.role_ename,
            status: IsItem?.status,
            check_arr: CheckID
          }}
        >
          <Form.Item
            label="角色名称"
            name="role_name"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入角色名称" />
          </Form.Item>
          <Form.Item
            label="角色代码"
            name="role_ename"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入角色代码" />
          </Form.Item>
          <Form.Item
            label="状态"
            name="status"
            rules={[{ required: true }]}
          >
            <Select placeholder="请选择">
              <Select.Option value={0}>可用</Select.Option>
              <Select.Option value={1}>不可用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="角色权限"
            name="check_arr"
            valuePropName="checkedKeys"
            trigger="onCheck"
            validateTrigger="onCheck"
            rules={[{ required: true, message: "请选择权限" }]}
          >
            <Tree onCheck={onCheck} checkable treeData={FunList} />
          </Form.Item>
          <Form.Item {...{ wrapperCol: { offset: 3 } }}>
            <Button type="primary" htmlType="submit">立即提交</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(Role);