import axios from "../../axios"

export class Request {
    static login(params: any, callback: (result: any) => void){
        axios.ajax({
            url: '/login_post',
            method: "post",
            data: params
        }).then((res) => {
            callback(res)
        })
    }
    static getCaptchaImg(params: any, callback: (arg0: any) => void){
        axios.ajax({
            url: '/get_code_image',
            data: params
        }).then((res) => {
            callback(res)
        })
    }
}