import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {
  Button,
  message,
  Modal,
  Tabs,
  PageHeader,
  Breadcrumb, Form, Row, Col, Input,
} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import {ListItem} from "./data"
import Skeleton from '../../../components/Skeleton';
import {GOOD_STATUSY, STORE_STATUSY} from "@src/config/statusConfig";

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any
}

let TEMP_CHECK_ROW: any[] = [];
let exportsFilter: any = {};
const GoodStatus = [
  {title: "全部", param: {status: 1}},
  {title: "销售中", param: {status: 2}},
  {title: "仓库中", param: {status: 3}},
  {title: "已下架", param: {status: 4}}
];

const ActivityshareGoodList: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const [tabKey, setTabKey] = useState<any>("0");
  const [selectedRows, setSelectedRows] = useState<any>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [modelForm] = Form.useForm()

  useEffect(() => {

    //获取标签
  }, [])
  //批量上下架
  const editVisibleModal = (is: boolean, items: any, type: any) => {
    let id_arr = []
    if (!TEMP_CHECK_ROW[0]) {
      message.error("请选择需要操作的数据");
      return;
    }
    TEMP_CHECK_ROW.forEach((i: any) => {
      id_arr.push({good_id: i.good_id})
    });
    Modal.confirm({
      title: type === 1 ? (
          <div>您已经勾选<span style={{color: 'red'}}>{TEMP_CHECK_ROW.length}</span>个商品，您确认要将这{TEMP_CHECK_ROW.length}个商品下架吗？
          </div>) : (
          <div>您已经勾选<span style={{color: 'red'}}>{TEMP_CHECK_ROW.length}</span>个商品，您确认要将这{TEMP_CHECK_ROW.length}个商品上架吗？
          </div>),
      okType: "danger",
      onOk() {
        Request.ChangeStatusData({good_id: JSON.stringify(id_arr), good_status: type === 1 ? 2 : 1}).then(() => {
          ref.current!.reload();
          TEMP_CHECK_ROW = [];
          setSelectedRows([]);
          message.success("操作成功");
        });
      },
    });
  }

  const tabChange = (key: any) => {
    setTabKey(key)
    TEMP_CHECK_ROW = [];
    setSelectedRows([]);
    ref.current.reset();
  }

  //操作按钮
  const getMenu = (_: any, item: ListItem) => {
    const {allRole} = props
    const {getMenuRole, menuRender} = Utils
    let menu = []
    if (getMenuRole("good/editGood", allRole)) {
      menu.push(<a onClick={() => {
        toDetails(String(item.good_id), 'edit');
      }}>编辑</a>)
    }
    if (getMenuRole("good/statusGood", allRole)) {
      menu.push(<a className={item.good_status === 1 ? "color-warning" : "color-success"} onClick={() => {
        upDownGood(item)
      }}>{item.good_status === 1 ? "下架" : "上架"}</a>)
    }
    if (getMenuRole("good/deleteGood", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    return menuRender(menu)
  }
  //批量删除
  const del = async () => {
    let id_arr = []
    if (!TEMP_CHECK_ROW[0]) {
      message.error("请选择需要操作的数据");
      return;
    }
    TEMP_CHECK_ROW.forEach((i: any) => {
      id_arr.push({good_id: i.good_id})
    });
    Modal.confirm({
      title: <div>你已经勾选<span className="colorB">{TEMP_CHECK_ROW.length}</span>个商品，您确认要将这{TEMP_CHECK_ROW.length}个商品删除吗？
      </div>,
      okType: "danger",
      onOk() {
        Request.goodDel({good_id: JSON.stringify(id_arr)}).then(() => {
          ref.current!.reload();
          TEMP_CHECK_ROW = [];
          setSelectedRows([]);
          message.success("删除成功");
        });
      },
    });
  }
  //编辑查看商品
  const toDetails = (good_id, detailtype) => {
    window.open(`/#/good/detail/${detailtype}/${good_id}`)
  }
  //新增商品
  const addGood = (detailtype) => {
    window.open(`/#/good/detail/${detailtype}/0`)
  }
  //上下架商品
  const upDownGood = (item: ListItem) => {
    Modal.confirm({
      title: item.good_status === 1 ? "你确定要下架商品吗?" : "你确定要上架商品吗?",
      okType: 'danger',
      onOk() {
        Request.ChangeStatusData({
          good_id: JSON.stringify([{"good_id": item.good_id}]),
          good_status: item.good_status === 1 ? 2 : 1
        }).then(() => {
          ref.current!.reload()
          message.success("操作成功")
        })
      }
    })
  }
  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: 'danger',
      onOk() {
        Request.goodDel({good_id: JSON.stringify([{"good_id": item.good_id}])}).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }

  const onSelect = (selected: any, changeRows: any, checkAll: boolean) => {
    if (selected) {
      TEMP_CHECK_ROW = [...TEMP_CHECK_ROW, ...changeRows];
    } else {
      if (checkAll) {
        TEMP_CHECK_ROW = [];
      } else {
        changeRows.forEach((item: any) => {
          let index = TEMP_CHECK_ROW.findIndex((i) => {
            return i.good_id == item.good_id;
          });
          TEMP_CHECK_ROW.splice(index, 1);
        });
      }
    }
    setSelectedRows(TEMP_CHECK_ROW.map((i) => i.good_id));
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onSelect: (record: any, selected: any) => {
      onSelect(selected, [record], false);
    },
    onSelectAll: (selected: any, record: any, changeRows: any) => {
      onSelect(selected, changeRows, true);
    },
  };


  const visibleModal = () => {
    setVisible(!visible)
  }

  const formXLSubmit = () => {
    let id_arr: any;
    let obj = modelForm.getFieldsValue()
    id_arr = TEMP_CHECK_ROW.map((i: any) => {
      return i.good_id;
    });
    obj.good_id = id_arr.toString()

    Request.updateFalseSaleNumber(obj).then(() => {
      TEMP_CHECK_ROW = [];
      setSelectedRows([]);
      visibleModal()
      ref.current.reset();
      message.success("设置成功");
    });
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', width: 40, dataIndex: 'good_id', search: false, fixed: 'left'},
    {title: '关键词', width: 80, dataIndex: 'q', hideInTable: true},
    {title: '商品名称', width: 220, dataIndex: 'good_title', search: false},
    {
      title: '商品主图', width: 80, search: false, align: "center", render: (_, record) => {
        return <img
            src={record.good_main_media_url ? record.good_main_media_url : require("../../../resources/assets/none.png")}
            alt="" style={{width: "50px", height: "50px"}}/>
      }
    },
    {title: '总库存', width: 80, dataIndex: 'all_spec_stock', search: false},
    {title: '最小规格价', width: 80, dataIndex: 'min_spec_price', search: false},
    {title: '最小规格成本价', width: 80, dataIndex: 'min_spec_cost_price', search: false},
    {title: '销量', width: 80, dataIndex: 'sale_volume', search: false},
    {
      title: '五星店主审核状态', align: 'center', width: 100, dataIndex: 'audit_status', search: false, render: (_, record) => {
        if (record.store_good_id > 0) {
          if (record.audit_status == 0) {
            return <span className="color-primary">待审核</span>;
          } else if(record.audit_status == 1){
            return <span className="color-success">审核成功</span>;
          }else if(record.audit_status == 2){
            return <span className="color-danger">已驳回</span>;
          }
        } else  {
          return '-'
        }
      }
    },
    {
      title: '五星店主上下架状态', align: 'center', width: 100, dataIndex: 'level5_status', search: false, render: (_, record) => {
        if (record.store_good_id > 0) {
          if (record.level5_status == 0) {
            return <span className="color-primary">未上架</span>;
          } else if(record.level5_status == 1){
            return <span className="color-success">已上架</span>;
          }else if(record.level5_status == 2){
            return <span className="color-danger">已下架</span>;
          }
        } else  {
          return '-'
        }
      }
    },
    {title: '平台状态', align: 'center', width: 60, dataIndex: 'good_status', valueEnum: GOOD_STATUSY, search: false,},
    {title: '更新时间', width: 110, dataIndex: 'updated_at', search: false, align: "center"},
    {title: '添加时间', width: 110, dataIndex: 'created_at', search: false, align: "center"},
    {title: '操作', align: 'center', fixed: 'right', width: 90, dataIndex: 'id', search: false, render: getMenu},
  ];
  return (
      <>
        <Skeleton loading={false}>
          <PageHeader
              title='平台商品列表'
              className="site-page-header"
              breadcrumb={<Breadcrumb><Breadcrumb.Item>商品</Breadcrumb.Item>
                <Breadcrumb.Item>平台商品</Breadcrumb.Item></Breadcrumb>}
              footer={
                <Tabs defaultActiveKey="0" onChange={tabChange}>
                  {GoodStatus.map((statusItem: any, index: number) => {
                    return (<TabPane closable={false} tab={statusItem.title} key={index}></TabPane>);
                  })}
                </Tabs>
              }
          >

          </PageHeader>
          <div className="wrapContent">
            <ProTable<ListItem>
                rowKey="good_id"
                tableStyle={{padding: '0 24px 0 24px'}}
                columns={columns}
                actionRef={ref}
                scroll={{x: 2000}}
                rowSelection={rowSelection}
                request={async (params: any) => {
                  params = Object.assign(
                      JSON.parse(JSON.stringify(GoodStatus[+tabKey].param)),
                      params
                  );
                  exportsFilter = JSON.parse(JSON.stringify(params));
                  const res = await Request.getList(params);
                  return {
                    data: res.data.list,
                    total: res.data.count,
                    success: true,
                    pageSize: params.pageSize,
                    current: params.pageSize
                  }
                }
                }

                toolbar={{
                  title: [
                    (Utils.getMenuRole("good/addGood", props.allRole) ? (
                        <Button key="addMgood" type="primary" className="mr10" onClick={() => {
                          addGood('new')
                        }}>添加商品</Button>) : ''),

                    ((tabKey == 0 || tabKey == 2 || tabKey == 3) && Utils.getMenuRole("good/statusGood", props.allRole) ? (
                        <Button key="statusMgood2" type="primary" className="mr10" onClick={() => {
                          editVisibleModal(true, selectedRows, 2)
                        }}>批量上架</Button>) : ''),

                    ((tabKey == 0 || tabKey == 1) && Utils.getMenuRole("good/statusGood", props.allRole) ? (
                        <Button key="statusMgood1" type="primary" className="mr10" onClick={() => {
                          editVisibleModal(true, selectedRows, 1)
                        }}>批量下架</Button>) : ''),

                    (Utils.getMenuRole("good/deleteGood", props.allRole) ? (
                        <Button key="setSalesVolume" danger type="primary" className="mr10" onClick={() => {
                          del()
                        }}>批量删除</Button>) : '')
                  ],
                  filter: ''
                }}
                pagination={{
                  defaultPageSize: 20,
                }}
            />
          </div>
          <Modal
              destroyOnClose
              title="批量设置虚拟销量"
              visible={visible}
              onCancel={visibleModal}
              // footer={false}
              onOk={formXLSubmit}
              width={600}>
            <Form
                requiredMark={false}
                form={modelForm}
            >
              <Row>
                <Col className='ml10 mr10 lineheight30'>虚拟销量</Col>
                <Col>
                  <Form.Item
                      name="start_number"
                      rules={[{required: true}]}
                  >
                    <Input type="number" placeholder="请输入虚拟销量"/>
                  </Form.Item>
                </Col>
                <Col className='ml10 mr10 lineheight30'>-</Col>
                <Col>
                  <Form.Item
                      name="end_number"
                      rules={[{required: true}]}
                  >
                    <Input type="number" placeholder="请输入虚拟销量"/>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Skeleton>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(ActivityshareGoodList)