import axios from "../../../../axios"

function getData() {
    return axios.ajax({
        url: '/config/getConfigDownloadLandingPage',
        method: "post"
    })
}

function edit(params: any ) {
    return axios.ajax({
        url: '/config/editConfigDownloadLandingPage',
        method: "post",
        data: params
    })
}

export default {
    getData,
    edit
}