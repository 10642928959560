import Utils from "./utils"

// form 表单校验
export default {
    // 手机号
    checkMobile: (_, value) => {
        if (!value) {
            return Promise.reject("请输入手机号")
        } else if (!new RegExp(/^1[3456789]\d{9}$/).test(value)) {
            return Promise.reject("请输入正确的手机号")
        } else {
            return Promise.resolve()
        }
    },
    // 日期控件
    checkDatePicker: (_, value) => {
        if (!value) {
            return Promise.reject("请选择时间")
        } else if (Array.isArray(value) && (!value[0] || !value[1])) {
            return Promise.reject("请选择时间")
        } else {
            return Promise.resolve()
        }
    },
    // 身份证号
    checkIDCard: (_, value) => {
        if (!value) {
            return Promise.reject("请输入身份证号")
        } else if (!Utils.IDCardCheck(value)) {
            return Promise.reject("身份证号格式错误")
        } else {
            return Promise.resolve()
        }
    },
    // 省市区
    checkArea: (_, value) => {
        if (!value || !value[2]) {
            return Promise.reject("请选择地区")
        } else {
            return Promise.resolve()
        }
    },
    // 不能小于0
    checLessThan0: (_, value) => {
        if (value === "" || value === undefined) {
            return Promise.reject("请输入")
        } else if (Number(value) < 0) {
            return Promise.reject("不能小于0")
        } else {
            return Promise.resolve()
        }
    },
    // 不能小于或等于0
    checLessThan1: (_, value) => {
        if (value === "" || value === undefined) {
            return Promise.reject("请输入")
        } else if (Number(value) <= 0) {
            return Promise.reject("不能小于或等于0")
        } else {
            return Promise.resolve()
        }
    }
}