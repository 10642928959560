import axios from "../../../axios"

function getList() {
    return axios.ajax({
        url: '/sys/get_role_list',
        method: "post",
        isShowLoading: false
    })
}

function add(params: any) {
    return axios.ajax({
        url: "/sys/add_role",
        method: "post",
        data: params
    })
}

function edit(params: any) {
    return axios.ajax({
        url: "/sys/edit_role",
        method: "post",
        data: params
    })
}

function deleteData(params: { role_id: number | string }) {
    return axios.ajax({
        url: "/sys/delete_role",
        method: "post",
        data: params
    })
}

function getRoleInfo(params: { role_id: number | string }) {
    return axios.ajax({
        url: '/sys/get_role_info',
        method: "post",
        data: params
    })
}

function getFunList() {
    return axios.ajax({
        url: '/sys/get_func_list',
        method: "post"
    })
}

export default {
    getList,
    getFunList,
    getRoleInfo,
    add,
    edit,
    deleteData
}