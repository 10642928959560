import React, {useState, useEffect} from 'react';
import {Card, message, Button, Input, Form, Divider, Row, Col} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import {connect} from 'react-redux';
import './index.css'

interface IProps extends RouteComponentProps {
  allRole: any[]
}

//s123
const GeneralConfig: React.FC<IProps> = (props: IProps) => {
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [integralData, setintegralData] = useState<any>({});

  const initData = async () => {
    const res = await Request.getData()
    let {data} = res
    setintegralData(data)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initData()
  }, [])
  const onFinish = (formVal: any) => {
    let obj = {"config_value": JSON.stringify(formVal)}
    Request.edit(obj).then((res) => {
      if (res.code == 0) {
        message.success("保存成功")
        initData()
      } else {
        message.error(res.msg)
      }
    })
  };

  return (
      <>
        <div className="diamonds">
          <Skeleton loading={showSkeleton}>
            <Card className="mb15">
              <Form
                  labelCol={{span: 8}}
                  wrapperCol={{span: 16}}
                  onFinish={onFinish}
                  requiredMark={false}
                  initialValues={{...integralData}}>
                <div className="public-title">
                  <span>直推</span>
                  <Button type="primary" htmlType="submit">保存</Button>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>报单直推奖励（上级的第一个店主）:报单金额的</Col>
                  <Col>
                    <Form.Item name="zt_bd_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="sign">
                  <Col>直推管理奖</Col>
                  <Col>
                    <Form.Item name="zt_bd_gl_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <br/>
                <div className="public-title">
                  <span>级别奖励</span>
                </div>
                <Divider/>
                {/*一星店主*/}
                <Row className="sign">
                  <Col>报单级差奖总金额</Col>
                  <Col>
                    <Form.Item name="star_jicha_reward_all_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col>店铺级差奖总金额</Col>
                  <Col>
                    <Form.Item name="star_store_jicha_reward_all_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col>一星店主:</Col>
                  <Col>售价</Col>
                  <Col>
                    <Form.Item name="one_star_price">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="元"/>
                    </Form.Item>
                  </Col>
                  <Col>,可获得</Col>
                  <Col>
                    <Form.Item name="one_star_bd_lock_integral">
                      <Input style={{width: "250px", margin: "0 8px"}} min="0" suffix="开通店主积分"/>
                    </Form.Item>
                  </Col>
                  <Col>,每天释放(开通店主积分)</Col>
                  <Col>
                    <Form.Item name="one_star_green_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>为绿色积分,享受伞下报单级差奖励(绿色积分)</Col>
                  <Col>
                    <Form.Item name="one_star_jicha_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col><span style={{margin: "0 0 0 70px"}}>伞下店铺流水（线上、线下）级差收益(绿色积分)</span></Col>
                  <Col>
                    <Form.Item name="one_star_store_water_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>奖励,商家冻积分释放比例</Col>
                  <Col>
                    <Form.Item name="one_star_store_lock_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>

                {/*二星店主*/}
                <Row className="sign">
                  <Col>二星店主:</Col>
                  <Col>售价</Col>
                  <Col>
                    <Form.Item name="two_star_price">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="元"/>
                    </Form.Item>
                  </Col>
                  <Col>,可获得</Col>
                  <Col>
                    <Form.Item name="two_star_bd_lock_integral">
                      <Input style={{width: "250px", margin: "0 8px"}} min="0" suffix="开通店主积分"/>
                    </Form.Item>
                  </Col>
                  <Col>,每天释放(开通店主积分)</Col>
                  <Col>
                    <Form.Item name="two_star_green_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>为绿色积分,享受伞下报单级差奖励</Col>
                  <Col>
                    <Form.Item name="two_star_jicha_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col><span style={{margin: "0 0 0 70px"}}>伞下店铺流水（线上、线下）级差收益(绿色积分)</span></Col>
                  <Col>
                    <Form.Item name="two_star_store_water_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>奖励,商家冻积分释放比例</Col>
                  <Col>
                    <Form.Item name="two_star_store_lock_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>

                {/*三星店主*/}
                <Row className="sign">
                  <Col>三星店主:</Col>
                  <Col>售价</Col>
                  <Col>
                    <Form.Item name="three_star_price">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="元"/>
                    </Form.Item>
                  </Col>
                  <Col>,可获得</Col>
                  <Col>
                    <Form.Item name="three_star_bd_lock_integral">
                      <Input style={{width: "250px", margin: "0 8px"}} min="0" suffix="开通店主积分"/>
                    </Form.Item>
                  </Col>
                  <Col>,每天释放(开通店主积分)</Col>
                  <Col>
                    <Form.Item name="three_star_green_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>为绿色积分,享受伞下报单级差奖励</Col>
                  <Col>
                    <Form.Item name="three_star_jicha_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col><span style={{margin: "0 0 0 70px"}}>伞下店铺流水（线上、线下）级差收益(绿色积分)</span></Col>
                  <Col>
                    <Form.Item name="three_star_store_water_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>奖励,商家冻积分释放比例</Col>
                  <Col>
                    <Form.Item name="three_star_store_lock_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>

                {/*四星店主*/}
                <Row className="sign">
                  <Col>四星店主:</Col>
                  <Col>售价</Col>
                  <Col>
                    <Form.Item name="four_star_price">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="元"/>
                    </Form.Item>
                  </Col>
                  <Col>,可获得</Col>
                  <Col>
                    <Form.Item name="four_star_bd_lock_integral">
                      <Input style={{width: "250px", margin: "0 8px"}} min="0" suffix="开通店主积分"/>
                    </Form.Item>
                  </Col>
                  <Col>,每天释放(开通店主积分)</Col>
                  <Col>
                    <Form.Item name="four_star_green_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>为绿色积分,享受伞下报单级差奖励</Col>
                  <Col>
                    <Form.Item name="four_star_jicha_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col><span style={{margin: "0 0 0 70px"}}>伞下店铺流水（线上、线下）级差收益(绿色积分)</span></Col>
                  <Col>
                    <Form.Item name="four_star_store_water_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>奖励,商家冻积分释放比例</Col>
                  <Col>
                    <Form.Item name="four_star_store_lock_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>

                {/*五星店主*/}
                <Row className="sign">
                  <Col>五星店主:</Col>
                  <Col>售价</Col>
                  <Col>
                    <Form.Item name="five_star_price">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="元"/>
                    </Form.Item>
                  </Col>
                  <Col>,可获得</Col>
                  <Col>
                    <Form.Item name="five_star_bd_lock_integral">
                      <Input style={{width: "250px", margin: "0 8px"}} min="0" suffix="开通店主积分"/>
                    </Form.Item>
                  </Col>
                  <Col>,每天释放(开通店主积分)</Col>
                  <Col>
                    <Form.Item name="five_star_green_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>为绿色积分,享受伞下报单级差奖励</Col>
                  <Col>
                    <Form.Item name="five_star_jicha_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col><span style={{margin: "0 0 0 70px"}}>伞下店铺流水（线上、线下）级差收益(绿色积分)</span></Col>
                  <Col>
                    <Form.Item name="five_star_store_water_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>奖励,商家冻积分释放比例</Col>
                  <Col>
                    <Form.Item name="five_star_store_lock_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="public-title">
                  <span>平级奖</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>报单五星平级奖励：</Col>
                  <Col>
                    <Form.Item name="m_zhitui_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col>门店流水五星平级奖励：</Col>
                  <Col>
                    <Form.Item name="m_district_reward_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} min="0" suffix="%"/>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Skeleton>
        </div>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(GeneralConfig)