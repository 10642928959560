import axios from "../../../axios"

function getList(params: any) {
  return axios.ajax({
    url: '/record/getRecordBalanceWithdrawList',
    method: "post",
    data: params,
    isShowLoading: false
  })
}

function edit(params: any) {

  return axios.ajax({
    url: '/record/doBalanceWithdraw',
    method: "post",
    data: params
  })
}

function revocation(params: any) {
  return axios.ajax({
    url: '/record/withdrawRevocation',
    method: "post",
    data: params,
    isShowLoading: false
  })

}

export default {
  getList,
  edit,
  revocation
}