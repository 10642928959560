import axios from "../../../axios"

function getList(params: any) {
  return axios.ajax({
    url: '/config/getSupplierList',
    method: "post",
    data: params,
  })
}

function add(params: any) {
  return axios.ajax({
    url: '/config/addSupplier',
    method: "post",
    data: params
  })
}

function edit(params: any) {
  return axios.ajax({
    url: '/config/editSupplier',
    method: "post",
    data: params
  })
}

function deleteData(params: any) {
  return axios.ajax({
    url: '/config/deletedSupplier',
    method: "post",
    data: params
  })
}

function upDownGood(params: any) {
  return axios.ajax({
    url: '/config/statusSupplier',
    method: "post",
    data: params
  })
}


export default {
  getList,
  add,
  edit,
  deleteData,
  upDownGood
}