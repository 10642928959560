import React, {useRef, useState, useEffect} from 'react';
import {connect} from "react-redux";
import {PlusOutlined} from '@ant-design/icons';
import {Button, DatePicker, Form, Input, message, Modal, Select, PageHeader, Breadcrumb} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import validate from "../../../utils/validate"
import {ListItem} from "./data"
import {CONFIG_TARGET_TYPE, SHOW_REGION_TYPE} from "@src/config/statusConfig"
import UploadOSS from '../../../components/UploadOSS';
import moment from 'moment';
import {RouteComponentProps} from 'react-router-dom';
import PickerColor from '../../../components/PickerColor';
import {getStoreAllList, getStoreGoodAllList} from '@src/config/getAsyncConfig';

const {RangePicker} = DatePicker;

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const {Option} = Select;
const Banner: React.FC<IProps> = (props: IProps) => {
  const [form2] = Form.useForm();
  const ref = useRef<ActionType>();
  const [title, setTitle] = useState<string>("");
  const [bannerType, setBannerType] = useState<string>("none");
  const [targeType, setTargeType] = useState<string>("");
  const [showType, setShowType] = useState<any>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [storeGoodAllList, setStoreGoodAllList] = useState<any>([]);
  const [storeAllList, setStoreAllList] = useState<any>([]);
  const [stateItem, setItem] = useState<any>({
    banner_id: "",
    banner_title: "",
    banner_desc: "",
    banner_sort: "",
    bg_color: "",
    image_url: "",
    banner_status: "",
    target_type: "",
    target_address: "",
    show_region_type: "",
    failure_at: ""
  })
  useEffect(() => {
    getStoreGoodAllList().then(res => {
      setStoreGoodAllList(res.data.list)
    });

    getStoreAllList().then(res => {
      setStoreAllList(res.data.list)
    })
  }, [])
  const getMenu = (_: any, item: ListItem) => {
    const {allRole} = props
    const {getMenuRole, menuRender} = Utils
    let menu = []
    if (getMenuRole("config/editBanner", allRole)) {
      menu.push(<a onClick={() => {
        edit(item)
      }}>编辑</a>)
    }

    if (getMenuRole("config/statusBanner", allRole)) {
      menu.push(<a className={item.banner_status === 1 ? "color-warning" : "color-success"} onClick={() => {
        upDownGood(item)
      }}>{item.banner_status === 1 ? "下架" : "上架"}</a>)
    }
    if (getMenuRole("config/deleteBanner", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    return menuRender(menu)
  }

  const add = () => {
    setBannerType("none")
    setTitle("新建")
    setVisible(true)
    setItem({
      banner_id: "",
      banner_title: "",
      banner_desc: "",
      banner_sort: "",
      bg_color: "",
      image_url: "",
      banner_status: "",
      target_type: "",
      target_address: "",
      show_region_type: "",
      failure_at: "",
      port_status: ''
    })
    form2.setFieldsValue({
      banner_id: "",
      banner_title: "",
      banner_desc: "",
      banner_sort: "",
      bg_color: "",
      image_url: "",
      banner_status: "",
      target_type: "",
      target_address: "",
      show_region_type: "",
      failure_at: "",
      is_auth: '',
      bannerType: '',
      port_status: ''
    })

  }

  const edit = (item: ListItem) => {
    setVisible(true)
    setTitle("编辑")
    if (item.target_type == 'good_detail' || item.target_type == "store") {
      item.target_address = +item.target_address;
    }
    setShowType(item.show_region_type)
    if (item.target_type == 'brand' || item.target_type == 'haoping' || item.target_type == 'jingxuan' || item.target_type == 'jumpstore' || item.target_type == 'youweiresou') {
      item.bannerType = 'internal_model'
      setBannerType('internal_model')
    } else {
      item.bannerType = item.target_type
      setBannerType(item.target_type)
    }
    if (item.failure_at == '0000-00-00 00:00:00') {
      item.failure_at = ''
    }
    form2.setFieldsValue({
      ...item, show_region_type: item.show_region_type + "",
      failure_at: item.failure_at ? [moment(item.start_at), moment(item.failure_at)] : item.failure_at
    })
    setItem({
      ...item
    })
    console.log(item)
  }

  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: '你确定要删除数据吗?',
      okType: 'danger',
      onOk() {
        Request.deleteData({banner_id: item.banner_id}).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    });
  }

  const upDownGood = (item: ListItem) => {
    Modal.confirm({
      title: item.banner_status === 1 ? "你确定要下架广告位吗?" : "你确定要上架广告位吗?",
      okType: 'danger',
      onOk() {
        Request.upDownGood({banner_id: item.banner_id, banner_status: item.banner_status === 1 ? 2 : 1}).then(() => {
          ref.current!.reload()
          message.success("操作成功")
        })
      }
    });
  }
  const visibleModal = () => {
    setVisible(!visible)
  }

  const formSubmit = (formVal: any) => {
    if (formVal.failure_at) {
      formVal.start_at = Utils.formateDate(formVal.failure_at[0]);
      formVal.failure_at = Utils.formateDate(formVal.failure_at[1])
    }
    if (formVal.bannerType == "good_detail") {
      formVal.target_type = "good_detail"
    } else if (formVal.bannerType == "web") {
      formVal.target_type = "web"
    } else if (formVal.bannerType == "store") {
      formVal.target_type = "store"
    } else if (formVal.bannerType == "none") {
      formVal.target_type = "none"
    }
    delete formVal.bannerType
    const callback = () => {
      visibleModal()
      ref.current!.reload()
      message.success("操作成功")
    }
    formVal.image_url = Utils.getUploadOSSUrl(formVal.image_url)[0]
    if (stateItem.banner_id) {
      formVal.banner_id = stateItem.banner_id
      Request.edit(formVal).then(callback)
    } else {
      Request.add(formVal).then(callback)
    }
  }
  const showTypeChange = (e) => {
    setShowType(e)
  }
  const columns: ProColumns<ListItem>[] = [
    {title: '广告标题', width: 180, dataIndex: 'keyword', hideInTable: true},
    {
      title: '广告图片', width: 120, dataIndex: 'image_url', search: false, render: (image_url: any) => {
        return <div style={{height: "50px"}}><img src={image_url} alt="" style={{maxHeight: "50px", maxWidth: "68px"}}/>
        </div>
      }
    },
    {title: '广告标题', width: 180, dataIndex: 'banner_title', search: false},
    {title: '广告区域', width: 120, dataIndex: 'show_region_type', valueEnum: SHOW_REGION_TYPE},
    {title: '广告类型', width: 120, dataIndex: 'target_type', valueEnum: CONFIG_TARGET_TYPE, search: false},
    {
      title: '广告状态', width: 80, dataIndex: 'banner_status', valueEnum: {
        1: {text: "显示", status: "Success"},
        2: {text: "不显示", status: "Error"}
      }
    },
    {
      title: '到期时间', width: 120, dataIndex: 'failure_at', search: false, render: (failure_at: any) => {
        return failure_at == "0000-00-00 00:00:00" ? " " : failure_at;
      }
    },
    {title: '广告权重', width: 180, dataIndex: 'banner_sort', search: false},
    {title: '操作', align: 'center', width: 160, dataIndex: 'id', search: false, fixed: 'right', render: getMenu}
  ];

  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20}
  }
  return (
      <>
        <PageHeader
            title='APP广告'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>运营管理</Breadcrumb.Item>
              <Breadcrumb.Item>APP广告</Breadcrumb.Item></Breadcrumb>}></PageHeader>
        <div className="wrapContent">
          <ProTable<ListItem>
              rowKey="banner_id"
              tableStyle={{padding: '0 24px 0 24px'}}
              columns={columns}
              actionRef={ref}
              request={async (params: any) => {
                params.status = 1
                const res = await Request.getList(params);
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.current
                }
              }
              }
              toolBarRender={() => []}
              toolbar={{
                title: (Utils.getMenuRole("config/addBanner", props.allRole) &&
                    <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={add}> 新建 </Button>),
                filter: ''
              }}
              options={{density: false, fullScreen: false, reload: false, setting: false}}
          />
        </div>
        <Modal destroyOnClose title={title} visible={visible} onCancel={visibleModal} footer={false} width={800}>
          <Form
              {...formItemLayout}
              form={form2}
              requiredMark={false}
              onFinish={formSubmit}
              initialValues={{
                ...stateItem,
                show_region_type: stateItem.show_region_type + "",
                failure_at: stateItem.failure_at ? [moment(stateItem.start_at), moment(stateItem.failure_at)] : stateItem.failure_at
              }}
          >

            <Form.Item label="广告标题" name="banner_title" rules={[{required: true}]}>
              <Input placeholder="请输入广告标题"/>
            </Form.Item>
            <Form.Item
                label="图片"
                name="image_url"
                valuePropName="filelist"
                rules={[{required: true, message: "请选择图片"}]}
                help={<span className="font5 color-danger">
                  首页轮播图建议尺寸(710.2*245.5))<br/>
                  首页胶囊建议尺寸(684*211 <br/>
                </span>}
            >
              <UploadOSS/>
            </Form.Item>

            <Form.Item label="显示区域" name="show_region_type" rules={[{required: true}]}>
              <Select placeholder="请选择" onChange={showTypeChange}>
                {Utils.getOptionList(SHOW_REGION_TYPE)}
              </Select>
            </Form.Item>

            <Form.Item label="广告类型" name="bannerType" rules={[{required: true}]}>
              <Select placeholder="请选择" value={bannerType} onChange={v => setBannerType(v)}>
                <Option value="none">纯展示</Option>
                <Option value="internal_model">内部地址</Option>
                <Option value="good_detail">商品详情</Option>
                <Option value="web">外部链接</Option>
                <Option value="store">店铺内页</Option>
              </Select>
            </Form.Item>

            {bannerType == "internal_model" && <Form.Item label="目标地址" name="target_type" rules={[{required: true}]}>
                <Select placeholder="请选择" value={targeType} onChange={v => setTargeType(v)}>
                    <Option value="brand">品牌店铺专区</Option>
                    <Option value="haoping">好评店铺专区</Option>
                    <Option value="jingxuan">精选好店专区</Option>
                    <Option value="youweiresou">以太热搜</Option>
                    <Option value="jumpstore">报单板块专区</Option>
                </Select>
            </Form.Item>}

            {bannerType == "web" && <Form.Item label="目标地址" name="target_address" rules={[{required: true}]}>
                <Input placeholder="请输入目标地址"/>
            </Form.Item>}

            {bannerType == "good_detail" && <Form.Item label="店铺商品名称" name="target_address" rules={[{required: true}]}>
                <Select showSearch optionFilterProp="children" placeholder="请选择">
                  {Utils.getOptionList(storeGoodAllList, "store_good_id", "title")}
                </Select>
            </Form.Item>}

            {bannerType == "store" && <Form.Item label="店铺" name="target_address" rules={[{required: true}]}>
                <Select showSearch optionFilterProp="children" placeholder="请选择">
                  {Utils.getOptionList(storeAllList, "store_id", "store_name")}
                </Select>
            </Form.Item>}

            <Form.Item label="背景颜色" name="bg_color">
              <PickerColor/>
            </Form.Item>

            <Form.Item label="广告权重" name="banner_sort" rules={[{validator: validate.checLessThan0}]}>
              <Input type="number" placeholder="排序(数值越大排序越靠前)"/>
            </Form.Item>

            <Form.Item label="起止日期" name="failure_at">
              <RangePicker showTime/>
            </Form.Item>

            <Form.Item label="状态" name="banner_status" rules={[{required: true}]}>
              <Select placeholder="请选择">
                <Select.Option value={1}>显示</Select.Option>
                <Select.Option value={2}>不显示</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="是否授权" name="is_auth" rules={[{required: true}]}>
              <Select placeholder="请选择">
                <Option value={0}>否（H5链接直接跳转页面）</Option>
                <Option value={1}>APP授权（H5链接需要传token,用户需要登录）</Option>
              </Select>
            </Form.Item>

            <Form.Item label="备注" name="banner_desc" rules={[{required: true}]}>
              <Input placeholder="请输入备注"/>
            </Form.Item>

            <Form.Item {...{wrapperCol: {offset: 3}}}>
              <Button type="primary" className="mr20" htmlType="submit">立即提交</Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(Banner);