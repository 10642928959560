import React, {useState, useEffect, useRef} from 'react'
import Request from './Request'
import {connect} from 'react-redux'
import {
  Button,
  Form,
  Input,
  message,
  Col,
  Row,
  Select,
  Checkbox,
  Tabs,
  Dropdown,
  Menu,
  DatePicker,
  Pagination,
  PageHeader,
  Breadcrumb,
} from 'antd'
import {DownOutlined, UpOutlined} from '@ant-design/icons'
import Utils from '../../../utils/utils'
import {ORDER_STATUS, PAY_METHOD} from '@src/config/statusConfig'
import {RouteComponentProps} from 'react-router-dom'

import './index.less'

const {RangePicker} = DatePicker
const CheckboxGroup = Checkbox.Group

interface IProps extends RouteComponentProps {
  dispatch: any
  allRole: any[]
}

const {TabPane} = Tabs
let exportsFilter: any = {}
const GoodStatus = [
  {
    title: '全部',
    param: {order_status: -1},
  },
  {
    title: '待发货',
    param: {
      order_status: 2,
    },
  },
  {
    title: '待收货',
    param: {
      order_status: 3,
    },
  },
  {
    title: '已确认收货',
    param: {
      order_status: 5,
    },
  },
  {
    title: '待退款',
    param: {
      order_status: 6,
    },
  },
  {
    title: '已退款',
    param: {
      order_status: 7,
    },
  },

]
const OrderList: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm()
  const [tabKey, setTabKey] = useState<any>('0')
  const [goodList, setGoodList] = useState<any[]>([{good_title: '商品1'}])
  const [total, setTotal] = useState<any>('0')
  const [expand, setExpand] = useState(false)
  const [searchKeys, setSearchKeys] = useState<string>('order_no')
  const [timeSlot, setTimeSlot] = useState<any[]>([])
  const [searchParams, setSearchParams] = useState<any>({
    current: 1,
    pageSize: 10,
  })
  const [checkedList, setCheckedList] = React.useState([])
  const [timeType, setTimeType] = useState<any>({
    timeName: '下单时间',
    timeValue: 'created_at',
  })


  useEffect(() => {

    func()
  }, [])
  //获取列表数据
  const getList = async () => {
    searchParams.order_status = GoodStatus[tabKey].param.order_status
    const res = await Request.getList(searchParams)
    setGoodList(res.data.list)
    let arr = []
    res.data.list.forEach((item: any) => {
      arr.push(item.order_id)
    })
    setTotal(res.data.count)
  }
  const changeParams = (key: any, value: any) => {
    let newData = searchParams
    newData[key] = value
    setSearchParams(newData)
  }
  //列表选择点击事件
  const onChange = (list) => {
    setCheckedList(list)
  }

  //查看物流详情
  const toexpressDetail = (id) => {
    const {history, dispatch} = props
    let path = `/order/store_order_express/${id}`
    history.push({
      pathname: path,
    })
  }
  const exportsDown = () => {
    let url = process.env.REACT_APP_API_URL + '/order/exportStoreOrderList?token=' + Utils.getToken()
    exportsFilter = JSON.parse(JSON.stringify(searchParams))
    delete exportsFilter.current
    delete exportsFilter.pageSize
    if (checkedList.length > 0) exportsFilter.order_ids = checkedList.join(',')
    for (const key in exportsFilter) {
      if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
        const element = exportsFilter[key]
        url += `&${key}=${element}`
      }
    }
    window.open(url, '_blank')
  }
  const selectBefore = (
      <Select
          value={searchKeys}
          style={{width: 100}}
          onChange={(e) => {
            setSearchKeys(e)
          }}>
        <Select.Option value="order_no">订单号</Select.Option>
        <Select.Option value="mobile_purchase">手机号</Select.Option>
        <Select.Option value="address_receiver">收货姓名</Select.Option>
        <Select.Option value="address_mobile">收货电话</Select.Option>
        <Select.Option value="f_store_name">发货店铺</Select.Option>
        <Select.Option value="m_store_name">售卖店铺</Select.Option>
      </Select>
  )
  const menu = (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="created_at" title="下单时间">下单时间</Menu.Item>
        <Menu.Item key="paid_at" title="付款时间">付款时间</Menu.Item>
        <Menu.Item key="send_express_at" title="发货时间">发货时间</Menu.Item>
        <Menu.Item key="receive_at" title="签收时间">签收时间</Menu.Item>
      </Menu>
  )

  function handleMenuClick(e) {
    setTimeType({timeName: e.item.props.title, timeValue: e.key})
  }

  //查看详情
  const todetail = (id: string | number = 'new') => {
    window.open(`/#/order/store_order_detail/${id}`)
  }

  const tabsChange = (v) => {
    setTabKey(v)
    setSearchParams({
      current: searchParams.current,
      pageSize: searchParams.pageSize,
    })
    func()
  }
  //同步hooks
  const func = Utils.useSyncCallback(() => {
    getList()
  })
  //分页事件
  const pagingChange = (e, r) => {
    let obj = searchParams
    obj.current = e
    obj.pageSize = r
    setSearchParams(obj)
    getList()
  }
  const SearchSubmit = (obj: any) => {
    //时间
    if (timeSlot[0] && timeSlot[1]) {
      if (timeType.timeValue == 'created_at') {
        obj.start_time_created = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_created = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else if (timeType.timeValue == 'paid_at') {
        obj.start_time_paid = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_paid = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else if (timeType.timeValue == 'send_express_at') {
        obj.start_time_sended = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_sended = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else if (timeType.timeValue == 'receive_at') {
        obj.start_time_signed = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_signed = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else {
        obj.start_time_signed = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_signed = timeSlot[1].format('YYYY-MM-DD HH:mm')
      }
    }
    obj.current = 1
    obj.pageSize = 10
    //订单金额
    if (searchParams.pay_good_fee_start && searchParams.pay_good_fee_end) {
      obj.pay_good_fee_start = searchParams.pay_good_fee_start
      obj.pay_good_fee_end = searchParams.pay_good_fee_end
    } else if (
        searchParams.pay_good_fee_start ||
        searchParams.pay_good_fee_end
    ) {
      message.error('请输入完整的订单金额区间')
      return {
        data: [],
        success: false,
      }
    }
    //实付金额
    if (searchParams.pay_fee_start && searchParams.pay_fee_end) {
      obj.pay_fee_start = searchParams.pay_fee_start
      obj.pay_fee_end = searchParams.pay_fee_end
    } else if (searchParams.pay_fee_start || searchParams.pay_fee_end) {
      message.error('请输入完整的实付金额区间')
      return {
        data: [],
        success: false,
      }
    }
    setSearchParams(obj)
    func()
  }
  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20},
  }

  const viewLayout2 = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
  }
  return (
      <>
        <PageHeader title="店铺订单列表"
                    className="site-page-header"
                    breadcrumb={
                      <Breadcrumb>
                        <Breadcrumb.Item>订单</Breadcrumb.Item>
                        <Breadcrumb.Item>店铺订单列表</Breadcrumb.Item>
                      </Breadcrumb>
                    }
                    footer={
                      <Tabs defaultActiveKey="0" onChange={tabsChange}>
                        {GoodStatus.map((statusItem: any, index: number) => {
                          return (<TabPane closable={false} tab={statusItem.title} key={index}/>)
                        })}
                      </Tabs>}/>
        <div className="wrapContent waterwrap">
          <div className="wrap mb16">
            <Form
                {...formItemLayout}
                requiredMark={false}
                onFinish={SearchSubmit}
                form={form}>
              <Row>
                <Col span={6}>
                  <Form.Item labelCol={{span: 0}} wrapperCol={{span: 22}} label="" name={searchKeys}>
                    <Input addonBefore={selectBefore}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item labelCol={{span: 0}} wrapperCol={{span: 22}} label="" name="time">
                    <div style={{display: 'flex'}}>
                      <Dropdown overlay={menu} trigger={['click']}>
                        <Button onClick={(e) => e.preventDefault()}>
                          {timeType.timeName} <DownOutlined/>
                        </Button>
                      </Dropdown>
                      <RangePicker
                          showTime={{format: 'HH:mm'}}
                          format="YYYY-MM-DD HH:mm"
                          value={[timeSlot[0], timeSlot[1]]}
                          onChange={(d) => {
                            d ? setTimeSlot([d[0], d[1]]) : setTimeSlot([])
                          }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item {...viewLayout2} label="商品名" name="goods_name">
                    <Input placeholder="请输入商品搜索"></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button type="primary" htmlType="submit">查询</Button>
                  <Button
                      style={{margin: '0 8px'}}
                      onClick={() => {
                        form.resetFields()
                        setTimeSlot([])
                        setSearchParams({
                          current: 1,
                          pageSize: searchParams.pageSize,
                        })
                        func()
                      }}>
                    重置
                  </Button>
                  <a onClick={() => {
                    setExpand(!expand)
                  }}>
                    {expand ? (<>收起<UpOutlined/></>) : (<>展开<DownOutlined/></>)}
                  </a>
                </Col>

              </Row>
              {expand ? (
                  <Row>
                    <Col span={6}>
                      <Form.Item{...viewLayout2} label="实付金额" name="order_source4">
                        <Input
                            type="number"
                            style={{width: 80}}
                            defaultValue={searchParams.pay_fee_start}
                            onChange={(e) => {
                              changeParams('pay_fee_start', e.target.value)
                            }}
                        />
                        &nbsp; - &nbsp;
                        <Input
                            type="number"
                            style={{width: 80}}
                            defaultValue={searchParams.pay_fee_end}
                            onChange={(e) => {
                              changeParams('pay_fee_end', e.target.value)
                            }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
              ) : (
                  ''
              )}
            </Form>
          </div>
          <div className="wrap">
            <div className="mb16">
              <div className="mb16">
                {(Utils.getMenuRole("order/exportStoreOrderList", props.allRole) ? (
                    <Button type="primary" key="order/exportStoreOrderList" onClick={exportsDown}>导出</Button>) : '')}
              </div>
            </div>
            <Row className="tabtr">
              <Col span={9}>商品信息</Col>
              <Col span={3}>支付信息</Col>
              <Col span={9}>配送信息</Col>
              <Col span={3}>操作</Col>
            </Row>
            <div className="goodList">
              <CheckboxGroup value={checkedList} onChange={onChange}>
                {goodList.map((item: any, index: any) => {
                  return (
                      <div className="good" key={index + item.order_no}>
                        <Row className="goodhead">
                          <Col span={3}>手机号：{item.mobile}</Col>
                          <Col span={3}>订单号：{item.order_no}</Col>
                          <Col span={5}>下单时间：{item.created_at}</Col>
                          <Col className="textcenter" span={3}>订单状态： {ORDER_STATUS[item.order_status]}</Col>
                        </Row>
                        <Row className="goodcontent">
                          <Col className="goodmsg" span={9}>
                            <div className="content">
                              <img src={item.good_main_media_url} alt="" style={{height: '100px',}}/>
                              <div>
                                <div className="good-title" title={item.good_title}>{item.good_title}</div>
                                <div>规格:{item.spec_title}</div>
                                <div>
                                  <span style={{color: 'red', marginRight: '20px'}}>{item.pay_unit_fee}</span>
                                  <span>X&nbsp;{item.purchase_count}</span>
                                </div>
                                <div className="font5">售卖店铺：{item.m_store_name}</div>
                                <div className="font5">发货店铺：{item.f_store_name}</div>
                                <div className="font5">
                                  商家冻积分：{item.lock_store_integral}
                                  &nbsp;&nbsp;&nbsp;
                                  用户冻积分：{item.lock_integral}
                                  &nbsp;&nbsp;&nbsp;
                                  状态：{item.is_sh == 1 ? '已发放' :  (item.order_status == 7 ? '已退款' : '未发放')}
                                </div>
                              </div>
                            </div>

                          </Col>
                          <Col span={3}>
                            <div>

                              <div style={{color: 'red'}}>实付款:¥{item.pay_fee}</div>
                              <div className="font5">订单总金额:{item.pay_fee_all}</div>
                              <div className="font5">支付方式:{PAY_METHOD[item.pay_method]}</div>
                              <div style={{color: 'green'}} className="font5">绿色积分:{item.green_integral}</div>
                              <div style={{color: '#fe6673'}} className="font5">幸福值:{item.happy_integral}</div>
                            </div>
                          </Col>
                          <Col className="goodmsg" span={9}>
                            <div className="content">
                              <img
                                  src={require('../../../resources/assets/icon4.png')}
                                  alt=""
                                  style={{
                                    width: '25px',
                                    marginRight: '10px',
                                    height: '25px',
                                  }}
                              />
                              <div>
                                <div>收货人：{item.address_receiver}</div>
                                <div>收货人电话：{item.address_mobile}</div>
                                <div>收货地址：{item.province_name} {item.city_name} {item.district_name} {item.address_detail}</div>
                              </div>
                            </div>
                          </Col>
                          <Col span={3}>
                            {(Utils.getMenuRole("order/getStoreOrderInfo", props.allRole) ? (
                                <a className="mr10" onClick={() => {
                                  todetail(item.order_id)
                                }}>查看</a>) : '')}
                            {item.order_status >= 3 && item.order_status != 9 && item.refund_order_status != 2 && ((Utils.getMenuRole("order/getStoreOrderExpress", props.allRole) ? (
                                <a onClick={() => {
                                  toexpressDetail(item.order_id)
                                }}>物流追踪</a>) : ''))}
                          </Col>
                        </Row>
                        <div className="goodbottom ellipsis">{' '}
                          备注：{item.order_remark}
                        </div>
                      </div>
                  )
                })}
              </CheckboxGroup>
            </div>
            <div className="page mb16 mt15">
              <Pagination
                  total={total}
                  showSizeChanger
                  current={searchParams.current}
                  defaultCurrent={1}
                  onChange={pagingChange}
                  showQuickJumper
                  showTotal={(total) => `第 1-${total} 条/总共 ${total} 条`}
              />
            </div>
          </div>
        </div>
      </>
  )
}

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole,
  }
}

export default connect(mapStateToProps)(OrderList)
