import axios from '../../../axios'

function getList(params: any) {
  return axios.ajax({
    url: '/order/getOnlineOrderList',
    method: 'post',
    data: params,
  })
}


export default {
  getList,

}
