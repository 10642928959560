import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {
  Button,
  message,
  Modal,
  Tabs,
  PageHeader,
  Divider,
  Breadcrumb, Form, Input, Select, Col, Row, Image,
} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import {ListItem} from "./data"
import Skeleton from '../../../components/Skeleton';
import {IS_UPLOAD_PUBLIC} from "@src/config/statusConfig";

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any
}

let TEMP_CHECK_ROW: any[] = [];
let exportsFilter: any = {};
const GoodStatus = [
  {title: "待审核", param: {status: 0}},
  {title: "全部", param: {status: -1}},
  {title: "已通过", param: {status: 1}},
  {title: "已驳回", param: {status: 2}}
];

const StoreGood: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm()
  const ref = useRef<ActionType>();
  const [tabKey, setTabKey] = useState<any>("0");
  const [visible, setVisible] = useState<boolean>(false);
  const [visible2, setVisible2] = useState<boolean>(false);
  const [storeGoodInfo, setStoreGoodInfo] = useState<any>();
  const [auditDesc, setAuditDesc] = useState<boolean>(false);
  const [adminDescValue, setAdminDescValue] = useState<string>('平台下架');
  const [dowValue, setDowValue] = useState<any>();

  useEffect(() => {
    //获取标签
  }, [])

  const tabChange = (key: any) => {
    setTabKey(key)
    TEMP_CHECK_ROW = [];
    ref.current.reset();
  }

  //操作按钮
  const getMenu = (_: any, item: ListItem) => {
    const {allRole} = props
    const {getMenuRole, menuRender} = Utils
    let menu = []
    if (getMenuRole("good/auditStatus", allRole) && item.audit_status == 0 && item.level5_store_id == item.store_id) {
      menu.push(<a className="color-danger" onClick={() => {
        view(item);
      }}>审核</a>)
    }
    if (getMenuRole("good/editGood", allRole)) {
      menu.push(<a onClick={() => {
        view(item);
      }}>查看</a>)
    }
    if (getMenuRole("good/adminStatus", allRole) && item.audit_status == 1) {
      menu.push(<a className={item.admin_status === 1 ? "color-warning" : "color-success"} onClick={() => {
        console.log(11)
        if (item.good_id > 0 && item.is_upload_public == 0) {
          Modal.confirm({
            title: "提示!",
            content: "选品商品，不能在此处下架、下架！请到平台商品列表中下架！",
            okType: 'danger',

          })
        } else {
          adminStatus(item)
        }
      }}>{item.admin_status === 1 ? "下架" : "上架"}</a>)
    }
    return menuRender(menu)
  }

  const visibleModal = () => {
    setVisible(!visible)
  }

  const visibleModal2 = () => {
    setVisible2(!visible2)
  }

  const dowOnOk = (e) => {
    Modal.confirm({
      title: "你确定要下架店铺商品吗?",
      okType: 'danger',
      onOk() {
        Request.adminStatus({
          store_good_id: JSON.stringify([{"store_good_id": dowValue.store_good_id}]),
          admin_status: 2,
          admin_desc: adminDescValue,
        }).then(() => {
          ref.current!.reload()
          message.success("操作成功")
          setVisible2(false)
        })
      }
    })
  }
  const adminDesc = (v) => {
    setAdminDescValue(v.target.value)
  }

  const view = async (item) => {
    let item2 = await Request.getStoreGoodInfo({store_good_id: item.store_good_id});
    setVisible(true)
    setStoreGoodInfo(item2.data)
  }

  //审核选择
  const typeChange = (v) => {
    if (v == 2) {
      setAuditDesc(true)
    } else {
      setAuditDesc(false)
    }
  }
  //上下架商品
  const adminStatus = (item: ListItem) => {
    if (item.admin_status === 1) {
      setVisible2(!visible2)
      setDowValue(item)
    } else {
      Modal.confirm({
        title: "你确定要上架店铺商品吗?",
        okType: 'danger',
        onOk() {
          Request.adminStatus({
            store_good_id: JSON.stringify([{"store_good_id": item.store_good_id}]),
            admin_status: 1
          }).then(() => {
            ref.current!.reload()
            message.success("操作成功")
          })
        }
      })
    }
  }

  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20}
  }

  const formSubmit = (formVal: any) => {
    const callback = () => {
      message.success("操作成功")
      setVisible(false)
      ref.current!.reload()
    }
    formVal.store_good_id = storeGoodInfo.store_good_id
    Request.auditStatus(formVal).then(callback)
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', width: 60, align: 'center', dataIndex: 'store_good_id', search: false, fixed: 'left'},
    {title: '关键词', width: 80, dataIndex: 'q', hideInTable: true},
    // $ret['store_image'] = $store_info->store_image ? $store_info->store_image . '?x-oss-process=image/resize,m_lfit,w_141' : 'https://wangxiaoma.oss-cn-hangzhou.aliyuncs.com/20230302/167773941265694.png';
    {
      title: '店铺名称', width: 220, dataIndex: 'good_title', search: false, render: (_, record) => {
        return <span>
          <img
              src={record.store_img ? record.store_img : "https://wangxiaoma.oss-cn-hangzhou.aliyuncs.com/20230302/167773941265694.png"}
              alt="" style={{width: "40px", height: "40px"}}/>
          &nbsp;&nbsp;{record.store_name}
        </span>
      }
    },
    {title: '商品名称', width: 220, dataIndex: 'good_title', search: false},
    {
      title: '商品主图', width: 80, search: false, align: "center", render: (_, record) => {
        return <img
            src={record.good_main_media_url ? record.good_main_media_url : require("../../../resources/assets/none.png")}
            alt="" style={{width: "50px", height: "50px"}}/>
      }
    },
    {title: '总库存', width: 80, dataIndex: 'all_spec_stock', search: false},
    {title: '最小规格价', width: 80, dataIndex: 'min_spec_price', search: false},
    {title: '最小规格原价', width: 80, dataIndex: 'min_spec_original_price', search: false},
    {title: '最小规格成本价', width: 80, dataIndex: 'min_spec_cost_price', search: false},
    {title: '销量', width: 80, dataIndex: 'sale_volume', search: false},
    {
      title: '审核状态', align: 'center', width: 70, dataIndex: 'audit_status', search: false, render: (_, record) => {
        if (record.level5_store_id == record.store_id) {
          if (record.audit_status == 0) {
            return <span className="color-danger">待审核</span>;
          } else if (record.audit_status == 1) {
            return <span className="color-success">审核成功</span>;
          } else if (record.audit_status == 2) {
            return <span className="color-danger">审核失败</span>;
          }
        }
        return '-';
      }
    },
    {
      title: '后台状态', align: 'center', width: 70, dataIndex: 'admin_status', search: false, render: (_, record) => {
        if (record.admin_status == 1) {
          return <span className="color-success">上架中</span>;
        } else {
          return <span className="color-danger">已下架</span>;
        }
      }
    },
    {
      title: '店铺状态', align: 'center', width: 70, dataIndex: 'good_status', search: false, render: (_, record) => {
        if (record.good_status == 1) {
          return <span className="color-success">上架中</span>;
        } else {
          return <span className="color-danger">已下架</span>;
        }
      }
    },
    {
      title: '五星店主上下架状态',
      align: 'center',
      width: 100,
      dataIndex: 'level5_status',
      search: false,
      render: (_, record) => {
        if (record.store_good_id > 0) {
          if (record.level5_status == 0) {
            return <span className="color-primary">未上架</span>;
          } else if (record.level5_status == 1) {
            return <span className="color-success">已上架</span>;
          } else if (record.level5_status == 2) {
            return <span className="color-danger">已下架</span>;
          }
        } else {
          return '-'
        }
      }
    },
    {
      title: '公域池',
      align: 'center',
      width: 1,
      dataIndex: 'is_upload_public',
      search: true,
      valueEnum: IS_UPLOAD_PUBLIC,
      hideInTable: true
    },
    {
      title: '公域池', align: 'center', width: 50, dataIndex: 'is_upload_public', search: false, render: (_, record) => {
        if (record.level5_store_id == record.store_id) {
          if (record.is_upload_public == 1) {
            return '是';
          }
          return '否'
        }
        return '-';
      }
    },
    //  IS_UPLOAD_PUBLIC
    {
      title: '商品类型', width: 90, dataIndex: 'is_upload_public', search: false, render: (_, record) => {
        if (record.good_id > 0) {
          if (record.level5_store_id > 0) {
            return '拉取五星店主商品';
          } else {
            return '拉取平台库商品';
          }
        } else {
          return '店铺商品';
        }
      }
    },
    {title: '更新时间', width: 110, dataIndex: 'updated_at', search: false, align: "center"},
    {title: '添加时间', width: 110, dataIndex: 'created_at', search: false, align: "center"},
    {title: '操作', align: 'center', fixed: 'right', width: 70, dataIndex: 'id', search: false, render: getMenu},
  ];

  return (
      <>
        <Skeleton loading={false}>
          <PageHeader
              title='店铺商品列表'
              className="site-page-header"
              breadcrumb={<Breadcrumb><Breadcrumb.Item>商品</Breadcrumb.Item>
                <Breadcrumb.Item>店铺商品</Breadcrumb.Item></Breadcrumb>}
              footer={
                <Tabs defaultActiveKey="0" onChange={tabChange}>
                  {GoodStatus.map((statusItem: any, index: number) => {
                    return (<TabPane closable={false} tab={statusItem.title} key={index}></TabPane>);
                  })}
                </Tabs>
              }
          >
          </PageHeader>
          <div className="wrapContent">
            <ProTable<ListItem>
                rowKey="store_good_id"
                tableStyle={{padding: '0 24px 0 24px'}}
                columns={columns}
                actionRef={ref}
                scroll={{x: 2700}}
                request={async (params: any) => {
                  params = Object.assign(
                      JSON.parse(JSON.stringify(GoodStatus[+tabKey].param)),
                      params
                  );
                  exportsFilter = JSON.parse(JSON.stringify(params));
                  const res = await Request.getList(params);
                  return {
                    data: res.data.list,
                    total: res.data.count,
                    success: true,
                    pageSize: params.pageSize,
                    current: params.pageSize
                  }
                }
                }
                pagination={{
                  defaultPageSize: 20,
                }}
            />
          </div>
        </Skeleton>
        <Modal
            destroyOnClose
            title='商品详情'
            visible={visible}
            onCancel={visibleModal}
            footer={false}
            width={1000}
        >
          <Row>
            <Col span={24}>
              <Form.Item labelCol={{span: 2, offset: 1}} label="商品名称">{storeGoodInfo?.good_title}</Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="商品封面" labelCol={{span: 2, offset: 1}} labelAlign="right">
                <Image width={140} src={storeGoodInfo?.good_main_media_url}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="商品主图" labelCol={{span: 2, offset: 1}} labelAlign="right">
                <Image.PreviewGroup>
                  {storeGoodInfo?.good_media_url_gather.map(i => (
                      <Image key={i} width={140} style={{padding: "0 10px 0 0"}} src={i}/>))}
                </Image.PreviewGroup>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="商品分类" labelCol={{span: 2, offset: 1}} labelAlign="right">
                {storeGoodInfo?.category_name}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="标签" labelCol={{span: 2, offset: 1}} labelAlign="right">
                {storeGoodInfo?.tag}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="规格" labelCol={{span: 2, offset: 1}} labelAlign="right">
                <Row>
                  <Col span={7} style={{textAlign: "center"}}>规格名称</Col>
                  <Col span={4} style={{textAlign: "center"}}>成本价</Col>
                  <Col span={4} style={{textAlign: "center"}}>售价</Col>
                  <Col span={4} style={{textAlign: "center"}}>原价</Col>
                  <Col span={4} style={{textAlign: "center"}}>库存数量</Col>
                </Row>
                <Divider style={{margin: "14px 0"}}/>
                {storeGoodInfo?.spec_list.map(i => (
                    <Row key={i.store_spec_id}>
                      <Col span={7} style={{textAlign: "center"}}>{i.spec_title}</Col>
                      <Col span={4} style={{textAlign: "center"}}>{i.spec_cost_price}</Col>
                      <Col span={4} style={{textAlign: "center"}}>{i.spec_price}</Col>
                      <Col span={4} style={{textAlign: "center"}}>{i.spec_original_price}</Col>
                      <Col span={4} style={{textAlign: "center"}}>{i.spec_stock}</Col>
                      <Divider style={{margin: "6px 0"}}/>
                    </Row>
                ))}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="商品详情图" labelCol={{span: 2, offset: 1}} labelAlign="right">
                <Image.PreviewGroup>
                  {storeGoodInfo?.good_detail_html.map(i => (
                      <Image width={140} key={i} style={{padding: "0 10px 0 0"}} src={i}/>))}
                </Image.PreviewGroup>
              </Form.Item>

            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="店铺状态" labelCol={{span: 2, offset: 1}} labelAlign="right">
                {storeGoodInfo?.good_status == 1 ? <span className="color-success">上架中...</span> : "未上架"}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item label="审核状态" labelCol={{span: 2, offset: 1}} labelAlign="right">
                {storeGoodInfo?.audit_status == 1 ?
                    <span className="color-success">已通过</span> : storeGoodInfo?.audit_status == 0 ? "待审核" : "已驳回"}
              </Form.Item>
            </Col>
          </Row>

          {storeGoodInfo?.audit_status == 2 && <Row>
              <Col span={24}>
                  <Form.Item label="驳回原因" labelCol={{span: 2, offset: 1}} labelAlign="right">
                      <span className="color-danger">{storeGoodInfo?.audit_desc}</span>
                  </Form.Item>
              </Col>
          </Row>}
          {storeGoodInfo?.audit_status == 0 &&
          <Form{...formItemLayout} requiredMark={false} form={form} onFinish={formSubmit}>
              <Row>
                  <Col span={24}>
                      <Form.Item label="审核选择" labelCol={{span: 2, offset: 1}} name="admin_status"
                                 rules={[{required: true}]}>
                          <Select placeholder="请选择审核状态" style={{width: "300px"}} onChange={typeChange}>
                              <Select.Option value={1}>通过</Select.Option>
                              <Select.Option value={2}>驳回</Select.Option>
                          </Select>
                      </Form.Item>

                  </Col>
              </Row>
            {auditDesc &&
            <Row>
                <Col span={24}>
                    <Form.Item label="驳回原因" labelCol={{span: 2, offset: 1}} name="audit_desc"
                               rules={[{required: true}]}>
                        <Input.TextArea placeholder="请输入板块介绍 " rows={5}/>
                    </Form.Item>
                </Col>
            </Row>
            }
              <Form.Item {...{wrapperCol: {offset: 3}}}>
                  <Button type="primary" htmlType="submit">立即提交</Button>
              </Form.Item>
          </Form>
          }
        </Modal>
        <Modal
            destroyOnClose
            title="下架"
            visible={visible2}
            onCancel={visibleModal2}
            onOk={dowOnOk}
            width={800}
        >
          <Form{...formItemLayout} requiredMark={false} form={form} onFinish={formSubmit}>
            <Row>
              <Col span={24}>
                <Form.Item label="下架原因" labelCol={{span: 2, offset: 1}} name="admin_desc" rules={[{required: true}]}>
                  <Input.TextArea placeholder="请输入下架原因 " defaultValue="平台下架" onChange={adminDesc} rows={5}/>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Modal>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(StoreGood)