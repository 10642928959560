import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Card, message } from 'antd';
import EditTable from '../../../../components/Editable';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import Utils from "../../../../utils/utils"
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const WaterConfig: React.FC<IProps> = (props: IProps) => {

  const [listData, setListData] = useState<any>([])
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)

  const initList = async () => {
    const res = await Request.getList()
    let { data } = res
    let dataSource = []
    dataSource.push({
      name: "转赠服务费",
      value: data.accumulation_fund.value
    })
    dataSource.push({
      name: "转出服务费",
      value: data.withdrawal_procedure.value
    })
    dataSource.push({
      name: "每日释放水滴比例",
      value: data.day_water.value
    })
    setListData(dataSource)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initList()
  }, [])

  const edit = (data: any[]) => {
    let config_value = {
      accumulation_fund: {
        value: data[0].value,
        desc: "转赠服务费"
      },
      withdrawal_procedure: {
        value: data[1].value,
        desc: "转出服务费"
      },
      day_water: {
        value: data[2].value,
        desc: "每日释放水滴比例"
      }
    }
    Request.edit({ config_value: JSON.stringify(config_value) }).then(() => {
      message.success("保存成功")
    })
  }

  const columns = [
    {
      title: '服务',
      width: "40%",
      dataIndex: 'name'
    }, {
      title: '费率(%)',
      width: "40%",
      dataIndex: 'value',
      editable: "NUMBER"
    }
  ]

  const editCol = Utils.getMenuRole("config/edit_water_config", props.allRole)

  return (
    <Card>
      <Skeleton loading={showSkeleton}>
        {listData.length > 0 && (
          <EditTable
            edit={{ modify: editCol, add: false, del: false }}
            originData={listData}
            columns={columns}
            onChange={(newData) => {
              edit(newData)
            }}
          />
        )}
      </Skeleton>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(WaterConfig);