import React, {useState, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {Card, message, Button, Input, Form} from 'antd';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import Utils from "../../../../utils/utils"
import UploadOSS from '../../../../components/UploadOSS';
import {RouteComponentProps} from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

interface IState {
  register_url: string
  background_image_url_gather: string[]
}

const InviteFriend: React.FC<IProps> = (props: IProps) => {

  const [configData, setConfigData] = useState<IState>({
    register_url: "",
    background_image_url_gather: []
  })
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)

  const initList = async () => {
    const res = await Request.getList()
    let {data} = res
    setConfigData(data)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initList()
  }, [])

  const formItemLayout = {
    labelCol: {span: 2},
    wrapperCol: {span: 20}
  }
  const childRef = useRef();

  const formSubmit = async (formVal: any) => {
    formVal.background_image_url_gather = Utils.getUploadOSSUrl(formVal.background_image_url_gather)
    let config_value: any = {
      background_image_url_gather: Utils.getUploadOSSUrl(formVal.background_image_url_gather),
      register_url: formVal.register_url
    }
    let obj = {"config_value": JSON.stringify(config_value)}
    Request.edit(obj).then(async () => {
      message.success("保存成功")
      const res = await Request.getList()
      let {data} = res
      //@ts-ignore
      childRef?.current?.changeVal(data.background_image_url_gather)
    })
  }

  const editData = Utils.getMenuRole("config/editInviteFriends", props.allRole)

  return (
      <Card>
        <Skeleton loading={showSkeleton}>
          <Form {...formItemLayout} requiredMark={false}
                onFinish={formSubmit}
                initialValues={configData}
          >
            {editData &&
            <div style={{textAlign: "right", marginBottom: 16}}>
                <Button type="primary" htmlType="submit">保存</Button>
            </div>
            }
            <Form.Item
                label="注册地址"
                name="register_url"
                rules={[{required: true}]}
            >
              <Input disabled={!editData} style={{width: "450px"}} placeholder="请输入注册地址"/>
            </Form.Item>
            <Form.Item
                label="邀请好友图片"
                name="background_image_url_gather"
                valuePropName="filelist"
                style={{margin: 0}}
                rules={[{required: true, message: "请选择图片"}]}
                help={<span className="color-danger font5">图片大小480px * 800px</span>}
            >
              <UploadOSS cRef={childRef} disabled={!editData} filelength={!editData ? 0 : 9}/>
            </Form.Item>

          </Form>
        </Skeleton>
      </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(InviteFriend);