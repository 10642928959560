import React, {useState, useEffect} from 'react';
import {PageHeader, Card, Row, Col, Breadcrumb} from "antd";
import {RouteComponentProps} from "react-router-dom";
import {connect} from "react-redux";
import Request from "./Request"

import './index.less'

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any
}

const {Meta} = Card;
const AppList: React.FC<IProps> = (props: IProps) => {
  const [tempData, setTempData] = useState<any[]>([
    {
      title: "品牌店铺",
      key: "newPeople"
    }, {
      title: "爆款推荐",
      key: "hot"
    }, {
      title: "好评店铺",
      key: "haopin"
    }, {
      title: "以太热搜",
      key: "resou"
    }
  ])
  useEffect(() => {
    getList()
  }, [])
  const getList = () => {
    Request.getModule().then(res => {
      setTempData(res.data)
    })
  }
  const toDetails = (detailtype) => {
    const {history, dispatch} = props;
    let path
    if (detailtype == "pinpai") {
      path = `/operate/pinpai_detail`
    } else if (detailtype == "jingxuan") {
      path = `/operate/jingxuan_detail`
    } else if (detailtype == "haoping") {
      path = `/operate/haoping_detail`
    } else if (detailtype == "resou") {
      path = `/operate/resou_detail`
    }
    history.push({pathname: path});
  }
  return (
      <>
        <PageHeader
            title='APP板块'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>运营管理</Breadcrumb.Item>
              <Breadcrumb.Item>APP板块</Breadcrumb.Item></Breadcrumb>}></PageHeader>
        <div className="wrapContent app">
          <div className="appwrap">
            <div className="public-title mb10">首页</div>
            <div className="divider mb10"></div>
            <Row gutter={20}>
              {tempData.map(item => {
                return <Col span={6}>
                  <Card
                      onClick={() => {
                        toDetails(item.key)
                      }}
                      key={item.key}
                      style={{width: 300, marginBottom: "20px", cursor: "pointer"}}
                      cover={<img alt="app" src={require("../../../resources/assets/appbanner.png")}/>}
                      actions={[]}
                  >
                    <Meta
                        title={
                          <div>
                            {item.name}
                            {item.status == 0 ? <div className="show">显示</div>
                                : <div className="hiden">隐藏</div>}
                          </div>
                        }
                    />
                    <div className="flex justify-space mt15">
                      <div className="flex align-center">
                      </div>
                    </div>
                  </Card>
                </Col>
              })}

            </Row>
          </div>
        </div>
      </>
  );
};
const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(AppList)