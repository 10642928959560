import axios from "../../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/record/getRecordHappyIntegralList',
        method: "post",
        data: params,
        isShowLoading: false
    })
}

export default {
    getList
}