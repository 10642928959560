import axios from '../axios'

// 获取供销商
function getSupplierAllList() {
  return axios.ajax({
    url: '/config/getSupplierAllList',
    method: 'post',
  })
}

// 获取快递
function getExpressAll() {
  return axios.ajax({
    url: 'config/getExpressAll',
    method: 'post',
    data: {
      current: 1,
      pageSize: 9999,
    },
  })
}

// 获取供应链
function getSupplyChain() {
  return axios.ajax({
    url: '/good/get_supply_chain',
    method: 'post',
    data: {
      current: 1,
      pageSize: 99999,
      contract_type: 3,
      supply_type: 1,
    },
  })
}

// 获取分类
function getCategory() {
  return axios.ajax({
    url: '/config/getCategoryAll',
    method: 'post',
  })
}

//获取淘宝物料商品
function getMaterialGoodList(params: any) {
  return axios.ajax({
    url: '/activity/get_material_good_list',
    method: 'post',
    data: params,
  })
}

//获取拼多多物料商品
function getPDDMaterialGoodList(params: any) {
  return axios.ajax({
    url: '/activity/get_pdd_goods_search_list',
    method: 'post',
    data: params,
  })
}

//添加淘宝活动商品
function addTbkActivityGood(params: any) {
  return axios.ajax({
    url: '/activity/add_tbk_activity_good',
    method: 'post',
    data: params,
  })
}

//添加拼多多活动商品
function addPDDActivityGood(params: any) {
  return axios.ajax({
    url: '/activity/add_pdd_activity_good',
    method: 'post',
    data: params,
  })
}

//删除活动商品
function deleteTbkActivityGood(params: any) {
  return axios.ajax({
    url: '/activity/delete_tbk_activity_good',
    method: 'post',
    data: params,
  })
}

//hkey删除活动商品信息
function getDeleteTbkGoodHkey(params: any) {
  return axios.ajax({
    url: '/activity/delete_tbk_activity_good_hkey',
    method: 'post',
    data: params,
  })
}


// 获取分类
function getCategoryAll() {
  return axios.ajax({
    url: '/config/getCategoryAll',
    method: 'post',
  })
}

//获得所有店铺名称的商品id
function getStoreGoodAllList() {
  return axios.ajax({
    url: '/config/getStoreGoodAllList',
    method: 'post',
  })
}

//获得所有店铺名称
function getStoreAllList() {
  return axios.ajax({
    url: '/config/getStoreAllList',
    method: 'post',
  })
}



export {
  getSupplierAllList,
  getExpressAll,
  getSupplyChain,
  getCategory,
  addTbkActivityGood,
  addPDDActivityGood,
  deleteTbkActivityGood,
  getMaterialGoodList,
  getPDDMaterialGoodList,
  getDeleteTbkGoodHkey,
  getCategoryAll,
  getStoreAllList,
  getStoreGoodAllList,
}
