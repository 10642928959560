import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  PageHeader,
  Breadcrumb,
  Row,
  Select,
  message,
  Steps,
  Popover,
  Modal,
  Input
} from "antd";
import Request from "./Request";
import {RouteComponentProps} from "react-router-dom";
import {withAliveScope} from "react-activation";
import {getExpressAll} from "@src/config/getAsyncConfig"
import AreaPicker from '../../../components/AreaPicker';
import moment from "moment";
import validate from "../../../utils/validate"
import Skeleton from '../../../components/Skeleton';
import utils from "../../../utils/utils";
import {PAY_METHOD} from '@src/config/statusConfig'

import './detail.less'
import Utils from "@src/utils/utils";

const {Step} = Steps;

interface IProps extends RouteComponentProps {
  allRole: any[];
  dispatch: any;
  tabs: any;
  dropScope: any;
}


const ActivityDetails: React.FC<IProps> = (props: IProps) => {
  const [form2] = Form.useForm();
  const [areaform] = Form.useForm();
  //@ts-ignore
  let order_id = props?.match?.params?.id || "new";

  const [orderId] = useState<string | number>(order_id);
  const [orderData, setOrderData] = useState<any>(order_id);
  const [Hour, setHour] = useState<string | number>();
  const [expressList, setExpressList] = useState<any[]>([]);
  const [visiblefh, setVisiblefh] = useState<boolean>(false);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [areaName, setAreaName] = useState<any>([])
  const [areaVisiblefh, setAreaVisiblefh] = useState<boolean>(false);

  useEffect(() => {
    func()
    getExpressAll().then(res => {
      setExpressList(res.data.list)
    })
  }, []);
  const formSubmit = (formVal: any) => {
    formVal.order_id = orderData.order_id
    Request.editOrderExpress(formVal).then(() => {
      visibleModalfh();
      func()
      message.success("操作成功");
    });
  };
  const func = utils.useSyncCallback(() => {
    Request.getActDetails({order_id: orderId}).then((res) => {
      let data = res.data
      console.log(res)
      if (data.pay_unit_fee && data.pay_good_fee) {
        data.pay_yh_fee = data.pay_good_fee - data.pay_unit_fee
      }
      if (data.order_status == 9) {
        data.step = 5
      } else {
        data.step = data.order_status
      }
      console.log(data.step)
      setOrderData(data)
      if (data.order_status == 2) {
        let newtime = moment()
        let starttime = moment(data.created_at)
        let num = newtime.diff(starttime, 'hours')
        if (num > 0) {
          setHour(num)
        }
      }
      setShowSkeleton(false)
    });
  });
  const visibleModalfh = () => {
    setVisiblefh(!visiblefh);
  };
  //发货
  const deliver = async () => {
    form2.resetFields()
    visibleModalfh();
  };
  const onReset = () => {
    form2.resetFields();
  };
  //查看物流详情
  const todetail = () => {
    const {history, dispatch} = props;
    let path = `/order/customs_order_express/${orderId}`;
    history.push({
      pathname: path,
    });
  };
  const customDot = (dot, {status, index}) => (
      <Popover
          content={
            <span>
          step {index} status: {status}
        </span>
          }
      >
        {dot}
      </Popover>
  );
  const areaVisibleModalfh = () => {
    setAreaVisiblefh(!areaVisiblefh);
  };
  const editArea = () => {
    areaform.setFieldsValue({
      address_receiver: orderData.address_receiver,
      address_mobile: orderData.address_mobile,
      area: [orderData.province, orderData.city, orderData.district],
      address_detail: orderData.address_detail,
    })
    setAreaName([orderData.province_name, orderData.city_name, orderData.district_name])
    areaVisibleModalfh()
  }
  //收货地址修改
  const areaformSubmit = (formVal: any) => {
    formVal.order_id = orderData.order_id
    if (formVal.area) {
      formVal.province_code = formVal.area[0]
      formVal.city_code = formVal.area[1]
      formVal.district_code = formVal.area[2]
      formVal.province = areaName[0]
      formVal.city = areaName[1]
      formVal.district = areaName[2]
      delete formVal.area
    }
    Request.editaddress(formVal).then(() => {
      areaVisibleModalfh();
      func()
      message.success("操作成功");
    });
  };
  const formItemLayout = {
    labelCol: {span: 5, offset: 0},
    wrapperCol: {span: 9},
  };
  const viewLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
  };

  return (
      <div className="detailwrap">
        <Skeleton loading={showSkeleton}>
          <PageHeader
              breadcrumb={<Breadcrumb><Breadcrumb.Item>订单管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a>报单订单列表</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>报单订单详情</Breadcrumb.Item></Breadcrumb>}
              className="site-page-header-responsive mb15"
              title='报单订单详情'
          >
          </PageHeader>
          <div className="wrapContent">
            <Card className="mb15">
              <Row className="font16">
                <Col className="mr20">用户昵称:{orderData.purchase_name}</Col>
                <Col className="mr20">手机号:{orderData.purchase_mobile}</Col>
                <Col className="mr20">订单编号:{orderData.order_no}</Col>
              </Row>
            </Card>
            <Card className="mb15">
              <Row>
                <Col span={8} className="border">
                  {(orderData?.order_status == 1 || orderData?.order_status == 2) && <div>
                      <div className="font26 font-weight text-center mb20">等待商户发货</div>
                      <div className="font20 text-center mb20"><span className="colorH">请尽快处理发货,</span><span
                          className="red">{Hour ? ('已过' + Hour + '小时未发货') : ''}</span></div>
                      <div className="text-center">
                        {((Utils.getMenuRole("order/orderDelivery", props.allRole) ? (
                            <Button type="primary" onClick={() => {
                              deliver()
                            }}>发货</Button>) : ''))}
                      </div>
                  </div>}
                  {orderData?.order_status == 3 && <div>
                      <div className="font26 font-weight text-center mb20">商户已发货，等待收货</div>
                      <div className="font20 text-center mb20"><span className="colorH">若发现物流问题请及时修改物流信息保障用户权益,</span>
                      </div>
                      <div className="text-center">
                        {((Utils.getMenuRole("order/getExpress", props.allRole) ? (
                            <Button type="primary" onClick={() => {
                              todetail()
                            }}>查看物流</Button>) : ''))}
                      </div>
                  </div>
                  }
                  {orderData?.order_status == 4 && <div>
                      <div className="font26 font-weight text-center mb20">买家已确认到货</div>
                      <div className="font20 text-center mb20"><span className="colorH">该笔订单已完成交易,</span></div>

                      <div className="text-center">
                        {((Utils.getMenuRole("order/getExpress", props.allRole) ? (
                            <Button type="primary" onClick={() => {
                              todetail()
                            }}>查看物流</Button>) : ''))}
                      </div>
                  </div>}
                </Col>
                <Col span={16}>
                  <div className="pd30">
                    <Steps current={orderData?.step - 1} progressDot={customDot}>
                      <Step title="买家下单" description={orderData?.order_status >= 1 && orderData?.created_at}/>
                      <Step title="买家付款" description={orderData?.order_status >= 2 && orderData?.created_at}/>
                      <Step title="商户发货" description={orderData?.order_status >= 3 && orderData?.send_express_at}/>
                      <Step title="交易完成"
                            description={(orderData?.order_status == 4 || orderData?.order_status == 5) && orderData?.receive_at}/>
                    </Steps>
                  </div>
                </Col>
              </Row>
            </Card>
            {orderData?.order_status >= 3 && <Card className="mb15">
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={12}>
                            <div className="item">
                                <div className="font16 mb15">物流信息</div>
                                <Form.Item label="物流公司">{orderData?.express_name}</Form.Item>
                                <Form.Item label="快递单号">{orderData?.express_no}
                                  {((Utils.getMenuRole("order/getCustomsExpress", props.allRole) ? (
                                      <a onClick={todetail}>&nbsp;&nbsp;物流追踪</a>) : ''))}
                                </Form.Item>
                                <Form.Item
                                    label="发货时间">{orderData?.order_status >= 3 && orderData?.send_express_at}</Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>}
            <Card className="mb15">
              <Form {...formItemLayout}>
                <Row>
                  <Col span={12} className="border">
                    <div className="font16 mb15">收货信息 {orderData?.order_status <= 2 &&
                    ((Utils.getMenuRole("order/editOrderAddress", props.allRole) ? (
                        <a className="font12 ml5" onClick={editArea}>修改</a>) : ''))
                    }</div>
                    <Form.Item label="收货人">{orderData?.address_receiver}</Form.Item>
                    <Form.Item label="联系电话">{orderData?.address_mobile}</Form.Item>
                    <Form.Item
                        label="收货地址">{orderData?.province_name} {orderData?.city_name} {orderData?.district_name} {orderData?.address_detail}</Form.Item>
                    <Form.Item label="用户留言">{orderData.order_remark}</Form.Item>
                  </Col>
                  <Col span={12}>
                    <div className="item2">
                      <div className="font16 mb15">付款信息</div>
                      <Form.Item label="实付款">
                        {orderData?.pay_fee}
                        &nbsp;
                        <span style={{color: 'green'}} className="font5">绿色积分:{orderData.green_integral}</span>
                        &nbsp;
                        <span style={{color: '#fe6673'}} className="font5">幸福值:{orderData.happy_integral}</span>
                      </Form.Item>
                      <Form.Item label="付款方式">{}{PAY_METHOD[orderData?.pay_method]}</Form.Item>
                      <Form.Item label="下单时间">{orderData?.order_status >= 1 && orderData?.created_at}</Form.Item>
                      <Form.Item label="付款时间">{orderData?.order_status >= 2 && orderData?.created_at} </Form.Item>
                      <Form.Item label="商家冻积分">{orderData?.lock_customer_integral} </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Skeleton>
        <Modal
            destroyOnClose
            title="物流修改"
            visible={visiblefh}
            onCancel={visibleModalfh}
            footer={false}
            width={500}
        >
          <Form {...viewLayout} requiredMark={false} onFinish={formSubmit} form={form2}>
            <Row>
              <Col span={24}>
                <Form.Item label="订单号">{orderData?.order_no}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="付款时间">{orderData?.created_at}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="收货人姓名">{orderData?.address_receiver}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="收货人电话">{orderData?.address_mobile}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    label="收货地址">{orderData?.province_name} {orderData?.city_name} {orderData?.district_name} {orderData?.address_detail}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    label="物流公司"
                    name="express_company_id"
                    rules={[{required: true}]}
                >
                  <Select
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      placeholder="请选择"
                  >
                    {
                      (() => {
                        return expressList.map(item => {
                          return <Select.Option key={item.express_id} label={item.express_name}
                                                value={item.express_id}>{item.express_name}</Select.Option>
                        })
                      })()
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                    label="物流单号"
                    name="express_no"
                    rules={[{required: true}]}
                >
                  <Input placeholder="请输入物流单号"/>
                </Form.Item>
                <Form.Item {...{wrapperCol: {offset: 3}}}>
                  <Button type="primary" htmlType="submit">
                    立即提交
                  </Button>
                  <Button className="resetbtn" onClick={onReset}>
                    重置
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
            destroyOnClose
            title="收货地址编辑"
            visible={areaVisiblefh}
            onCancel={areaVisibleModalfh}
            footer={false}
            width={500}
        >
          <Form {...viewLayout} requiredMark={false} onFinish={areaformSubmit} form={areaform}>
            <Row>
              <Col span={24}>
                <Form.Item
                    label="收货人"
                    name="address_receiver"
                    rules={[{required: true}]}
                >
                  <Input placeholder="请输入收货人"/>
                </Form.Item>
                <Form.Item
                    label="联系电话"
                    name="address_mobile"
                    rules={[{required: true}]}
                >
                  <Input placeholder="请输入联系电话"/>
                </Form.Item>
                <Form.Item
                    label="所在区域"
                    name="area"
                    rules={[{validator: validate.checkArea}]}
                >
                  <AreaPicker valueName={areaName} Township={false} onChange={(_, name) => {
                    setAreaName(name)
                  }}/>
                </Form.Item>
                <Form.Item
                    label="详细地址"
                    name="address_detail"
                    rules={[{required: true}]}
                >
                  <Input placeholder="请输入详细地址"/>
                </Form.Item>
                <Form.Item {...{wrapperCol: {offset: 3}}}>
                  <Button className="mr20" onClick={areaVisibleModalfh}>
                    取消
                  </Button>
                  <Button type="primary" htmlType="submit">
                    确认
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole,
    tabs: state.tabs,
  };
};

export default connect(mapStateToProps)(withAliveScope(ActivityDetails));
