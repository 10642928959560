import { Row, Col } from "antd";
import React from "react";
import "./index.less"

interface IsProps {
  loading: boolean
  children?: any
}

const SkeletonDetail: React.FC<IsProps> = (props: IsProps) => {
  const detail = (
    <>
      <div className="detail-skeleton-pagehead skeleton-animation"></div>
      <Row>
        {(() => {
          let arr = []
          for (let index = 0; index < 12; index++) {
            arr.push(
              <Col className="skeleton-detail-item" span={12} key={index}>
                <Row>
                  <Col span={5} offset={1} className="detail-item-label skeleton-animation"></Col>
                  <Col span={12} offset={1} className="detail-item-label skeleton-animation"></Col>
                </Row>
              </Col>
            )
          }
          return arr
        })()}
      </Row>
    </>
  )
  return (
    <>
      {props.loading ? (
        <div className="my-skeleton-container">
          {detail}
          {detail}
          {detail}
          {detail}
        </div>) : (props.children)}
    </>
  )
}

export default SkeletonDetail