import React, {useState, useEffect} from "react";
import Request from "./Request";
import {connect} from "react-redux";
import {
  PageHeader, Breadcrumb, Row, Col, Statistic, Divider
} from "antd";
import {RouteComponentProps} from "react-router-dom";
import moment from 'moment';
import './index.less'

interface IProps extends RouteComponentProps {
  allRole: any[];
  dispatch: any
}

const NoticeList: React.FC<IProps> = (props: IProps) => {
  const [workData, setWorkData] = useState<any>("");
  const [ResData, setResData] = useState<any>("");

  useEffect(() => {
    getDetail()
  }, []);
  const getDetail = async () => {
    const res = await Request.getDetails();
    setResData(res.data);
  }


  return (
      <>
        <div style={{background: "white", minHeight: "700px", paddingBottom: "50px"}}>
          <PageHeader
              title={<div>
                <div>早安，祝你开心每一天！</div>
              </div>}
              className="site-page-header"
              extra=''
              breadcrumb={<Breadcrumb><Breadcrumb.Item>首页</Breadcrumb.Item>
              </Breadcrumb>}
          >
          </PageHeader>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">会员数据</span></Col>
            <Divider className="divider"/>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench1.png")}/>
                <Statistic title="总用户" value={ResData.member_all_count}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench1.png")}/>
                <Statistic title="今日新增用户" value={ResData.member_today_register_count}/>
              </div>
            </Col>
          </Row>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">&nbsp;</span></Col>
            <Col span={4}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench1.png")}/>
                <Statistic title="今日活跃用户" value={ResData.member_today_active_count}/>
              </div>
            </Col>
            <Col span={4}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench1.png")}/>
                <Statistic title="昨日活跃用户" value={ResData.member_yestoday_active_count}/>
              </div>
            </Col>
            <Col span={4}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench1.png")}/>
                <Statistic title="本周活跃用户" value={ResData.member_weeks_active_count}/>
              </div>
            </Col>
            <Col span={4}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench1.png")}/>
                <Statistic title="本月活跃用户" value={ResData.member_month_active_count}/>
              </div>
            </Col>
            <Col span={4}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench1.png")}/>
                <Statistic title="上月活跃用户" value={ResData.member_lst_month_active_count}/>
              </div>
            </Col>
          </Row>
          <Divider className="divider"/>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">用户资金数据</span></Col>
            <Divider className="divider"/>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="用户现有总余额" value={ResData.member_all_balance}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日余额增加" value={ResData.today_add_balance}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日余额减少" value={ResData.today_sub_balance}/>
              </div>
            </Col>
          </Row>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">&nbsp;</span></Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="用户现有锁定积分" value={ResData.member_all_lock_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日用户冻积分增加" value={ResData.today_add_lock_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日用户冻积分减少" value={ResData.today_sub_lock_integral}/>
              </div>
            </Col>
          </Row>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">&nbsp;</span></Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="现有商家冻积分" value={ResData.member_all_lock_store_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日商家冻积分增加" value={ResData.today_add_lock_store_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日商家冻积分减少" value={ResData.today_sub_lock_store_integral}/>
              </div>
            </Col>
          </Row>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">&nbsp;</span></Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="用户现有开通店主积分" value={ResData.member_all_lock_customer_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日开通店主积分增加" value={ResData.today_add_lock_customer_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日开通店主积分券减少" value={ResData.today_sub_lock_customer_integral}/>
              </div>
            </Col>
          </Row>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">&nbsp;</span></Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="用户绿色积分" value={ResData.member_all_green_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日绿色积分增加" value={ResData.today_add_green_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日绿色积分减少" value={ResData.today_sub_green_integral}/>
              </div>
            </Col>
          </Row>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">&nbsp;</span></Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="用户现有幸福值" value={ResData.member_all_happy_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日幸福值增加" value={ResData.today_add_happy_integral}/>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench5.png")}/>
                <Statistic title="今日幸福值减少" value={ResData.today_sub_happy_integral}/>
              </div>
            </Col>
          </Row>
          <Divider className="divider"/>
          <Row className="workbench">
            <Col className="title" span={24}><span className="mr20">订单数据</span></Col>
            <Divider className="divider"/>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="总平台订单数" value={ResData.order_pingtai_all_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="今日平台订单数" value={ResData.order_pingtai_today_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="本月平台订单数" value={ResData.order_pingtai_month_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="上月平台订单数" value={ResData.order_pingtai_last_month_count}/>
              </div>
            </Col>
          </Row>
          <br/>
          <Row className="workbench">
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="总店铺订单数" value={ResData.order_store_all_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="今日店铺订单数" value={ResData.order_store_today_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="本月店铺订单数" value={ResData.order_store_month_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="上月店铺订单数" value={ResData.order_last_store_month_count}/>
              </div>
            </Col>
          </Row>
          <br/>
          <Row className="workbench">
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="总报单订单数" value={ResData.order_customs_all_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="今日报单订单数" value={ResData.order_customs_today_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="本月报单订单数" value={ResData.order_customs_month_count}/>
              </div>
            </Col>
            <Col span={6}>
              <div className="flex align-center">
                <img className="workbench-icon" alt="" src={require("../../../resources/assets/workbench6.png")}/>
                <Statistic title="上月报单订单数" value={ResData.order_customs_last_month_count}/>
              </div>
            </Col>
          </Row>
        </div>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole,
  };
};

export default connect(mapStateToProps)(NoticeList);
