import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select, TreeSelect } from 'antd';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import { ListItem } from "./data"
import validate from "../../../utils/validate"
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const FunManage: React.FC<IProps> = (props: IProps) => {

  const ref = useRef<ActionType>();

  const [title, setTitle] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [IsItem, setIsItem] = useState<ListItem>()
  const [IsList, setIsList] = useState<ListItem[]>()

  const getMenu = (_: any, item: ListItem) => {
    const { allRole } = props
    const { getMenuRole, menuRender } = Utils
    let menu = []
    if (getMenuRole("sys/edit_func", allRole)) {
      menu.push(<a onClick={() => {
        edit(item)
      }}>编辑</a>)
    }
    if (getMenuRole("sys/add_func", allRole) && item.level !== 3) {
      menu.push(<a className="color-success" onClick={() => {
        addChildren(item)
      }}>添加</a>)
    }
    if (getMenuRole("sys/delete_func", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    return menuRender(menu)
  }

  const add = () => {
    setTitle("新建")
    setVisible(true)
    setIsItem({
      id: "",
      pid: 0,
      name: "",
      ename: "",
      icon: "",
      level: 4,
      order: 0,
      status: 0,
      title:'',
    })
  }

  const addChildren = (item: ListItem) => {
    setTitle("添加")
    setVisible(true)
    setIsItem({
      id: "",
      pid: item.id,
      name: "",
      ename: "",
      icon: "",
      level: item.level + 1,
      order: 0,
      status: 0,
      title:'',
    })
  }

  const edit = (item: ListItem) => {
    setTitle("编辑")
    setVisible(true)
    setIsItem(item)
  }

  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: 'danger',
      onOk() {
        Request.deleteData({ id: item.id }, item.level).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }

  const visibleModal = () => {
    setVisible(!visible)
  }

  const formSubmit = (formVal: any) => {
    const callback = () => {
      visibleModal()
      ref.current!.reload()
      message.success("操作成功")
    }
    if (IsItem?.id) {
      formVal.id = IsItem.id
      if(IsItem.level==1){
        formVal.pid = 0
      }
      Request.edit(formVal, IsItem.level).then(callback)
    } else {
      if(IsItem.level==4){
        formVal.pid = 0
      }
      Request.add(formVal, IsItem!.level).then(callback)
    }
  }

  const columns: ProColumns<ListItem>[] = [
    { title: '名称', width: "30%", dataIndex: 'title' },
    { title: '路由', width: "20%", dataIndex: 'url' },
    { title: '功能代码', width: "10%", dataIndex: 'ename' },
    { title: '排序', width: 100, dataIndex: 'order' },
    { title: '状态', width: 100, dataIndex: 'status', valueEnum: { "0": { status: "Success", text: "可用" }, "1": { status: "Error", text: "不可用" } } },
    { title: '操作', align: 'center', width: 160, dataIndex: 'id', render: getMenu }
  ];

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 }
  }

  return (
    <>
      <ProTable<ListItem>
        rowKey="id"
        tableStyle={{ padding: '0 24px 0 24px' }}
        columns={columns}
        actionRef={ref}
        search={false}
        headerTitle="功能列表"
        request={async () => {
          const res = await Request.getList();
          setIsList(res.data)
          return {
            data: res.data,
            success: true
          }
        }}
        pagination={false}
        toolBarRender={() => [
          (Utils.getMenuRole("sys/add_app", props.allRole) && <Button key="button" icon={<PlusOutlined />} type="primary" onClick={add}> 新建 </Button>)
        ]}
        options={{ density: false, fullScreen: false, reload: true, setting: true }}
      />
      <Modal
        destroyOnClose
        title={title}
        visible={visible}
        onCancel={visibleModal}
        footer={false}
        width={800}>
        <Form
          {...formItemLayout}
          requiredMark={false}
          onFinish={formSubmit}
          initialValues={{
            ...IsItem
          }}
        >
          {
            IsItem?.pid !== 0 && (
              <Form.Item
                label="所属应用"
                name="pid"
                rules={[{ required: true }]}
              >
                <TreeSelect treeData={IsList} placeholder="请选择" />
              </Form.Item>)
          }
          <Form.Item
            label="应用名称"
            name="title"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入应用名称" />
          </Form.Item>
          {
            IsItem?.level === 1 && (
              <Form.Item
                label="图标"
                name="icon"
                rules={[{ required: true }]}
              >
                <Input placeholder="请输入图标" />
              </Form.Item>)
          }
          {
            IsItem?.level !== 1 && (
              <Form.Item
                label="路由"
                name="url"
                rules={[{ required: true }]}
              >
                <Input placeholder="请输入路由" />
              </Form.Item>)
          }
          <Form.Item
            label="功能代码"
            name="ename"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入功能代码" />
          </Form.Item>
          <Form.Item
            label="排序"
            name="order"
            rules={[{ validator: validate.checLessThan0 }]}
          >
            <Input type="number" placeholder="请输入排序" />
          </Form.Item>
          <Form.Item
            label="状态"
            name="status"
            rules={[{ required: true }]}
          >
            <Select placeholder="请选择">
              <Select.Option value={0}>可用</Select.Option>
              <Select.Option value={1}>不可用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...{ wrapperCol: { offset: 3 } }}>
            <Button type="primary" htmlType="submit">立即提交</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(FunManage);