import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {
  Tabs,
  PageHeader,
  Breadcrumb, Button,
} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import {ListItem} from "./data"
import Skeleton from '../../../components/Skeleton';
import {ONLINE_STATUS, PAY_METHOD} from "@src/config/statusConfig";
import InputGroup from '../../../components/InputGroup';
import Utils from "@src/utils/utils";

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any
}

let TEMP_CHECK_ROW: any[] = [];
let exportsFilter: any = {};
const GoodStatus = [
  {title: "今日", param: {data_type: 1}},
  {title: "昨日", param: {data_type: 2}},
  {title: "本月", param: {data_type: 3}},
  {title: "上月", param: {data_type: 4}},
  {title: "历史", param: {data_type: 0}}
];
const StoreGood: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const [tabKey, setTabKey] = useState<any>("0");
  const [orderData, setOrderData] = useState<any>({});

  const {allRole} = props

  useEffect(() => {
    //获取标签
  }, [])

  const tabChange = (key: any) => {
    setTabKey(key)
    TEMP_CHECK_ROW = [];
    ref.current.reset();
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', width: 60, align: 'center', dataIndex: 'order_id', search: false, fixed: 'left'},
    {title: '店铺名称', width: 120, dataIndex: 'store_name', search: true},
    {title: '店铺手机号', width: 100, dataIndex: 'phone', search: true},
    {title: '会员昵称', width: 100, dataIndex: 'nickname', search: false},
    {title: '会员手机号', width: 100, dataIndex: 'mobile', search: true},
    {title: '付款金额', width: 80, dataIndex: 'pay_fee', search: false},
    {
      title: '付款金额', width: 100, dataIndex: 'pay_fee', sorter: true, hideInTable: true, renderFormItem: () => {
        return (
            <InputGroup type="number"/>
        )
      }

    },
    {title: '店铺结算金额', width: 80, dataIndex: 'store_balance', search: false},
    {title: '用户冻积分', width: 80, dataIndex: 'lock_integral', search: false},
    {title: '商家冻积分', width: 80, dataIndex: 'lock_store_integral', search: false},
    {title: '支付方式', align: "center", width: 60, dataIndex: 'pay_method', valueEnum: PAY_METHOD, search: true,},
    {title: '状态', align: "center", width: 60, dataIndex: 'order_status', valueEnum: ONLINE_STATUS, search: true,},
    {
      title: '创建时间', width: 0, dataIndex: 'created_at', valueType: "dateTimeRange", hideInTable: true,
      render: (_, record) => record.created_at
    },
    {title: '创建时间', width: 110, dataIndex: 'created_at', search: false, align: "center"},
  ];

  const exportsDown = () => {
    let url = process.env.REACT_APP_API_URL + '/order/exportOnlineOrder?token=' + Utils.getToken()
    delete exportsFilter.current
    delete exportsFilter.pageSize
    for (const key in exportsFilter) {
      if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
        const element = exportsFilter[key];
        url += `&${key}=${element}`
      }
    }
    window.open(url, '_blank');
  }
  return (
      <>
        <Skeleton loading={false}>
          <PageHeader
              title='扫码订单列表'
              className="site-page-header"
              breadcrumb={<Breadcrumb><Breadcrumb.Item>订单</Breadcrumb.Item>
                <Breadcrumb.Item>扫码订单</Breadcrumb.Item></Breadcrumb>}
              footer={
                <Tabs defaultActiveKey="0" onChange={tabChange}>
                  {GoodStatus.map((statusItem: any, index: number) => {
                    return (<TabPane closable={false} tab={statusItem.title} key={index}></TabPane>);
                  })}
                </Tabs>
              }
          >
          </PageHeader>
          <div className="wrapContent">
            <ProTable<ListItem>
                rowKey="order_id"
                tableStyle={{padding: '0 24px 0 24px'}}
                columns={columns}
                actionRef={ref}
                scroll={{x: 1800}}
                request={async (params: any) => {
                  params = Object.assign(
                      JSON.parse(JSON.stringify(GoodStatus[+tabKey].param)),
                      params
                  );
                  exportsFilter = JSON.parse(JSON.stringify(params));
                  if (params.pay_fee) {
                    params.pay_fee_start = params.pay_fee[0] || ""
                    params.pay_fee_end = params.pay_fee[1] || ""
                    delete params.pay_fee
                  }
                  if (params.paid_at) {
                    params.paid_at_start = params.paid_at[0] || ""
                    params.paid_at_end = params.paid_at[1] || ""
                    delete params.pay_at
                  }
                  const res = await Request.getList(params);
                  setOrderData(res.data.order_data)
                  return {
                    data: res.data.list,
                    total: res.data.count,
                    success: true,
                    pageSize: params.pageSize,
                    current: params.pageSize
                  }
                }
                }
                pagination={{
                  defaultPageSize: 20,
                }}
                toolbar={{
                  title: [(Utils.getMenuRole("member/exportMember", allRole) && (
                      <Button type="primary" key="member/exportMember" onClick={exportsDown}>导出</Button>)),

                    <div style={{float: "right", fontSize: "10px", marginLeft: "20px"}}>
                      &nbsp;&nbsp;
                      <span
                          className="ant-badge-status-dot ant-badge-status-success"></span>&nbsp;付款金额：{orderData.all_pay_fee}
                      &nbsp;&nbsp;
                      <span
                          className="ant-badge-status-dot ant-badge-status-success"></span>&nbsp;店铺结算金额：{orderData.all_store_balance}
                      &nbsp;&nbsp;
                      <span
                          className="ant-badge-status-dot ant-badge-status-success"></span> &nbsp;总订单数：{orderData.count}
                    </div>,
                  ],
                  filter: ''
                }}
            />
          </div>
        </Skeleton>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(StoreGood)