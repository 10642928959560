import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';
import Request from "./Request"
import RoleRequest from "../role/Request"
import Utils from "../../../utils/utils"
import { ListItem } from "./data"
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

let roleList: any[] = []

const AdminManage: React.FC<IProps> = (props: IProps) => {

  const ref = useRef<ActionType>();

  const [title, setTitle] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [IsItem, setIsItem] = useState<ListItem>()

  useEffect(() => {
    RoleRequest.getList().then(res => {
      roleList = res.data
    })
  }, [])

  const getMenu = (_: any, item: ListItem) => {
    const { allRole } = props
    const { getMenuRole, menuRender } = Utils
    let menu = []
    if (getMenuRole("sys/edit_func", allRole)) {
      menu.push(<a onClick={() => {
        edit(item)
      }}>编辑</a>)
    }
    if (getMenuRole("sys/delete_func", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    return menuRender(menu)
  }

  const add = () => {
    setTitle("新建")
    setVisible(true)
    setIsItem({
      role_id: undefined,
      admin_id: "",
      username: "",
      password: "",
      mobile: "",
      nicename: "",
      status: 1
    })
  }

  const edit = (item: ListItem) => {
    setTitle("编辑")
    setVisible(true)
    setIsItem(item)
  }

  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: 'danger',
      onOk() {
        Request.deleteData({ admin_id: item!.admin_id }).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }

  const visibleModal = () => {
    setVisible(!visible)
  }

  const formSubmit = (formVal: any) => {
    const callback = () => {
      visibleModal()
      ref.current!.reload()
      message.success("操作成功")
    }
    if (IsItem?.admin_id) {
      formVal.admin_id = IsItem.admin_id
      Request.edit(formVal).then(callback)
    } else {
      Request.add(formVal).then(callback)
    }
  }

  const columns: ProColumns<ListItem>[] = [
    { title: '用户名', dataIndex: 'username', search: false },
    { title: '昵称', dataIndex: 'nicename' },
    { title: '状态', dataIndex: 'status', search: false, valueEnum: { "0": { status: "Success", text: "可用" }, "1": { status: "Error", text: "不可用" } } },
    {
      title: '角色', dataIndex: 'role_name', renderFormItem: () => {
        return (
          <Select allowClear placeholder="请选择">
            {Utils.getOptionList(roleList, "role_id", "role_name")}
          </Select>
        )
      }
    },
    { title: '角色状态', dataIndex: 'sys_role_status', search: false, valueEnum: { "0": { status: "Success", text: "可用" }, "1": { status: "Error", text: "不可用" } } },
    { title: '操作', align: 'center', width: 160, dataIndex: 'id', search: false, render: getMenu }
  ];

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 }
  }

  return (
    <>
      <ProTable<ListItem>
        rowKey="admin_id"
        tableStyle={{ padding: '0 24px 0 24px' }}
        columns={columns}
        actionRef={ref}
        headerTitle="管理员列表"
        request={async (params: any) => {
          params.page = params.current
          params.limit = params.pageSize
          if (params.role_name !== undefined) {
            params.role_id = params.role_name
            delete params.role_name
          }
          const res = await Request.getList(params);
          return {
            total: res.count,
            data: res.list,
            success: true,
            pageSize: params.pageSize,
            current: params.current
          }
        }}
        toolBarRender={() => [
          (Utils.getMenuRole("sys/add_app", props.allRole) && <Button key="button" icon={<PlusOutlined />} type="primary" onClick={add}> 新建 </Button>)
        ]}
        options={{ density: false, fullScreen: false, reload: true, setting: true }}
      />
      <Modal
        destroyOnClose
        title={title}
        visible={visible}
        onCancel={visibleModal}
        footer={false}
        width={800}>
        <Form
          {...formItemLayout}
          requiredMark={false}
          onFinish={formSubmit}
          initialValues={{
            ...IsItem
          }}
        >
          <Form.Item
            label="角色"
            name="role_id"
            rules={[{ required: true }]}
          >
            <Select placeholder="请选择">
              {Utils.getOptionList(roleList, "role_id", "role_name")}
            </Select>
          </Form.Item>

          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: IsItem?.admin_id ? false : true }]}
          >
            <Input type="password" placeholder="不输入密码默认为保持原密码" />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="mobile"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入手机号" />
          </Form.Item>
          <Form.Item
            label="昵称"
            name="nicename"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入昵称" />
          </Form.Item>
          <Form.Item
            label="状态"
            name="status"
            rules={[{ required: true }]}
          >
            <Select placeholder="请选择">
              <Select.Option value={0}>可用</Select.Option>
              <Select.Option value={1}>不可用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...{ wrapperCol: { offset: 3 } }}>
            <Button type="primary" htmlType="submit">立即提交</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(AdminManage);