import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import "./index.less"
interface IsProps {
  value?: string
  disabled?: boolean
  onChange?: (color?: string) => void
}

const PickerColor: React.FC<IsProps> = (props: IsProps) => {

  const { value = "", disabled = false, onChange = () => { } } = props
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)
  const [pickerPosition, setPickerPosition] = useState<any>("bottom")
  const [color, setColor] = useState<string>(value)

  const handleClick = (e: any) => {
    if (disabled) {
      return
    }
    let docheight = document.documentElement.clientHeight || document.body.clientHeight
    let canvasPosition = e.clientY || e.pageY
    setPickerPosition((docheight - canvasPosition) < 300 ? "top" : "bottom")
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color: any) => {
    setColor(color.hex)
    onChange(color.hex)
  }

  const emptyColor = () => {
    setColor("")
    onChange("")
    handleClose()
  }

  return (
    <div style={{ position: "relative" }}>
      <div className="pickercolor-swatch" onClick={handleClick}>
        <div className="pickercolor-color" style={{ backgroundColor: color }}></div>
      </div>
      { displayColorPicker && (
        <div className={pickerPosition === "bottom" ? "pickercolor-popover-bottom" : "pickercolor-popover-top"}>
          <div className="pickercolor-cover" onClick={handleClose}></div>
          <div style={{ position: "relative" }}>
            <SketchPicker disableAlpha color={color} onChange={handleChange} />
            <span className="pickercolor-empty" onClick={emptyColor}>清空</span>
          </div>
        </div>)}
    </div>
  )
}

export default PickerColor