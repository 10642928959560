import React, {useEffect} from 'react';
import {Row, Col, Modal, Menu, Dropdown} from "antd";
import {LogoutOutlined} from "@ant-design/icons"
import "./index.less";
import {connect} from "react-redux";
import utils from "../../utils/utils";

interface IsProps {
  userName?: string
  toggle?: React.ReactNode
  dispatch: any;
}

const Header: React.FC<IsProps> = (props: IsProps) => {
  useEffect(() => {
    // getList()
  })

  const showExitConfirm = () => {
    Modal.confirm({
      title: "是否确定退出系统?",
      okType: 'danger',
      onOk() {
        utils.setToken()
        //@ts-ignore
        window.reactHistory.push("/login")
      }
    });
  };

  let menu = (
      <Menu>
        <Menu.Item>
          <a onClick={showExitConfirm}><LogoutOutlined style={{marginRight: 0}}/>&nbsp;&nbsp;&nbsp;退出登录</a>
        </Menu.Item>
      </Menu>
  )

  return (
      <div className="header">
        <Row className="header-top">
          <Col span={12} className="logo">
            <img src='https://ywshapp.oss-cn-hangzhou.aliyuncs.com/admin/WechatIMG103.png' alt=""/>
            <span>后台管理系统</span>
          </Col>
          <Col span={12} className="header-right">
            <Dropdown overlay={menu}>
            <span className="user-info">
              <img className="user-icon" alt="" src={require("../../resources/assets/adminweb.png")}/>
              <span className="user-name">{props.userName || localStorage.getItem("nickname")}</span>
            </span>
            </Dropdown>
          </Col>
        </Row>
      </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userName: state.userName
  };
};
export default connect(mapStateToProps)(Header);
