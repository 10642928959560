import React, {useRef} from "react";
import ProTable, {ActionType, ProColumns} from "@ant-design/pro-table";
import Request from "./Request";
import {ListItem} from "./data";
import {PageHeader, Breadcrumb} from "antd";
import {RouteComponentProps} from "react-router-dom";
import {connect} from "react-redux";

interface IProps extends RouteComponentProps {
  allRole: any[];
}

const RecordStar: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const columns: ProColumns<ListItem>[] = [
    {title: "手机号", width: 100, dataIndex: "mobile"},
    {title: "验证码", width: 100, dataIndex: "send_code", search: false,},
    {title: "操作类型", width: 100, dataIndex: "record_type", search: false,},
    {title: "发送时间", width: 100, dataIndex: "created_at", valueType: "dateTimeRange", hideInTable: true},
    {title: "发送时间", width: 100, dataIndex: "created_at", search: false},
  ];
  return (
      <div>
        <PageHeader
            title='短信记录'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>系统配置</Breadcrumb.Item>
              <Breadcrumb.Item>短信记录</Breadcrumb.Item></Breadcrumb>}
        >

        </PageHeader>
        <div className="wrapContent">
          <ProTable<ListItem>
              rowKey="send_code"
              tableStyle={{padding: "15px 24px 0 24px"}}
              columns={columns}
              actionRef={ref}
              request={async (params: any) => {
                let res
                if (params.created_at) {
                  params.start_time = params.created_at[0]
                  params.end_time = params.created_at[1]
                  delete params.created_at
                }
                res = await Request.getList(params);
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.current,
                };
              }}
              toolBarRender={false}
              options={{density: false, fullScreen: false, reload: false, setting: false}}
          />
        </div>

      </div>
  );
};
const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole,
  };
};

export default connect(mapStateToProps)(RecordStar);
