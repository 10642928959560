import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  PageHeader,
  Tabs,
  Card,
  Statistic,
  Breadcrumb, Select
} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import {ListItem} from "./data"
import {RouteComponentProps} from 'react-router-dom';
import './index.less'
import {ZLB_PAY_STATUS} from "@src/config/statusConfig";

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const GoodStatus = [
  {
    title: "待审核",
    param: {
      status: 0,
    },
  },
  {
    title: "全部",
    param: {
      status: -1,
    },
  },
  {
    title: "待程序打款中",
    param: {
      status: 1,
    },
  }, {
    title: "已驳回",
    param: {
      status: 2,
    },
  }, {
    title: "打款成功",
    param: {
      status: 3,
    },
  }, {
    title: "打款失败",
    param: {
      status: 4,
    },
  }
];
let exportsFilter: any = {};
const RecordWithdraw: React.FC<IProps> = (props: IProps) => {
      const ref = useRef<ActionType>();
      const [form] = Form.useForm()
      const [title, setTitle] = useState<string>("");
      const [visible, setVisible] = useState<boolean>(false);
      const [modelType, setModelType] = useState<"EDIT" | "PREVIEW">("PREVIEW");
      const [IsItem, setIsItem] = useState<ListItem>()
      const [listData, setListData] = useState<ListItem>()
      const [required, setRequired] = useState<boolean>(false);
      const [tabKey, setTabKey] = useState<any>(0);
      const [dType, setDType] = useState<string>("offline");

      //导出
      const exportsDown = () => {
        let url = process.env.REACT_APP_API_URL + "/record/exportBalanceWithdraw?token=" + Utils.getToken();
        delete exportsFilter.current;
        delete exportsFilter.pageSize;
        for (const key in exportsFilter) {
          if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
            const element = exportsFilter[key];
            url += `&${key}=${element}`;
          }
        }
        window.location.href = url;
      };

      const getMenu = (_: any, item: ListItem) => {
        const {allRole} = props
        const {getMenuRole, menuRender} = Utils
        let menu = []
        menu.push(<a onClick={() => {
          perView(item)
        }}>查看</a>)
        if (getMenuRole("record/doBalanceWithdraw", allRole) && (item.withdraw_status == 0 && (item.zlb_status == 0 || item.zlb_status == 3))) {
          menu.push(<a className="color-danger" onClick={() => {
            edit(item)
          }}>审核</a>)
        }

        return menuRender(menu)
      }

      const edit = async (item: ListItem) => {
        setTitle("提现审核")
        setVisible(true)
        setModelType("EDIT")
        setIsItem(item)
        form.setFieldsValue({remark: ""})
      }

      const perView = (item: ListItem) => {
        setTitle("查看")
        setIsItem(item)
        setModelType("PREVIEW")
        setVisible(true)
      }

      const visibleModal = () => {
        setVisible(!visible)
      }

      const action = (params: { withdraw_id: string | number, status: string | number, remark: string }) => {
        Request.edit(params).then(() => {
          visibleModal()
          ref.current!.reload()
          message.success("操作成功")
        })
      }

      const formSubmit = (remark: string, type: number) => {
        const formVal = {
          withdraw_id: IsItem!.withdraw_id,
          status: type,
          remark: remark,
          d_type: dType
        }
        if (type === 4) {
          let title = '你确定要打款吗?此操作实时打款不可逆,请谨慎操作';
          Modal.confirm({
            title: title,
            okType: 'danger',
            onOk() {
              action(formVal)
            }
          })
        } else {
          action(formVal)
        }
      }

      const columns: ProColumns<ListItem>[] = [
        {title: 'ID', width: 60, align: "center", dataIndex: 'withdraw_id', search: false, fixed: "left"},
        {
          title: '头像',
          align: 'center',
          width: 60,
          dataIndex: 'head_image_url',
          search: false,
          render: (head_image_url: any) => {
            return <div style={{height: "50px", width: '50px', borderRadius: "50%", overflow: "hidden"}}><img
                src={head_image_url} alt="" style={{width: "100%", height: "100%"}}/></div>
          }
        },
        {title: '会员昵称', width: 120, dataIndex: 'nickname', search: false},
        {title: '会员手机号', align: 'center', width: 100, dataIndex: 'mobile'},
        {title: '提现金额', width: 70, dataIndex: 'withdraw_fee', search: false},
        {title: '手续费', width: 50, dataIndex: 'withdraw_service_fee', search: false},
        {title: '实际打款', width: 70, dataIndex: 'withdraw_reality_fee', search: false},

        {

          title: '状态', align: 'center', width: 120, render: (_, record) => {
            if (record.withdraw_status === 0) {
              if (record.zlb_status == 1) {
                return <span className="color-success">已通过</span>
              } else {
                return <span className="color-primary">待审核</span>
              }
              return <span className="color-warning">待审核{record.zlb_status == 3 ?
                  <span className="font5 color-danger">（程序打款失败）</span> : ''}</span>
            } else if (record.withdraw_status === 1) {
              if (record.zlb_status == 2) {
                return <span className="color-success">打款成功</span>
              } else if (record.zlb_status == 3) {
                return <span className="color-danger">打款失败</span>
              } else {
                return <span className="color-primary">打款中</span>
              }
            } else if (record.withdraw_status === 2) {
              return <span className="color-danger">已驳回</span>
            }
          }
        },
        {
          title: '程序打款状态', align: 'center', width: 100, dataIndex: 'task_desc', search: false, render: (_, record) => {
            if (record.zlb_status == 0) {
              return <span className="colorE">未打款</span>
            } else if (record.zlb_status == 1) {
              return <span className="color-primary">打款中</span>
            } else if (record.zlb_status == 2) {
              return <span className="color-success">打款成功</span>
            } else if (record.zlb_status == 3) {
              return <span className="color-danger">打款失败</span>
            } else {
              return <span className="color-primary">-</span>
            }
          }
        },
        {title: '程序打款备注', width: 130, dataIndex: 'task_desc', search: false},
        {title: '驳回备注', width: 130, dataIndex: 'remark', search: false},
        {title: '开户姓名', width: 80, dataIndex: 'bank_name', search: false},
        {title: '开户手机号', width: 120, dataIndex: 'zld_mobile', search: false},
        {title: '银行卡号', width: 120, dataIndex: 'bank_number', search: false},
        {title: '申请时间', align: 'center', width: 120, dataIndex: 'created_at', search: false},
        {title: '打款成功时间', align: 'center', width: 120, dataIndex: 'examine_at', search: false},
        {title: '申请时间', align: 'center', width: 0, dataIndex: 'created_at', valueType: "dateTimeRange", hideInTable: true},
        {title: '操作', align: 'center', fixed: "right", width: 100, dataIndex: 'id', search: false, render: getMenu}
      ];
      const tabChange = (key: any) => {
        setTabKey(key)
        ref.current.reset();
      }

      const SelectDType = (select_data: any) => {
        setDType(select_data);
      }
      const formItemLayout = {
        labelCol: {span: 7},
        wrapperCol: {span: 16}
      }

      const viewLayout = {
        labelCol: {span: 3},
        wrapperCol: {span: 18}
      }

      return (
          <>
            <PageHeader
                title='提现管理'
                className="site-page-header"
                breadcrumb={<Breadcrumb><Breadcrumb.Item>财务管理</Breadcrumb.Item>
                  <Breadcrumb.Item>提现管理</Breadcrumb.Item></Breadcrumb>}
                footer={
                  <Tabs defaultActiveKey="0" onChange={tabChange}>
                    {GoodStatus.map((statusItem: any, index: number) => {
                      return (
                          <TabPane
                              closable={false}
                              tab={statusItem.title}
                              key={statusItem.param.status}
                          ></TabPane>
                      );
                    })}
                  </Tabs>
                }
            >

            </PageHeader>
            <div className="wrapContent ">
              <ProTable<ListItem>
                  rowKey="withdraw_id"
                  tableStyle={{padding: '0 24px 0 24px'}}
                  columns={columns}
                  actionRef={ref}
                  headerTitle="提现列表"
                  scroll={{x: 2700}}
                  request={async (params: any) => {
                    if (params.created_at) {
                      params.start_at = params.created_at[0]
                      params.end_at = params.created_at[1]
                      delete params.created_at
                    }
                    params.withdrwa_status = tabKey
                    exportsFilter = JSON.parse(JSON.stringify(params));
                    const res = await Request.getList(params);
                    setListData(res.data)
                    return {
                      data: res.data.list,
                      total: res.data.count,
                      success: true,
                      pageSize: params.pageSize,
                      current: params.current
                    }
                  }}
                  toolbar={{
                    title: (<>{Utils.getMenuRole("record/exportBalanceWithdraw", props.allRole) && (
                        <Button type="primary" className="mr10" key="record/exportBalanceWithdraw" onClick={exportsDown}>导出</Button>)}</>),
                    filter: ''
                  }}
                  tableExtraRender={() => (
                      <Card>
                        <Row gutter={30}>
                          <Col span={6}>
                            <div className="flex pd16" style={{width: "100%", border: "1px solid #d9d9d9"}}>
                              <img src={require("../../../resources/assets/income.png")} className="mr30"
                                   style={{width: "60px", height: "60px"}} alt=""/>
                              <Statistic title="提现金额"
                                         value={'¥' + listData?.withdraw_total ? listData?.withdraw_total : 0}/>
                            </div>

                          </Col>
                          <Col span={6}>
                            <div className="flex pd16" style={{width: "100%", border: "1px solid #d9d9d9"}}>
                              <img src={require("../../../resources/assets/peoplenum.png")} className="mr30"
                                   style={{width: "60px", height: "60px"}} alt=""/>
                              <Statistic title="提现人数" value={listData?.withdraw_members ? listData?.withdraw_members : 0}/>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                  )}
              />
            </div>
            <Modal
                destroyOnClose
                title={title}
                visible={visible}
                onCancel={visibleModal}
                footer={false}
                width={800}
                className="withdraw">
              <Form{...formItemLayout} requiredMark={false} form={form}>
                <Row>
                  <Col span={8}>
                    <Form.Item label="用户ID">{IsItem?.mid}</Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="用户昵称">{IsItem?.nickname}</Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="手机号">{IsItem?.mobile}</Form.Item>
                  </Col>
                </Row>
                <Divider dashed></Divider>
                <Row>
                  <Col span={8}>
                    <Form.Item label="提现金额">{IsItem?.withdraw_fee}</Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="实际打款">{IsItem?.withdraw_reality_fee}</Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="手续费">{IsItem?.withdraw_service_fee}</Form.Item>
                  </Col>
                </Row>
                <Divider dashed></Divider>
                <Row>
                  <Col span={8}>
                    <Form.Item label="开户名">{IsItem?.bank_name}</Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="开户手机号">{IsItem?.zld_mobile}</Form.Item>
                  </Col>
                  <Col span={8}>

                    <Form.Item label="银行卡号">{IsItem?.bank_number}</Form.Item>
                  </Col>
                </Row>
                {modelType === "PREVIEW" ? (
                    <>
                      <Form.Item label="备注" labelCol={{span: 2}}{...viewLayout}>
                        {IsItem?.withdraw_info?.remark}
                      </Form.Item>
                      <Divider dashed></Divider>
                      <div>
                        <Form.Item style={{textAlign: "left"}} label="程序打款状态">
                          {ZLB_PAY_STATUS[IsItem?.zlb_status]}
                        </Form.Item>
                        {
                          IsItem?.zlb_status == 3 &&
                          <Form.Item style={{textAlign: "left"}} label="程序打款失败原因">
                            {IsItem?.task_desc}
                          </Form.Item>
                        }
                        <Form.Item style={{textAlign: "left"}} label="程序打款数据josn">{IsItem?.return_json_data}</Form.Item>
                        <Form.Item style={{textAlign: "left"}} label="程序回调数据josn">{IsItem?.notify_json_data}</Form.Item>
                      </div>
                    </>
                ) : (
                    <>
                      <Form.Item label="打款类型" name="d_type" labelCol={{span: 3}}>
                        <Select placeholder="请选择类型" defaultValue={dType} onChange={SelectDType}>
                          <Select.Option value="offline">线下打款</Select.Option>
                          <Select.Option value="zlb">众乐帮打款</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="备注" name="remark" labelCol={{span: 3}} rules={[{required: required}]}>
                        <Input.TextArea placeholder="请输入备注 驳回必填"/>
                      </Form.Item>
                      <Form.Item {...{wrapperCol: {offset: 3}}}>
                        <Button type="primary" onClick={() => {
                          setRequired(false)
                          form.validateFields().then((value) => {
                            formSubmit(value.remark, 4)
                          })
                        }}>通过</Button>
                        <Divider type="vertical"/>
                        <Button type="primary" danger onClick={() => {
                          setRequired(true)
                          form.validateFields().then((value) => {
                            formSubmit(value.remark, 5)
                          })
                        }}>驳回</Button>
                      </Form.Item>
                    </>
                )}
              </Form>
            </Modal>
          </>
      );
    }
;

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(RecordWithdraw);