import axios from "../../axios"

function getMyfun() {
    return axios.ajax({
        url: '/sys/get_my_func',
        method: "post"
    })
}

export default {
  getMyfun
}