import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Form, Input, message, Modal, Select, TreeSelect, PageHeader, Breadcrumb} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import {ListItem} from "./data"
import validate from "../../../utils/validate"
import UploadOSS from '../../../components/UploadOSS';
import {RouteComponentProps} from 'react-router-dom';
import {CATREGORY_SHOW_TYPE} from "@src/config/statusConfig";


interface IProps extends RouteComponentProps {
  allRole: any[]
}

const Category: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm()
  const ref = useRef<ActionType>();

  const [title, setTitle] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [IsItem, setIsItem] = useState<ListItem>()
  const [IsList, setIsList] = useState<ListItem[]>()

  const getMenu = (_: any, item: ListItem) => {
    const {allRole} = props
    const {getMenuRole, menuRender} = Utils
    let menu = []
    if (getMenuRole("store/editCategory", allRole)) {
      menu.push(<a onClick={() => {
        edit(item)
      }}>编辑</a>)
    }
    if (getMenuRole("store/deleteCategory", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    return menuRender(menu)
  }

  const add = () => {
    setTitle("新建")

    setIsItem({
      parent_id: 0,
      category_id: "",
      category_name: "",
      category_desc: "",
      category_icon_url: "",
      category_sort: 0,
      show_itype: 0
    })
    setVisible(true)
    form.setFieldsValue({
      parent_id: 0,
      category_id: "",
      category_name: "",
      category_desc: "",
      category_icon_url: "",
      category_sort: 0,
      show_itype: 0

    })
  }


  const edit = (item: ListItem) => {
    setTitle("编辑")
    setIsItem(item)
    setVisible(true)
    form.setFieldsValue(item)
  }

  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: 'danger',
      onOk() {
        Request.deleteData({category_id: item.category_id}).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }

  const visibleModal = () => {
    setVisible(!visible)
  }

  const formSubmit = (formVal: any) => {
    const callback = () => {
      visibleModal()
      ref.current!.reload()
      message.success("操作成功")
    }
    formVal.category_icon_url = Utils.getUploadOSSUrl(formVal.category_icon_url)[0]
    if (IsItem?.category_id) {
      formVal.category_id = IsItem.category_id
      Request.edit(formVal).then(callback)
    } else {
      Request.add(formVal).then(callback)
    }
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', width: 160, dataIndex: 'category_id'},
    {title: '名称', dataIndex: 'category_name'},
    {
      title: 'icon', dataIndex: 'category_icon_url', render: (_, record) => {
        return <img src={record.category_icon_url} alt="" style={{maxHeight: "20px"}}/>
      }
    },
    {
      title: '状态', dataIndex: 'is_show', valueEnum: {
        0: {
          text: "显示",
          status: "Success"
        },
        1: {
          text: "不显示",
          status: "Error"
        }
      }
    },
    {title: '权重', dataIndex: 'category_sort'},
    {title: '显示区域', dataIndex: 'show_itype', valueEnum: CATREGORY_SHOW_TYPE},
    {title: '操作', align: 'center', width: 160, dataIndex: 'id', render: getMenu}
  ];

  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20}
  }

  return (
      <>
        <PageHeader
            title='类型列表'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>店铺</Breadcrumb.Item>
              <Breadcrumb.Item>店铺类型</Breadcrumb.Item></Breadcrumb>}
        >

        </PageHeader>
        <div className="wrapContent">
          <ProTable<ListItem>
              rowKey="category_id"
              tableStyle={{padding: '15px 24px 0 24px'}}
              columns={columns}
              actionRef={ref}
              search={false}
              request={async () => {
                const res = await Request.getList();
                setIsList(res.data)
                return {
                  data: res.data,
                  success: true
                }
              }}
              pagination={false}
              toolbar={{
                title: (Utils.getMenuRole("store/getCategoryList", props.allRole) &&
                    <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={add}> 新建 </Button>),
                filter: ''
              }}
              toolBarRender={() => []}
              options={{density: false, fullScreen: false, reload: false, setting: false}}
          />
        </div>
        <Modal
            destroyOnClose
            title={title}
            visible={visible}
            onCancel={visibleModal}
            footer={false}
            width={600}
        >

          <Form
              {...formItemLayout}
              requiredMark={false}
              form={form}
              onFinish={formSubmit}
              initialValues={{
                ...IsItem
              }}
          >
            {
              IsItem?.parent_id !== 0 && (
                  <Form.Item
                      label="父级分类"
                      name="parent_id"
                      rules={[{required: true}]}
                  >
                    <TreeSelect disabled treeData={IsList} placeholder="请选择"/>
                  </Form.Item>
              )
            }
            <Form.Item
                label="分类名称"
                name="category_name"
                rules={[{required: true}]}
            >
              <Input placeholder="请输入分类名称"/>
            </Form.Item>
            <Form.Item
                label="图片"
                name="category_icon_url"
                valuePropName="filelist"
            >
              <UploadOSS/>
            </Form.Item>
            <Form.Item label="权重" name="category_sort" rules={[{validator: validate.checLessThan0}]}>
              <Input type="number" placeholder="请输入权重,权重越高越靠前"/>
            </Form.Item>
            <Form.Item
                label="显示区域"
                name="show_itype"
                rules={[{required: true}]}
            >
              <Select placeholder="请选择">
                <Select.Option value={1}>首页icon区域</Select.Option>
                <Select.Option value={2}>分类店铺</Select.Option>
                <Select.Option value={3}>全部</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="状态" name="is_show" rules={[{required: true}]}>
              <Select placeholder="请选择">
                <Select.Option value={0}>显示</Select.Option>
                <Select.Option value={1}>不显示</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item {...{wrapperCol: {offset: 3}}}>
              <Button type="primary" htmlType="submit">立即提交</Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(Category);