import React, {useState} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Tabs, PageHeader} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import AppForce from "./app_force"
import UpVersion from "./upversion"
import utils from '@src/utils/utils';
import AppUpload from './app_upload'

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const AppConfig: React.FC<IProps> = (props: IProps) => {
  const [tabKey, setTabKey] = useState<any>("0");
  const {getMenuRole} = utils
  const {allRole} = props
  const configStatus = [];
  getMenuRole("config/getConfigForceUpdate", allRole) && (
      configStatus[0] = {
        title: "app强更配置",
        tabkey: 0
      }
  )
  getMenuRole("config/getConfigLockVersion", allRole) && (
      configStatus[1] = {
        title: "上架版本控制",
        tabkey: 1
      }
  )
  getMenuRole("config/getConfigDownloadLandingPage", allRole) && (
      configStatus[2] = {
        title: "下载落地页配置",
        tabkey: 2
      }
  )
  const tabChange = (key: any) => {
    setTabKey(key)
  }

  return (
      <>
        <PageHeader
            title='APP配置'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>系统配置</Breadcrumb.Item>
              <Breadcrumb.Item>APP配置</Breadcrumb.Item></Breadcrumb>}
            footer={
              <Tabs defaultActiveKey="0" onChange={tabChange}>
                {configStatus.map((statusItem: any, index: number) => {
                  return (
                      <TabPane
                          closable={false}
                          tab={statusItem.title}
                          key={statusItem.tabkey}
                      ></TabPane>
                  );
                })}
              </Tabs>
            }
        >

        </PageHeader>
        <div className="wrapContent">
          {tabKey == 0 && (<AppForce {...props} />)}
          {tabKey == 1 && (<UpVersion {...props} />)}
          {tabKey == 2 && (<AppUpload {...props} />)}
        </div>
      </>
  );
};


const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(AppConfig)