import React, {useRef, useState} from "react";
import ProTable, {ActionType, ProColumns} from "@ant-design/pro-table";
import Request from "./Request";
import {ListItem} from "./data";
import {PageHeader, Breadcrumb, Tooltip, Modal, Row, Col} from "antd";
import {RouteComponentProps} from "react-router-dom";
import {connect} from "react-redux";
import utils from "@src/utils/utils";

interface IProps extends RouteComponentProps {
  allRole: any[];
}

const RecordStar: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const [IsItem, setIsItem] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);
  const visibleModal = () => {
    setVisible(!visible);
  };
  const getMenu = (_: any, item: any) => {
    const {menuRender} = utils;
    let menu = [];
    menu.push(
        <a className="color-primary" onClick={() => {
          view(item);
        }}>查看</a>,
    );
    return menuRender(menu);
  };
  const view = (item: any) => {
    setIsItem(item);
    setVisible(true);
  };

  const columns: ProColumns<ListItem>[] = [
    {title: "关键词", width: 100, dataIndex: "q", search: true, hideInTable: true},
    {title: "ID", width: 100, dataIndex: "id", search: false},
    {title: "管理员ID", width: 100, dataIndex: "admin_id", search: false},
    {title: "管理员手机号", width: 100, dataIndex: "mobile", search: false},
    {title: "管理员昵称", width: 100, dataIndex: "username", search: false},
    {title: "路径", width: 10, dataIndex: "path", search: false},
    {
      title: "上送数据", width: 200, dataIndex: "body_json", search: false, render: (_, record) => {
        return <div style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: "400px",
          textOverflow: 'ellipsis'
        }}>{record.body_json}</div>
      }
    },
    {title: "时间", width: 100, dataIndex: "created_at", valueType: "dateTimeRange", hideInTable: true,},
    {title: "时间", width: 120, dataIndex: "created_at", search: false},
    {
      title: "操作",
      align: "center",
      width: 120,
      dataIndex: "id",
      render: getMenu,
      fixed: 'right',
      search: false,
    },
  ];
  return (
      <div>
        <PageHeader
            title='操作日志'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>系统配置</Breadcrumb.Item>
              <Breadcrumb.Item>日志记录</Breadcrumb.Item></Breadcrumb>}
        >

        </PageHeader>
        <div className="wrapContent">
          <ProTable<ListItem>
              rowKey="id"
              tableStyle={{padding: "15px 24px 0 24px"}}
              columns={columns}
              actionRef={ref}
              // scroll={{x: '100%'}}
              request={async (params: any) => {
                let res
                if (params.created_at) {
                  params.start_time = params.created_at[0]
                  params.end_time = params.created_at[1]
                  delete params.created_at
                }
                res = await Request.getList(params);
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.current,
                };
              }}
              toolBarRender={false}
              options={{density: false, fullScreen: false, reload: false, setting: false}}
          />
        </div>
        <Modal
            destroyOnClose
            title="上报数据详情"
            visible={visible}
            onCancel={visibleModal}
            footer={false}
            width={800}
        >
          <Row>
            <Col className="mb15" span={24}>ID：{IsItem?.id}</Col>
            <Col className="mb15" span={24}>管理员手机号：{IsItem?.mobile}</Col>
            <Col className="mb15" span={24}>管理员昵称：{IsItem?.username}</Col>
            <Col className="mb15" span={24}>路径：{IsItem?.path}</Col>
            <Col className="mb15" span={24}>时间：{IsItem?.created_at}</Col>
            <Col className="mb15" span={24}>上报数据：{IsItem?.body_json}</Col>
          </Row>
        </Modal>
      </div>

  );
};
const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole,
  };
};

export default connect(mapStateToProps)(RecordStar);
