import React, {useState, useRef} from 'react'
import Request from "./Request"
import {connect} from "react-redux";
import {Button} from 'antd';

import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table'
import {ListItem} from "./data"
import {
  iS_FREEZE,
  MEMBER_IS_BIG,
  MEMBER_IS_NEW_MEMBER, MEMBER_LEVEL,
  MEMBER_VIP
} from "@src/config/statusConfig"
import Utils from "../../../../utils/utils"
import {RouteComponentProps} from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any
}

let exportsFilter: any = {}
const DirectPush: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const [matchparams, setParams] = useState<any>(props?.match?.params)
  const {allRole} = props
  //导出
  const exportsDown = () => {
    let url =
        process.env.REACT_APP_API_URL +
        "/member/exportMember?token=" +
        Utils.getToken() + '&parent_id=' + matchparams.id;
    delete exportsFilter.current;
    delete exportsFilter.pageSize;
    exportsFilter.mids = "2021,2022"
    for (const key in exportsFilter) {
      if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
        const element = exportsFilter[key];
        url += `&${key}=${element}`;
      }
    }
    window.location.href = url;
  };
  const columns: ProColumns<ListItem>[] = [
    {title: 'ID',align: 'center', width: 80, dataIndex: 'mid', search: false, fixed: 'left',},
    {title: '手机号',align: 'center', width: 100, dataIndex: 'mobile', search: false},
    {title: '昵称', width: 140, dataIndex: 'nickname', search: false},
    {title: '上级手机号', align: 'center',width: 100, dataIndex: 'parent_mobile', search: false},
    {title: '余额', width: 100, dataIndex: 'balance', search: false},
    {title: '用户冻积分', width: 100, dataIndex: 'lock_integral', search: false},
    {title: '商家冻积分', width: 100, dataIndex: 'lock_store_integral', search: false},
    {title: '开通店主积分', width: 100, dataIndex: 'lock_customer_integral', search: false},
    {title: '绿色积分', width: 100, dataIndex: 'green_integral', search: false},
    {title: '幸福值', width: 100, dataIndex: 'happy_integral', search: false},
    {title: '星级别',align: 'center', width: 80, dataIndex: 'level', valueEnum: MEMBER_LEVEL},

    {title: '冻结状态',align: 'center', width: 80, dataIndex: 'is_freeze', valueEnum: iS_FREEZE},
    {
      title: '邀请码',align: 'center', width: 100, dataIndex: 'is_auth_group', search: false, render: (_, record) => {
        return record.level > 0 ? record.invitation_number : <span className="color-danger">无报单</span>
      }
    },
    {
      title: '用户注册时间',align: 'center', width: 120, dataIndex: 'created_at', search: false, valueType: "dateTimeRange",
      render: (_, record) => record.created_at
    },
  ]
  return (
      <>
        <div className="wrapContent">
          <ProTable<ListItem>
              rowKey="mid"
              columns={columns}
              actionRef={ref}
              scroll={{x: 2700}}
              search={false}
              tableStyle={{padding: '15px 24px 0 24px'}}
              request={async (params: any) => {
                if (params.created_at) {
                  params.start_time = params.created_at[0]
                  params.end_time = params.created_at[1]
                  delete params.created_at
                }
                params.mid = matchparams.id
                exportsFilter = params
                const res = await Request.getList(params);
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.current
                }
              }
              }
              toolbar={{
                title: [(Utils.getMenuRole("member/exportMember", allRole) && (
                    <Button type="primary" key="member/exportMember" onClick={exportsDown}>导出</Button>))],
                filter: ''
              }}
              toolBarRender={() => []}
          />
        </div>
      </>
  );

}

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(DirectPush);