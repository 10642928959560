import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Card, message, Button, Input, Form } from 'antd';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import Utils from "../../../../utils/utils"
import { RouteComponentProps } from 'react-router-dom';
import validate from '../../../../utils/validate';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const InviteFriends: React.FC<IProps> = (props: IProps) => {

  const [serviceFee, setServiceFee] = useState<string | number>("")
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const editData = Utils.getMenuRole("config/edit_withdraw_server_fee", props.allRole)

  const initList = async () => {
    const res = await Request.getList()
    let { data } = res
    setServiceFee(data.withdraw_service_fee_rate)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initList()
  }, [])

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 }
  }

  const formSubmit = (formVal: any) => {
    Request.edit({ config_value: formVal }).then(() => {
      message.success("保存成功")
    })
  }

  return (
    <Card>
      <Skeleton loading={showSkeleton}>
        <Form {...formItemLayout} requiredMark={false}
          onFinish={formSubmit}
          initialValues={{
            withdraw_service_fee_rate: serviceFee
          }}
        >
          {editData &&
            <div style={{ textAlign: "right", marginBottom: 16 }}>
              <Button type="primary" htmlType="submit">保存</Button>
            </div>
          }
          <Form.Item
            label="提现手续费"
            name="withdraw_service_fee_rate"
            rules={[{ validator: validate.checLessThan0 }]}
          >
            <Input disabled={!editData} style={{ width: "450px" }} placeholder="请输入提现手续费" type="number" suffix="%" />
          </Form.Item>
        </Form>
      </Skeleton>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(InviteFriends);