import axios from "../../axios"

function getAreaInfo(params) {
    return axios.ajax({
        url: '/config/getArea',
        method: "post",
        data:params
    })
}

function getAreaInfo2(params) {
    return axios.ajax({
        url: '/member/getAgentArea',
        method: "post",
        data:params
    })
}

export default {
    getAreaInfo,
    getAreaInfo2
}