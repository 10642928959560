import React, {useState} from "react";
import {Breadcrumb, Tabs, PageHeader} from "antd";
import {RouteComponentProps} from "react-router-dom";
import WithdrawServiceFee from "./withdraw_service_fee";
import LeveReward from "./leve_reward";
import Examine from "./examine"
import Integral from "./integral"

import {connect} from "react-redux";
import utils from "@src/utils/utils";

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[];
}
//s1
const StarConfig: React.FC<IProps> = (props: IProps) => {
  const [tabKey, setTabKey] = useState<any>("0");
  const {getMenuRole} = utils
  const {allRole} = props
  const configStatus = [];
  if (getMenuRole("config/getWithdrawServiceFee", allRole)) {
    configStatus.push({tabkey: 0, title: "服务费配置"});
  }
  if (getMenuRole("config/getExamineConfig", allRole)) {
    configStatus.push({tabkey: 1, title: "审核配置"});
  }
  if (getMenuRole("config/getLeveRewardConfig", allRole)) {
    configStatus.push({tabkey: 2, title: "级别奖励配置"});
  }
  if (getMenuRole("config/getIntegralConfig", allRole)) {
    configStatus.push({tabkey: 3, title: "积分配置"});
  }

  const tabChange = (key: any) => {
    setTabKey(key)
  }
  return (
      <>
        <PageHeader
            title='属性配置'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>系统配置</Breadcrumb.Item>
              <Breadcrumb.Item>属性配置</Breadcrumb.Item></Breadcrumb>}
            footer={
              <Tabs defaultActiveKey="0" onChange={tabChange}>
                {configStatus.map((statusItem: any, index: number) => {
                  return (<TabPane closable={false} tab={statusItem.title} key={statusItem.tabkey}></TabPane>);
                })}
              </Tabs>
            }
        >
        </PageHeader>
        <div className="wrapContent">
          {tabKey == 0 && (<WithdrawServiceFee {...props} />)}
          {tabKey == 1 && (<Examine {...props}/>)}
          {tabKey == 2 && (<LeveReward {...props}/>)}
          {tabKey == 3 && (<Integral {...props}/>)}
        </div>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole,
  };
};

export default connect(mapStateToProps)(StarConfig);
