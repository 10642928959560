import axios from "../../../../axios"

function getList() {
    return axios.ajax({
        url: '/config/getConfigLockVersion',
        method: "post"
    })
}

function edit(params: { config_value: any }) {
    return axios.ajax({
        url: '/config/editConfigLockVersion',
        method: "post",
        data: params
    })
}

export default {
    getList,
    edit
}