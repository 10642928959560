import React from 'react'
import './index.less'
import {Card} from 'antd';

const Home = () => {
  return (
      <>
        <Card className="text-center font34" style={{margin:"10px 0 100px"}}>
          <br/><br/>
          <h1>欢迎进入以太生活管理系统</h1>
          <br/><br/>
          <img src="https://zjyp30.oss-cn-hangzhou.aliyuncs.com/admin/168481282791771.png" width="100%" alt=""/>
          <br/><br/>
          <br/><br/>

        </Card>
      </>
  );
}


export default Home


