import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  PageHeader,
  Tabs,
  Card,
  Statistic,
  Breadcrumb, Select
} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import {ListItem} from "./data"
import {RouteComponentProps} from 'react-router-dom';
import './index.less'
import {IS_WITHDRAW_STATUS, YIBAO_PAY_STATUS} from "@src/config/statusConfig";

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const GoodStatus = [
  {
    title: "待审核",
    param: {
      status: 1,
    },
  },
  {
    title: "全部",
    param: {
      status: -1,
    },
  },
  {
    title: "待程序打款中",
    param: {
      status: 2,
    },
  }, {
    title: "已驳回",
    param: {
      status: 3,
    },
  }, {
    title: "打款成功",
    param: {
      status: 4,
    },
  }, {
    title: "打款失败",
    param: {
      status: 5,
    },
  }
];
let exportsFilter: any = {};
const RecordWithdraw: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const [form] = Form.useForm()
  const [title, setTitle] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [modelType, setModelType] = useState<"EDIT" | "PREVIEW">("PREVIEW");
  const [IsItem, setIsItem] = useState<ListItem>()
  const [listData, setListData] = useState<ListItem>()
  const [required, setRequired] = useState<boolean>(false);
  const [tabKey, setTabKey] = useState<any>(1);
  const [receiveType, setreceiveType] = useState<string>("REAL_TIME");
  const [dType, setDType] = useState<string>("offline");

  //导出
  const exportsDown = () => {
    let url = process.env.REACT_APP_API_URL + "/record/exportStoreWithdraw?token=" + Utils.getToken();
    delete exportsFilter.current;
    delete exportsFilter.pageSize;
    for (const key in exportsFilter) {
      if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
        const element = exportsFilter[key];
        url += `&${key}=${element}`;
      }
    }
    window.location.href = url;
  };

  const getMenu = (_: any, item: ListItem) => {
    const {allRole} = props
    const {getMenuRole, menuRender} = Utils
    let menu = []
    menu.push(<a onClick={() => {
      perView(item)
    }}>查看</a>)
    if (getMenuRole("record/doStoreWithdraw", allRole) && (item.withdraw_status_temp == 1 || item.withdraw_status_temp == 5)) {
      menu.push(<a className="color-danger" onClick={() => {
        edit(item)
      }}>审核</a>)
    }
    if (getMenuRole("record/storeWithdrawRevocation", allRole) && item.withdraw_status_temp == 2 && item.yibaoStatus == 'REMITING' && (item.receiveType === 'TWO_HOUR' || item.receiveType === 'NEXT_DAY')) {
      menu.push(<a className="color-primary" onClick={() => {
        revocation(item)
      }}>撤销</a>)
    }
    return menuRender(menu)
  }

  const edit = async (item: ListItem) => {
    setTitle("提现审核")
    setVisible(true)
    setModelType("EDIT")
    setIsItem(item)
    form.setFieldsValue({remark: ""})
  }
  const revocation = async (item: ListItem) => {
    Modal.confirm({
      title: '你确定要撤销到待审核状态吗?',
      okType: 'danger',
      onOk() {
        Request.revocation({order_id: item.order_id}).then(() => {
          ref.current!.reload()
          message.success("操作成功")
        })
      }
    })
  }

  const perView = (item: ListItem) => {
    setTitle("查看")
    setIsItem(item)
    setModelType("PREVIEW")
    setVisible(true)
  }

  const visibleModal = () => {
    setVisible(!visible)
  }

  const action = (params: { order_id: string | number, status: string | number, remark: string }) => {
    Request.edit(params).then(() => {
      visibleModal()
      ref.current!.reload()
      message.success("操作成功")
    })
  }

  const formSubmit = (remark: string, type: number) => {
    const formVal = {
      receiveType: receiveType,
      order_id: IsItem!.order_id,
      status: type,
      remark: remark,
      d_type: dType
    }
    console.log(formVal)
    if (type === 4) {
      let title = '你确定要打款吗?此操作实时打款不可逆,请谨慎操作';
      if (dType == 'yibao') {
        title = title + '【两小时-隔日可撤销】';
      }
      Modal.confirm({
        title: title,
        okType: 'danger',
        onOk() {
          action(formVal)
        }
      })
    } else {
      action(formVal)
    }
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', width: 60, align: "center", dataIndex: 'order_id', search: false, fixed: "left"},
    {
      title: '头像', width: 60, dataIndex: 'head_image_url', search: false, render: (head_image_url: any) => {
        return <div style={{height: "50px", width: '50px', borderRadius: "50%", overflow: "hidden"}}><img
            src={head_image_url} alt="" style={{width: "100%", height: "100%"}}/></div>
      }
    },
    {title: '昵称', width: 120, dataIndex: 'nickname', search: false},
    {title: '手机号', width: 100, dataIndex: 'mobile'},
    {title: '提现金额', width: 70, dataIndex: 'withdraw_fee', search: false},
    {title: '手续费', width: 50, dataIndex: 'service_fee', search: false},
    {title: '实际打款', width: 70, dataIndex: 'amount', search: false},
    {title: '提现方式', width: 100, dataIndex: 'withdraw_type', search: true, valueEnum: IS_WITHDRAW_STATUS},
    {

      title: '状态', width: 120, render: (_, record) => {
        if (record.withdraw_status_temp === 1) {
          if (record.c_status > 0) {
            return <span className="color-danger">待审核 <span className="colorE font5">(撤销订单.)</span></span>
          }
          return <span className="color-danger">待审核</span>
        } else if (record.withdraw_status_temp === 2) {
          if (record.c_status == 1) {
            return <span className="color-danger">待程序打款 <span className="color-danger font5">(撤销中.)</span></span>
          } else if (record.c_status == 2) {
            return <span className="color-danger">待程序打款 <span className="color-success font5">(撤销成功.)</span></span>
          } else if (record.c_status == 3) {
            return <span className="color-danger">待程序打款 <span className="color-warning font5">(撤销失败.)</span></span>
          } else {
            return <span className="color-danger">待程序打款</span>
          }
        } else if (record.withdraw_status_temp === 3) {
          return <span className="color-danger">已驳回</span>
        } else if (record.withdraw_status_temp === 4) {
          if (record.c_status == 3) {
            return <span className="color-success">打款成功 <span className="colorE font5">(撤销失败.)</span></span>
          }
          return <span className="color-success">打款成功</span>
        } else if (record.withdraw_status_temp === 5) {
          return <span className="color-danger">打款失败</span>
        } else {
          return record.withdraw_status_temp;
        }
      }
    },

    {title: '姓名', width: 120, dataIndex: 'receiverAccountName', search: false},
    {title: '银行', width: 150, dataIndex: 'receiverBankCodeName', search: false,},
    {title: '开户支行', width: 300, dataIndex: 'branchBankName', search: false,},
    {title: '卡号', width: 160, dataIndex: 'receiverAccountNo', search: false},
    {title: '申请时间', width: 130, dataIndex: 'created_at', search: false},
    {title: '备注', width: 100, dataIndex: 'failReason', search: false},
    {title: '申请时间', width: 10, dataIndex: 'created_at', valueType: "dateTimeRange", hideInTable: true},
    {title: '操作', align: 'center', fixed: "right", width: 100, dataIndex: 'id', search: false, render: getMenu}
  ];
  const tabChange = (key: any) => {
    setTabKey(key)
    ref.current.reset();
  }
  const SelectDZ = (select_data: any) => {
    setreceiveType(select_data);
  }
  const SelectDType = (select_data: any) => {
    setDType(select_data);
  }
  const formItemLayout = {
    labelCol: {span: 7},
    wrapperCol: {span: 16}
  }

  const viewLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 18}
  }

  return (
      <>
        <PageHeader
            title='提现管理'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>财务管理</Breadcrumb.Item>
              <Breadcrumb.Item>提现管理</Breadcrumb.Item></Breadcrumb>}
            footer={
              <Tabs defaultActiveKey="1" onChange={tabChange}>
                {GoodStatus.map((statusItem: any, index: number) => {
                  return (
                      <TabPane
                          closable={false}
                          tab={statusItem.title}
                          key={statusItem.param.status}
                      ></TabPane>
                  );
                })}
              </Tabs>
            }
        >

        </PageHeader>
        <div className="wrapContent ">
          <ProTable<ListItem>
              rowKey="order_id"
              tableStyle={{padding: '0 24px 0 24px'}}
              columns={columns}
              actionRef={ref}
              headerTitle="提现列表"
              scroll={{x: 2500}}
              request={async (params: any) => {
                if (params.created_at) {
                  params.start_at = params.created_at[0]
                  params.end_at = params.created_at[1]
                  delete params.created_at
                }
                params.withdrwa_status = tabKey
                exportsFilter = JSON.parse(JSON.stringify(params));
                const res = await Request.getList(params);
                setListData(res.data)
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.current
                }
              }}
              toolbar={{
                title: (<>{Utils.getMenuRole("record/exportStoreWithdraw", props.allRole) && (
                    <Button type="primary" className="mr10" key="record/exportStoreWithdraw"
                            onClick={exportsDown}>导出</Button>)}</>),
                filter: ''
              }}
              tableExtraRender={() => (
                  <Card>
                    <Row gutter={30}>
                      <Col span={6}>
                        <div className="flex pd16" style={{width: "100%", border: "1px solid #d9d9d9"}}>
                          <img src={require("../../../resources/assets/income.png")} className="mr30"
                               style={{width: "60px", height: "60px"}} alt=""/>
                          <Statistic title="提现金额"
                                     value={'¥' + listData?.withdraw_total ? listData?.withdraw_total : 0}/>
                        </div>

                      </Col>
                      <Col span={6}>
                        <div className="flex pd16" style={{width: "100%", border: "1px solid #d9d9d9"}}>
                          <img src={require("../../../resources/assets/peoplenum.png")} className="mr30"
                               style={{width: "60px", height: "60px"}} alt=""/>
                          <Statistic title="提现人数" value={listData?.withdraw_members ? listData?.withdraw_members : 0}/>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex pd16" style={{width: "100%", border: "1px solid #d9d9d9"}}>
                          <img src={require("../../../resources/assets/icon2.png")} className="mr30" style={{width: "60px", height: "60px"}} alt=""/>
                          <Statistic title="易宝-商户资金账户" value={listData?.balance_fund_account ? listData?.balance_fund_account : 0}/>
                          <span className="font5 color-danger" style={{margin: "3px 0 0 0"}}>&nbsp;状态：</span>
                          <span className="font5 color-danger" style={{margin: "3px 0 0 0"}}>

                            {
                              listData?.balance_fund_account_status == 'AVAILABLE' ? '可用' :
                                  (listData?.balance_fund_account_status == 'FROZEN' ? '冻结' :
                                          (listData?.balance_fund_account_status == 'FROZEN_CREDIT' ? '冻结止收' :
                                                  (listData?.balance_fund_account_status == 'FROZEN_DEBIT' ? '冻结止付' :
                                                          (listData?.balance_fund_account_status == 'CANCELLATION' ? '销户' : '-')

                                                  )
                                          )
                                  )
                            }
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <img src={require("../../../resources/assets/workbench9.png")} className="mr30" style={{width: "60px", height: "60px"}} alt=""/>
                          <Statistic title="易宝-待结算账户（D1结算）" value={listData?.balance_settle_account ? listData?.balance_settle_account : 0}/>
                          <span className="font5 color-danger" style={{margin: "3px 0 0 0"}}>状态：</span>
                          <span className="font5 color-danger" style={{margin: "3px 0 0 0"}}>
                            {
                            listData?.balance_settle_account_status == 'AVAILABLE' ? '可用' :
                                (listData?.balance_settle_account_status == 'FROZEN' ? '冻结' :
                                        (listData?.balance_settle_account_status == 'FROZEN_CREDIT' ? '冻结止收' :
                                                (listData?.balance_settle_account_status == 'FROZEN_DEBIT' ? '冻结止付' :
                                                        (listData?.balance_settle_account_status == 'CANCELLATION' ? '销户' : '-')

                                                )
                                        )
                                )
                          }
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
              )}
          />
        </div>
        <Modal
            destroyOnClose
            title={title}
            visible={visible}
            onCancel={visibleModal}
            footer={false}
            width={800}
            className="withdraw">
          <Form{...formItemLayout} requiredMark={false} form={form}>
            <Row>
              <Col span={8}>
                <Form.Item label="用户ID">{IsItem?.mid}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="用户昵称">{IsItem?.nickname}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="手机号">{IsItem?.mobile}</Form.Item>
              </Col>
            </Row>
            <Divider dashed></Divider>
            <Row>
              <Col span={8}>
                <Form.Item label="提现金额">{IsItem?.withdraw_fee}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="实际打款">{IsItem?.amount}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="手续费">{IsItem?.service_fee}</Form.Item>
              </Col>
            </Row>
            <Divider dashed></Divider>
            <Row>
              <Col span={8}>
                <Form.Item label="开户名">{IsItem?.receiverAccountName}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="卡号">{IsItem?.receiverAccountNo}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="总行">{IsItem?.receiverBankCodeName}</Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item
                    label="提现方式">{IsItem?.withdraw_type == 1 ? '银行卡' : (IsItem?.withdraw_type === 2 ? '支付宝' : '微信')}</Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                <Form.Item labelCol={{span: 3}} label="开户行">{IsItem?.branchBankName}</Form.Item>
              </Col>
            </Row>
            {modelType === "PREVIEW" ? (
                <>
                  <Form.Item label="备注" labelCol={{span: 2}}{...viewLayout}>
                    {IsItem?.withdraw_info?.remark}
                  </Form.Item>
                  <Divider dashed></Divider>
                  <div>
                    <Form.Item style={{textAlign: "left"}} label="易宝状态">
                      {YIBAO_PAY_STATUS[IsItem?.yibaoStatus]}
                    </Form.Item>
                    <Form.Item style={{textAlign: "left"}} label="易宝打款数据josn（技术使用）">{IsItem?.returnMsgJson}</Form.Item>
                    <Form.Item style={{textAlign: "left"}} label="易宝回调数据josn（技术使用）">{IsItem?.notifyJson}</Form.Item>
                  </div>
                </>
            ) : (
                <>
                  <Form.Item label="打款类型" name="d_type" labelCol={{span: 3}}>
                    <Select placeholder="请选择类型" defaultValue="offline" onChange={SelectDType}>
                      <Select.Option value="offline">线下打款</Select.Option>
                      <Select.Option value="yibao">易宝打款</Select.Option>
                    </Select>
                  </Form.Item>
                  {dType == 'yibao' &&
                  <Form.Item label="到账类型" name="receiveType" labelCol={{span: 3}}>
                      <Select placeholder="请选择类型" defaultValue="REAL_TIME" onChange={SelectDZ}>
                          <Select.Option value="REAL_TIME">实时</Select.Option>
                          <Select.Option value="TWO_HOUR">两小时到账</Select.Option>
                          <Select.Option value="NEXT_DAY">次日到账 <span
                              className="font5">(无特殊情况资金于次日上午7点左右到收款银行账户中)</span></Select.Option>
                      </Select>
                  </Form.Item>
                  }
                  <Form.Item label="备注" name="remark" labelCol={{span: 3}} rules={[{required: required}]}>
                    <Input.TextArea placeholder="请输入备注 驳回必填"/>
                  </Form.Item>
                  <Form.Item {...{wrapperCol: {offset: 3}}}>
                    <Button type="primary" onClick={() => {
                      setRequired(false)
                      form.validateFields().then((value) => {
                        formSubmit(value.remark, 4)
                      })
                    }}>通过</Button>
                    <Divider type="vertical"/>
                    <Button type="primary" danger onClick={() => {
                      setRequired(true)
                      form.validateFields().then((value) => {
                        formSubmit(value.remark, 5)
                      })
                    }}>驳回</Button>
                  </Form.Item>
                </>
            )}
          </Form>
        </Modal>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(RecordWithdraw);