import React, {useRef, useState} from 'react';
import {connect} from "react-redux";
import {PlusOutlined} from '@ant-design/icons';
import {PageHeader, Breadcrumb, Modal, Form, Input, Button, message} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import {ListItem} from "./data"
import Utils from "../../../utils/utils"
import {RouteComponentProps} from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const Banner: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const [visible, setVisible] = useState<boolean>(false);
  const [IsItem, setIsItem] = useState<ListItem>()

  const getMenu = (_: any, item: ListItem) => {
    const {allRole} = props
    const {getMenuRole, menuRender} = Utils
    let menu = []
    if (getMenuRole("config/editSupplier", allRole)) {
      menu.push(<a onClick={() => {
        edit(item)
      }}>编辑</a>)
    }
    if (getMenuRole("config/deletedSupplier", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    return menuRender(menu)
  }

  const add = () => {
    setVisible(true)
    setIsItem(null)
  }

  const visibleModal = () => {
    setVisible(!visible)
  }
  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20}
  }

  const edit = (item: ListItem) => {
    setVisible(true)
    setIsItem(item)
  }

  const formSubmit = (formVal: any) => {
    const callback = () => {
      visibleModal()
      ref.current!.reload()
      message.success("操作成功")
    }
    if (IsItem?.id) {
      formVal.id = IsItem.id
      Request.edit(formVal).then(callback)
    } else {
      Request.add(formVal).then(callback)
    }
  }

  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: 'danger',
      onOk() {
        Request.deleteData({id: item.id}).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', width: 80, dataIndex: 'id', search: false},
    {title: '关键词', width: 10, dataIndex: 'q', hideInTable: true, search: true},
    {title: '供应链名字', width: 120, dataIndex: 'store_name', search: false},
    {title: '姓名', width: 120, dataIndex: 'name', search: false},
    {title: '手机号', width: 120, dataIndex: 'mobile', search: false},
    {title: '备注', width: 120, dataIndex: 'desc', search: false},
    {title: '操作', align: 'center', width: 160, dataIndex: 'id', render: getMenu, search: false}

  ];

  return (
      <>
        <PageHeader
            title='供应链列表'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>厂家</Breadcrumb.Item>
              <Breadcrumb.Item>供应链</Breadcrumb.Item></Breadcrumb>}></PageHeader>
        <div className="wrapContent">
          <ProTable<ListItem>
              rowKey="id"
              tableStyle={{padding: '0 24px 0 24px'}}
              columns={columns}
              actionRef={ref}
              request={async (params: any) => {
                if (params.created_at) {
                  params.start_time = params.created_at[0]
                  params.end_time = params.created_at[1]
                  delete params.created_at
                }
                params.status = 1
                const res = await Request.getList(params);
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.current
                }
              }
              }
              toolBarRender={() => []}
              toolbar={{
                title: ((Utils.getMenuRole("config/addSupplier", props.allRole) &&
                    <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={add}> 新建 </Button>)),
                filter: ''
              }}
              options={{density: false, fullScreen: false, reload: false, setting: false}}
          />
        </div>
        <Modal
            destroyOnClose
            title="编辑/新建"
            visible={visible}
            onCancel={visibleModal}
            footer={false}
            width={800}>
          <Form
              {...formItemLayout}
              requiredMark={false}
              onFinish={formSubmit}
              initialValues={{
                ...IsItem
              }}
          >
            <Form.Item
                label="供应链名字"
                name="store_name"
            >
              <Input placeholder="请输入供应链名字"/>
            </Form.Item>
            <Form.Item
                label="姓名"
                name="name"
            >
              <Input type="text" placeholder="请输入姓名"/>
            </Form.Item>
            <Form.Item
                label="手机号"
                name="mobile"
            >
              <Input type="text" placeholder="请输入手机号"/>
            </Form.Item>
            <Form.Item
                label="备注"
                name="desc"
            >
              <Input.TextArea placeholder="备注" rows={5}/>
            </Form.Item>
            <Form.Item {...{wrapperCol: {offset: 3}}}>
              <Button type="primary" htmlType="submit">立即提交</Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(Banner);