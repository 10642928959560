import React from 'react';
import {useEffect, useState, useCallback} from 'react'
import oss from 'ali-oss';
import {Select, Dropdown, Menu, Divider} from 'antd';
import {DownOutlined} from "@ant-design/icons"

const Option = Select.Option;
//同步hooks
const useSyncCallback = (callback) => {
    const [proxyState, setProxyState] = useState({current: false})

    const Func = useCallback(() => {
        setProxyState({current: true})
    }, [proxyState])

    useEffect(() => {
        if (proxyState.current === true) setProxyState({current: false})
    }, [proxyState])

    useEffect(() => {
        proxyState.current && callback()
    })
    return Func
}
export default {

    useSyncCallback,
    getUrlKey(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(window.location.href) || ["", ""])[1].replace(/\+/g, '%20')) || null
    },
    formateDate(time) {
        function checkTime(time) {
            return time < 10 ? "0" + time : time
        }

        if (!time) return '';
        let date = new Date(time);
        return date.getFullYear() + '-' + checkTime(date.getMonth() + 1) +
            '-' + checkTime(date.getDate()) + ' ' +
            checkTime(date.getHours()) + ":" + checkTime(date.getMinutes()) + ":" + checkTime(date.getSeconds());
    },
    // 封装Option 外层接收data
    getOptionList(data, key = "id", val = "name") {
        if (!data) {
            return []
        }
        let options = [];
        if (Array.isArray(data)) {
            data.forEach((item) => {
                //在options 中添加option 对象
                options.push(<Option value={item[key]} key={item[key]}>{item[val]}</Option>)
            });
        } else {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    let title = typeof data[key] === "string" ? data[key] : data[key].text
                    options.push(<Option value={key} key={key}>{title}</Option>)
                }
            }
        }

        return options;
    },
    // 删除 tabs
    deleteTab(tabs, targetKey) {
        let {activeKey} = tabs

        // 没有传入要删除的 targetKey 取当前地址
        if (!targetKey) {
            targetKey = window.location.hash.substr(1)
            let index = targetKey.indexOf("?")
            if (index !== -1) targetKey = targetKey.substring(0, index)
        }

        let lastIndex = tabs.panes.findIndex((pane) => pane.key === targetKey) - 1;
        const panes = tabs.panes.filter((pane) => pane.key !== targetKey);
        if (panes.length && activeKey === targetKey) {
            if (lastIndex >= 0) {
                activeKey = panes[lastIndex].key;
            } else {
                activeKey = panes[0].key;
            }
        }
        return {panes, activeKey}
    },
    UploadOSS(file, config) {
        function uploadPath() {
            let date = new Date()
            let time = date.getTime() + "" + Math.ceil(Math.random() * 100)
            // 上传文件的路径，使用日期命名文件目录
            let folder = config.folder || date.getFullYear() + (date.getMonth() + 1) + date.getDate()
            return `${folder}/${time}.${file.type.split("/")[1]}`
        }

        let client = new oss({
            accessKeyId: config.accessKeyId,
            accessKeySecret: config.accessKeySecret,
            bucket: config.bucket,
            region: config.region,
            secure: true,
            timeout: 600000
        })

        return new Promise((resolve, reject) => {
            client.put(uploadPath(), file).then(data => {
                resolve(data);
            }).catch(error => {
                reject(error)
            })
        })
    },
    getUploadOSSUrl(url) {
        let resRul = []
        if (typeof url === "string") {
            resRul.push(url)
        }
        if (Array.isArray(url)) {
            url.forEach((item) => {
                let u = typeof item === "string" ? item : item?.url
                if (u) {
                    resRul.push(u)
                }
            })
        } else {
            resRul.push(url.url)
        }
        return resRul
    },
    getToken() {
        return localStorage.getItem("token");
    },
    setToken(token) {
        if (token) {
            localStorage.setItem("token", token)
        } else {
            localStorage.removeItem("token")
        }
    },
    getMenuRole(key, allRole) {
        let role = false
        if (allRole && allRole.length > 0) {

            let index = allRole.findIndex((item) => {
                return item.url === key
            })
            role = index !== -1
        }
        return role
    },
    // 为表格操作栏中的按钮增加分隔符,超过 3 个按钮时在<更多>里面展示
    menuRender(menu) {
        let list = []
        if (menu.length > 3) {
            let downnemu = (
                <Dropdown overlay={
                    <Menu>{
                        menu.slice(2).map((item, index) => {
                            return <Menu.Item key={"downnemu" + index}>{item}</Menu.Item>
                        })
                    }</Menu>
                }>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>更多<DownOutlined/></a>
                </Dropdown>)
            let newmenu = [menu[0], menu[1], downnemu]
            newmenu.forEach((item, index) => {
                list.push(React.createElement("span", {key: index}, item, (index !== newmenu.length - 1 &&
                    <Divider type="vertical"/>)))
            })
        } else {
            menu.forEach((item, index) => {
                list.push(React.createElement("span", {key: index}, item, (index !== menu.length - 1 &&
                    <Divider type="vertical"/>)))
            })
        }
        return list
    },
    // 校验身份证号
    IDCardCheck(num) {
        num = num.toUpperCase();
        //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
        if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))) {
            // alert('输入的身份证号长度不对，或者号码不符合规定！\n15位号码应全为数字，18位号码末位可以为数字或X。');
            return false;
        }
        //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        //下面分别分析出生日期和校验位
        let len, re;
        len = num.length;
        if (len === 15) {
            re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
            let arrSplit = num.match(re);

            //检查生日日期是否正确
            let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
            let bGoodDay;
            bGoodDay = (dtmBirth.getYear() === Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) === Number(arrSplit[3])) && (dtmBirth.getDate() === Number(arrSplit[4]));
            if (!bGoodDay) {
                // alert('输入的身份证号里出生日期不对！');
                return false;
            } else {
                //将15位身份证转成18位
                //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                let arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                let arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
                let nTemp = 0, i;
                num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6);
                for (i = 0; i < 17; i++) {
                    nTemp += num.substr(i, 1) * arrInt[i];
                }
                num += arrCh[nTemp % 11];
                return true;
            }
        }
        if (len === 18) {
            re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
            let arrSplit = num.match(re);

            //检查生日日期是否正确
            let dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
            let bGoodDay;
            bGoodDay = (dtmBirth.getFullYear() === Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) === Number(arrSplit[3])) && (dtmBirth.getDate() === Number(arrSplit[4]));
            if (!bGoodDay) {
                // alert(dtmBirth.getYear());
                // alert(arrSplit[2]);
                // alert('输入的身份证号里出生日期不对！');
                return false;
            } else {
                //检验18位身份证的校验码是否正确。
                //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                let valnum;
                let arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                let arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
                let nTemp = 0, i;
                for (i = 0; i < 17; i++) {
                    nTemp += num.substr(i, 1) * arrInt[i];
                }
                valnum = arrCh[nTemp % 11];
                if (valnum !== num.substr(17, 1)) {
                    // alert('18位身份证的校验码不正确！'); //应该为： + valnum
                    return false;
                }
                return true;
            }
        }
        return false;
    }

}