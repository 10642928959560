import React from "react";
import { Button, Card, Result } from 'antd';
import { RouteComponentProps } from "react-router-dom";
import { switchTabs } from "@src/redux/action";
import { connect } from "react-redux";

interface IsProps extends RouteComponentProps {
  dispatch: any;
}

const NotPower: React.FC<IsProps> = (props: IsProps) => {

  const toHome = () => {
    props.history.replace("/home")
    props.dispatch(switchTabs("/home"))
  }

  return (
    <Card>
      <Result
        status="403"
        title={`抱歉!!! 您没有访问权限`}
        extra={<Button type="primary" onClick={toHome}>返回首页</Button>}
      />
    </Card>)
}

export default connect()(NotPower)