import React from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { LockOutlined, SafetyOutlined, UserOutlined } from '@ant-design/icons';
import { Request } from "./Request"
import "./index.less";
import { connect } from "react-redux"; // 连接器
import { switchUsers } from "../../redux/action";

class Login extends React.Component {
    state = {
        captchaImg: "",
        isCaptcha: false
    }

    login = (params: any) => {
        Request.login(params, (res) => {
            if (res.login) {
                localStorage.setItem("nickname", res.username)
                // 登录成功
                // 事件派发，自动调用reducer，通过reducer讲用户名保存到store对象中
                // @ts-ignore
                const { dispatch, history } = this.props;
                dispatch(switchUsers(res));
                history.push('/');
            } else {
                message.error(res.msg)
                let isCaptcha = Number(res.logins) > 2
                this.setState({
                    isCaptcha
                })
                if (isCaptcha) {
                    this.getCaptchaImg()
                }
            }
        })
    };

    getCaptchaImg = () => {
        Request.getCaptchaImg({}, (res) => {
            this.setState({
                captchaImg: res.data.img
            })
        })
    }

    finish = (values: { username: any; password: any; captcha: any; }) => {
        this.login(values);
    };

    checkUsername = (_: any, value: string) => {
        let reg = /^\w+$/;
        if (!value) {
            return Promise.reject("请输入用户名")
        } else if (!reg.test(value)) {
            return Promise.reject("用户名只允许输入英文字母和数字")
        } else {
            return Promise.resolve()
        }
    };

    render() {
        return (
            <div className="login-page">
                <div className="login-content-wrap">
                    <div className="login-content">
                        <div className="word">
                            <div>
                                <img className="logo" src='https://ywshapp.oss-cn-hangzhou.aliyuncs.com/admin/WechatIMG103.png' alt="" />
                            </div>
                            <div className="profile">
                                <p>集四海之气，借八方之力。</p>
                                <p>聚天下朋友，有作为，有品味。</p>
                            </div>
                        </div>

                        <div className="login-box">
                            <div className="title">用户登录</div>
                            <Form className="login-form" onFinish={this.finish}>
                                <Form.Item
                                    name="username"
                                    rules={[{ validator: this.checkUsername }]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder="用户名" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: '请输入密码' }]}
                                >
                                    <Input prefix={<LockOutlined />} type="password" placeholder="密码" />
                                </Form.Item>
                                {this.state.isCaptcha ?
                                    (<Row gutter={8}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="captcha"
                                                rules={[{ required: true, message: '请输入验证码' }]}
                                            >
                                                <Input prefix={<SafetyOutlined />} placeholder="验证码" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <img onClick={this.getCaptchaImg} src={this.state.captchaImg} alt="" />
                                        </Col>
                                    </Row>) : null}

                                <Form.Item>
                                    <Button type="primary" className="login-form-button" htmlType="submit">
                                        登录
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <div className="login-footer">
                        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2023009785号-1</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(Login);


