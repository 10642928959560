import React from "react";
import "./index.less"

interface IsProps {
  loading: boolean
  children?: any
}

const Skeleton: React.FC<IsProps> = (props: IsProps) => {
  return (
    <>
      {props.loading ? (
        <div className="my-skeleton-container">
          <div className="my-skeleton-list">
            <div className="skeleton-list-options">
              <div className="skeleton-list-options-btn skeleton-animation"></div>
              <div className="skeleton-list-options-btn-min skeleton-animation"></div>
            </div>
            <div className="skeleton-list-body">
              {
                [1, 2, 3, 4, 5, 6].map(item => (
                  <div className="skeleton-list-item" key={item}>
                    <div className="list-item-line">
                      <div className="line-short skeleton-animation"></div>
                      <div className="line-long skeleton-animation"></div>
                    </div>
                    <div className="list-item-option skeleton-animation"></div>
                  </div>
                ))
              }
            </div>
            <div className="skeleton-list-bottom">
              <div className="bottom-pagination skeleton-animation"></div>
            </div>
          </div>
        </div>) : (props.children)}
    </>
  )
}

export default Skeleton