import axios from "../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/config/getBannerList',
        method: "post",
        data: params,
    })
}

function add(params: {
    banner_title: string,
    banner_desc: string,
    banner_sort: string,
    bg_color?: string,
    image_url: string,
    banner_status: string,
    target_type: string,
    target_address?: string,
    show_region_type: string,
    failure_at: string
}) {
    return axios.ajax({
        url: '/config/addBanner',
        method: "post",
        data: params
    })
}

function edit(params: {
    banner_id: string | number,
    banner_title: string,
    banner_desc: string,
    banner_sort: string,
    bg_color?: string,
    image_url: string,
    banner_status: string | number,
    target_type: string,
    target_address?: string,
    show_region_type: string,
    failure_at: string
}) {
    return axios.ajax({
        url: '/config/editBanner',
        method: "post",
        data: params
    })
}

function deleteData(params: { banner_id: string | number }) {
    return axios.ajax({
        url: '/config/deleteBanner',
        method: "post",
        data: params
    })
}

function upDownGood(params: { banner_id: string | number, banner_status: string | number }) {
    return axios.ajax({
        url: '/config/statusBanner',
        method: "post",
        data: params
    })
}

export default {
    getList,
    add,
    edit,
    deleteData,
    upDownGood,
}