import React, { useState, useEffect, useImperativeHandle } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Divider,
  Button,
  Select,
  Switch,
  DatePicker,
  message,
  Modal
} from "antd";
import UploadOSS from "../UploadOSS";
import utils from "../../utils/utils";
import { PlusOutlined } from "@ant-design/icons";
import "./index.less";
import validate from '../../utils/validate';
import PickerColor from "../PickerColor";

const renderEditCell = (
  dataIndex: string,
  inputType: string,
  selectOption: any,
  rules: boolean,
  maxLength: any,
  record:any,
  suffix:any,
) => {
  let valuePropName = "value";
  let message = "";
  let suffixbf
  if (inputType === "UPLOAD") {
    valuePropName = "filelist";
    message = "请选择图片";
  }
  if (inputType === "SWITCH") {
    valuePropName = "checked";
  }
  if(record.type==3){
    suffixbf="%"
  }
  // if(selectOption=="折扣卷")
  if(inputType!="RESET"){
  return (
    <Form.Item
      className="required"
      name={dataIndex}
      style={{ margin: 0 }}
      valuePropName={valuePropName}
      // rules={[inputType=="NUMBER"?{ validator: validate.checLessThan1}:{ required: rules, message: <span>{message}</span>}]}
      rules={[{ required: rules, message: <span>{message}</span>}]}

    >
      {(() => {
        switch (inputType) {
          case "NUMBER":
            return <Input type="number" style={{ marginLeft: "-8px" }} suffix={suffixbf} />;
          case "TEXT":
            return (
              <Input
                style={{ marginLeft: "-8px" }}
                maxLength={maxLength && maxLength}
                suffix={suffix}
              />
            );
          case "TEXTAREA":
            return (
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                style={{ marginLeft: "-8px" }}
              />
            );
          case "SELECT":
            let { list = selectOption, key, val } = selectOption;
            return (
              <Select placeholder="请选择" style={{ marginLeft: "-8px" }}>
                {utils.getOptionList(list, key, val)}
                {console.log(utils.getOptionList(list, key, val))}
              </Select>
            );
          case "UPLOAD":
            return <UploadOSS />;
          case "RESET":
            return <Button  className="reset" onClick={()=>resetData(record,dataIndex)}>
            重置
          </Button>;
          case "SWITCH":
            return <Switch />;
          case "COLOR":
            return <PickerColor />;
          case "DATE":
            return <DatePicker showTime={true} format="YYYY-MM-DD HH:mm:ss" />;
          default:
            return <Input style={{ marginLeft: "-8px" }} />;
        }
      })()}
    </Form.Item> 
  );}
  else{
    return <Button  className="reset" onClick={()=>resetData(record,dataIndex)}>
    重置
  </Button>
  }
};
const resetData = (record,dataIndex)=>{
  record[dataIndex]="0"
}
const renderText = (
  data: any,
  inputType: string,
  selectOption: any,
  children: any
) => {
  switch (inputType) {
    case "UPLOAD":
      return <UploadOSS disabled={true} filelist={data} />;
    case "COLOR":
      return <PickerColor value={data} disabled />;
    case "DATE":
      return utils.formateDate(data);
    case "SELECT":
      let text = "";
      let { list = selectOption, key = "id", val = "name" } = selectOption;
      if (Array.isArray(list)) {
        list.some((item: any) => {
          return item[key] === data && (text = item[val]);
        });
      } else {
        text = list[data];
      }
      return text;
    default:
      return children;
  }
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: string;
  suffix?:string;
  record: any;
  index: number;
  selectOption?: any;
  rules?: boolean;
  maxLength?: number | undefined;
  children: React.ReactNode;
}

interface EditableTableProps {
  cRef?: any;
  originData: any[];
  isrowSelection?:boolean;
  columns: any[];
  edit?: { modify?: boolean; add?: boolean; del?: boolean ;cancelbtn?:boolean;specialKey?:any};
  defaultvalarr?:any;
  ossConfig?: {
    accessKeyId: string;
    accessKeySecret: string;
    region: string;
    bucket: string;
    folder?: string;
  };
  onChange: (data: any, index?: number, fun?: Function) => void;
  isAsync?: boolean;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  suffix,
  record,
  index,
  children,
  selectOption,
  rules = true,
  maxLength,
  ...restProps
}) => {
  return (
    <td {...restProps} style={{ height: "57px" }}>
      {editing
        ? renderEditCell(dataIndex, inputType, selectOption, rules, maxLength,record,suffix)
        : inputType === undefined
        ? children
        : renderText(record[dataIndex], inputType, selectOption, children)}
    </td>
  );
};

const EditTable: React.FC<EditableTableProps> = (props: EditableTableProps) => {
  const [form] = Form.useForm();
  const { edit = {}, cRef } = props;
  const { modify = true, add = true, del = true,cancelbtn = true,specialKey = '' } = edit;
  props.originData.forEach((item, index) => {
    item.key = index;
  });
  const [data, setData] = useState<any[]>(props.originData);
  const [defaultvalarr, setdefaultvalarr] = useState<any>(props.defaultvalarr);
  const [count, setCount] = useState<number>(props.originData.length);
  const [newEdit, setNewEdit] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState<number | string>("");
  const[isrowSelection,setisrowSelection] = useState<boolean>(props.isrowSelection);
  const [selectedRows,setSelectedRows] = useState<any[]>();

  const isEditing = (record: any) => record.key === editingKey;
  useImperativeHandle(cRef, () => ({
    changeVal: (newVal: any) => {
      newVal.forEach((item: any, index: number) => {
        item.key = index;
      });
      setCount(newVal.length);
      setData(newVal);
    },
  }));

  const editData = (record: any) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };
  const changeSpecialKey =(key: number,num:number) =>{
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
    newData[index].is_big = num
    // newData.forEach((item:any)=>{
    //   if(key === item.key){
    //     item.specialKey=1
    //   }else{
    //     item.specialKey=0
    //   }
    // })
    setData(newData);
    props.onChange(JSON.parse(JSON.stringify(newData)), index);
  }
  useEffect(() => {
    if (editingKey === "") {
      form.resetFields();
    }
  }, [form, editingKey]);

  const save = async (key: number) => {
    console.log(form.validateFields())
    try {
      const row = (await form.validateFields()) as any;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        const fun= ()=>{
          let num =0
          console.log(num,newData)
          newData.forEach((ele,index) => {
            if(Number(ele.red_deduction_max_fee)>Number(ele.spec_price)){
              message.error("红包最高抵扣不能大于销售价")
              // newData.splice(index, 1);
              num = 1
            }
          });
          if(num==0){
            setData(newData);
            setEditingKey("");
            setNewEdit(false);
          }
          
        }
        if (props.isAsync) {
          props.onChange(JSON.parse(JSON.stringify(newData)), index, fun);
        } else {
          fun()
          props.onChange(JSON.parse(JSON.stringify(newData)), index);
        }
      }
    } catch (err) {}
  };

  const handleAdd = () => {
    if (editingKey !== "") {
      return;
    }
    const newData = [...data];
    newData.push({
      key: count,
    });
    setCount(count + 1);
    setEditingKey(count);
    setNewEdit(true);
    setData(newData);
    setSelectedRows([])
    form.resetFields();
    form.setFieldsValue({ ...defaultvalarr });
  };

  const deleteData = (key: number) => {
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      newData.splice(index, 1);
      setData(newData);
      setEditingKey("");
      if (newEdit) {
        return setNewEdit(false);
      }
      props.onChange(JSON.parse(JSON.stringify(newData)), index);
    }
  };
  //批量删除
  const batchDel=()=>{
    const newData = [...data];
    if (!selectedRows[0]) {
      message.error("请选择需要操作的数据");
      return;
    }
    Modal.confirm({
      title: "你确定要删除这"+selectedRows.length+"条数据吗?",
      okType: "danger",
      onOk() {
        selectedRows.forEach(item=>{
          let index = newData.findIndex((ele) => item.key === ele.key);
          newData.splice(index, 1);
        })
        setData(newData);
        props.onChange(JSON.parse(JSON.stringify(newData)));
      },
    });
    
    
  }
  const cancel = (key: number) => {
    setEditingKey("");
    if (newEdit) {
      deleteData(key);
    }
  };

  const operation = {
    title: "操作",
    width: 100,
    dataIndex: "operation",
    render: (_: any, record: any) => {
      const editable = isEditing(record);
      return editable ? (
        <>
          <a onClick={() => save(record.key)}>保存</a>
          <Divider type="vertical" />
          {cancelbtn&&<a className="color-success" onClick={() => cancel(record.key)}>
            取消
          </a>}
        </>
      ) : (
        <>
          {modify && (
            <Button
              disabled={editingKey !== ""}
              onClick={() => editData(record)}
              type="link"
              style={{ padding: 0 }}
            >
              编辑
            </Button>          
          )}
          {specialKey&&record.is_big==0&&(
            <><br></br>
            <Button
              disabled={editingKey !== ""}
              className="specialKey"
              onClick={() => changeSpecialKey(record.key,1)}
              type="link"
              style={{ padding: 0 }}
            >
              置为大奖
            </Button></>
          )}
          {specialKey&&record.is_big==1&&(
            <><br></br>
            <Button
              disabled={editingKey !== ""}
              onClick={() => changeSpecialKey(record.key,0)}
              type="link"
              style={{ padding: 0 }}
            >
               取消大奖
            </Button></>
          )}
          {del && (
            <>
              {modify && <Divider type="vertical" />}
              <Popconfirm
                disabled={editingKey !== ""}
                title="确定要删除这一行吗?"
                onConfirm={() => deleteData(record.key)}
              >
                <Button
                  disabled={editingKey !== ""}
                  danger
                  type="link"
                  style={{ padding: 0 }}
                  className="color-danger"
                >
                  删除
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      );
    },
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRows(selectedRows)
    },
  };
  const mergedColumns = props.columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record: record,
          inputType: col.editable,
          suffix:col.suffix,
          defaultval:col.defaultval,
          selectOption: col.selectOption,
          rules: col.rules,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          maxLength: col.maxLength,
        };
      },
    };
  });

  if (modify || del) {
    mergedColumns.push(operation);
  }

  return (
    <>
      {modify &&  (
        <div style={{ textAlign: "left", marginBottom: 16 }}>
          {add &&<Button
            disabled={editingKey !== ""}
            icon={<PlusOutlined />}
            onClick={handleAdd}
            type="primary"
            style={{ marginRight: "10px" }}
          >
            新建
          </Button>}
          {isrowSelection==true&&<Button
            onClick={batchDel}
            disabled={editingKey !== ""}
            danger
            style={{ marginRight: "10px" }}
          >
            批量删除
          </Button>}
        </div>
      )}
      <Form form={form} component={false}>
        <Table
          rowKey={(record: any) => {
            return JSON.stringify(record);
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          size="middle"
          dataSource={data}
          rowSelection={isrowSelection==true&&{
            ...rowSelection,
          }}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </>
  );
};

export default EditTable;
