// src\redux\reducer\index.tsx
/*
 * Reducer: 数据处理
 */
import React from "react"
import { HomeOutlined } from '@ant-design/icons';
import { type } from "../action";

// let allRole = sessionStorage.getItem('allRole') ? JSON.parse(sessionStorage.getItem('detailId')) : [];
const initialState = {
    allRole: [],
    funList: [],
    tabs: {
        panes: [
            {
                key: '/home',
                title: React.cloneElement(<HomeOutlined style={{ fontSize: 16, marginRight: 0 }} />),
                closable: false
            }
        ],
        activeKey: "/home"
    },
    statusSonf: {},
    oss_conf: {}
};

const ebikeData = (state = initialState, action) => {
    switch (action.type) {
        case type.SWITCH_USERS:
            return {
                ...state, // 旧值
                userName: action.userName //新值
            };
        case type.SAVE_CONFIG:
            return {
                ...state, // 旧值
                allRole: action.params.all_role, //新值
                statusSonf: action.params.status_conf,
                oss_conf: action.params.oss_conf,
                funList: action.params.data
            };
        case type.SWITCH_TABS: {
            let { tabs } = state
            let { panes, activeKey } = tabs
            if (typeof action.params === "string") {
                activeKey = action.params
            } else {
                let index = panes.findIndex(item => {
                    return item.key === action.params.key
                })
                // 当前位置
                let current = panes.findIndex(item => {
                    return activeKey === item.key
                })
                if (index === -1) {
                    panes.splice(current + 1, 0, action.params)
                }
                activeKey = action.params.key
            }
            return {
                ...state,
                tabs: {
                    panes,
                    activeKey
                }
            }
        }
        case type.REFRESH_TABS: {
            return {
                ...state,
                tabs: {
                    ...action.params
                }
            }
        }
        default:
            return {
                ...state
            };
    }
};

export default ebikeData;
