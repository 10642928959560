import React, {useState, useEffect, useRef} from 'react'
import Request from './Request'
import {connect} from 'react-redux'
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  Col,
  Row,
  Select,
  Upload,
  Checkbox,
  Tabs,
  Dropdown,
  Menu,
  DatePicker,
  Pagination,
  PageHeader,
  Breadcrumb,
  Spin
} from 'antd'
import {DownOutlined, UpOutlined, InboxOutlined} from '@ant-design/icons'
import Utils from '../../../utils/utils'
import {ORDER_STATUS, PAY_METHOD} from '@src/config/statusConfig'
import {RouteComponentProps} from 'react-router-dom'
import {getExpressAll} from '@src/config/getAsyncConfig'
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table'
import {ListItem} from "./data"

import './index.less'

const {RangePicker} = DatePicker
const {Dragger} = Upload
const CheckboxGroup = Checkbox.Group
const {TextArea} = Input

interface IProps extends RouteComponentProps {
  dispatch: any
  allRole: any[]
}

const {TabPane} = Tabs
let exportsFilter: any = {}
const GoodStatus = [
  {
    title: '全部',
    param: {order_status: -1},
  },
  {
    title: '待发货',
    param: {
      order_status: 2,
    },
  },
  {
    title: '待收货',
    param: {
      order_status: 3,
    },
  },
  {
    title: '已确认收货',
    param: {
      order_status: 5,
    },
  },
  {
    title: '待退款',
    param: {
      order_status: 6,
    },
  },
  {
    title: '已退款',
    param: {
      order_status: 7,
    },
  },

]
const OrderList: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const ref = useRef<ActionType>();
  const [title, setTitle] = useState<string>('')
  const [visiblefh, setVisiblefh] = useState<boolean>(false)
  const [visibleImport, setVisibleImport] = useState<boolean>(false)
  const [tabKey, setTabKey] = useState<any>('0')
  const [goodList, setGoodList] = useState<any[]>([{good_title: '商品1'}])
  const [IsItem, setListItem] = useState<any>()
  const [total, setTotal] = useState<any>('0')
  const [expand, setExpand] = useState(false)
  const [searchKeys, setSearchKeys] = useState<string>('order_no')
  const [expressList, setExpressList] = useState<any[]>([])
  const [timeSlot, setTimeSlot] = useState<any[]>([])
  const [fileList, setFileList] = useState<any>([])
  const [spinning, setSpinning] = useState<any>(false)
  const [visibleAfter, setVisibleAfter] = useState<boolean>(false)
  const [afterValue, setAfterValue] = useState<any>('')

  const [searchParams, setSearchParams] = useState<any>({
    current: 1,
    pageSize: 10,
  })
  const [checkedList, setCheckedList] = React.useState([])
  const [timeType, setTimeType] = useState<any>({
    timeName: '下单时间',
    timeValue: 'created_at',
  })


  const error = (msg) => {
    Modal.error({
      title: '错误信息',
      content: msg,
      onOk() {
        setVisibleImport(!visibleImport)
      },
    })
  };
  const visibleModalImport = () => {
    setVisibleImport(!visibleImport)
  }

  const visibleModalImportOk = async () => {
    Request.importOrderUpdateData({data_json: JSON.stringify(fileList)}).then((res) => {
      if (res.data.error_count > 0) {
        error(res.msg);
      } else {
        setVisibleImport(!visibleImport)
      }
    });
  }
  useEffect(() => {
    getExpressAll().then((res) => {
      setExpressList(res.data.list)
    })
    func()
  }, [])
  //获取列表数据
  const getList = async () => {
    searchParams.order_status = GoodStatus[tabKey].param.order_status
    const res = await Request.getList(searchParams)
    setGoodList(res.data.list)
    let arr = []
    res.data.list.forEach((item: any) => {
      arr.push(item.order_id)
    })
    setTotal(res.data.count)
  }
  const changeParams = (key: any, value: any) => {
    let newData = searchParams
    newData[key] = value
    setSearchParams(newData)
  }
  //列表选择点击事件
  const onChange = (list) => {
    setCheckedList(list)
  }

  const visibleModalfh = () => {
    setVisiblefh(!visiblefh)
  }
  // 批量发货
  const UPLOAD_PROPS = {
    name: 'file',
    action: process.env.REACT_APP_API_URL + '/order/importOrder',
    headers: {
      authorization: 'authorization-text',
      token: Utils.getToken(),
    },
    showUploadList: false,
    onChange(info: any) {
      setSpinning(true)
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          setFileList(info.file.response.data.list)
          setSpinning(false)
        } else {
          message.error(info.file.response.msg)
          setSpinning(false)
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 上传失败`)
        setSpinning(false)
      }
    },
  }
  const toDel = (_, item, index) => {
    let newArr = fileList.filter((v, i) => i != index)
    setFileList(newArr)
  }
  const getMenu = (_: any, item: any, index: any) => {
    const {getMenuRole, menuRender} = Utils
    let menu = []
    menu.push(<a className="color-danger" onClick={() => {
      toDel(_, item, index)
    }}>移除</a>)
    return menuRender(menu)
  }
  const columns: ProColumns<ListItem>[] = [
    {
      title: "导入订单ID", width: 80, dataIndex: 'c_order_id', search: false, render: (_, record) => {
        if (record.c_order_id != record.order_id) {
          return <span className="color-danger">{record.order_id}</span>
        } else {
          return record.order_id
        }
      }
    },
    {
      title: "导入订单号", width: 80, dataIndex: 'c_order_no', search: false, render: (_, record) => {
        if (record.c_order_no !== record.order_no) {
          return <span className="color-danger">{record.order_no}</span>
        } else {
          return record.order_no
        }
      }
    },
    {
      title: "导入物流名称", width: 80, dataIndex: 'express_company_name', search: false, render: (_, record) => {
        if (record.express_company_name !== record.c_express_company_name) {
          return <span className="color-danger">{record.c_express_company_name}</span>
        } else {
          return record.c_express_company_name
        }
      }
    },
    {title: '导入物流单号', width: 80, dataIndex: 'express_no', search: false},
    {title: '识别物流编码', width: 80, dataIndex: 'c_express_code', search: false, align: "center"},
    {
      title: "订单状态", width: 80, dataIndex: 'order_status', search: false, render: (_, record) => {
        if (record.order_status != 2 || record.express_company_name !== record.c_express_company_name || record.c_order_no !== record.order_no || record.c_order_id != record.order_id) {
          return <span className="color-danger">不可导入</span>
        }
        return <span className="color-success">可导入</span>;
      }
    },
    {title: '操作', align: 'center', width: 80, fixed: 'right', dataIndex: 'id', render: getMenu, search: false}
  ]
  //发货
  const deliver = async (item: any, preview: boolean) => {
    form2.resetFields()
    visibleModalfh()
    setTitle('去发货')
    setListItem(item)
  }
  const onReset = () => {
    form2.resetFields()
  }
  const formSubmit = (formVal: any) => {
    formVal.order_id = IsItem.order_id
    Request.editOrderExpress(formVal).then(() => {
      visibleModalfh()
      func()
      message.success('操作成功')
    })
  }
  //查看物流详情
  const toexpressDetail = (id) => {
    const {history, dispatch} = props
    let path = `/order/order_express/${id}`
    history.push({
      pathname: path,
    })
  }
  const exportsDown = () => {
    let url = process.env.REACT_APP_API_URL + '/order/exportOrderList?token=' + Utils.getToken()
    exportsFilter = JSON.parse(JSON.stringify(searchParams))
    delete exportsFilter.current
    delete exportsFilter.pageSize
    if (checkedList.length > 0) exportsFilter.order_ids = checkedList.join(',')
    for (const key in exportsFilter) {
      if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
        const element = exportsFilter[key]
        url += `&${key}=${element}`
      }
    }
    window.open(url, '_blank')
  }
  const selectBefore = (
      <Select
          value={searchKeys}
          style={{width: 100}}
          onChange={(e) => {
            setSearchKeys(e)
          }}>
        <Select.Option value="order_no">订单号</Select.Option>
        <Select.Option value="mobile_purchase">手机号</Select.Option>
        <Select.Option value="address_receiver">收货姓名</Select.Option>
        <Select.Option value="address_mobile">收货电话</Select.Option>
        <Select.Option value="m_store_name">售卖店铺</Select.Option>
      </Select>
  )
  const menu = (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="created_at" title="下单时间">
          下单时间
        </Menu.Item>
        <Menu.Item key="paid_at" title="付款时间">
          付款时间
        </Menu.Item>
        <Menu.Item key="send_express_at" title="发货时间">
          发货时间
        </Menu.Item>
        <Menu.Item key="receive_at" title="签收时间">
          签收时间
        </Menu.Item>
      </Menu>
  )

  function handleMenuClick(e) {
    setTimeType({timeName: e.item.props.title, timeValue: e.key})
  }

  //查看详情
  const todetail = (id: string | number = 'new') => {
    window.open(`/#/order/order_detail/${id}`)
  }

  const tabsChange = (v) => {
    setTabKey(v)
    setSearchParams({
      current: searchParams.current,
      pageSize: searchParams.pageSize,
    })
    func()
  }
  //同步hooks
  const func = Utils.useSyncCallback(() => {
    getList()
  })
  //分页事件
  const pagingChange = (e, r) => {
    let obj = searchParams
    obj.current = e
    obj.pageSize = r
    setSearchParams(obj)
    getList()
  }
  const SearchSubmit = (obj: any) => {
    //时间
    if (timeSlot[0] && timeSlot[1]) {
      if (timeType.timeValue == 'created_at') {
        obj.start_time_created = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_created = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else if (timeType.timeValue == 'paid_at') {
        obj.start_time_paid = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_paid = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else if (timeType.timeValue == 'send_express_at') {
        obj.start_time_sended = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_sended = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else if (timeType.timeValue == 'receive_at') {
        obj.start_time_signed = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_signed = timeSlot[1].format('YYYY-MM-DD HH:mm')
      } else {
        obj.start_time_signed = timeSlot[0].format('YYYY-MM-DD HH:mm')
        obj.end_time_signed = timeSlot[1].format('YYYY-MM-DD HH:mm')
      }
    }
    obj.current = 1
    obj.pageSize = 10
    console.log(obj, searchParams)
    //订单金额
    if (searchParams.pay_good_fee_start && searchParams.pay_good_fee_end) {
      obj.pay_good_fee_start = searchParams.pay_good_fee_start
      obj.pay_good_fee_end = searchParams.pay_good_fee_end
    } else if (
        searchParams.pay_good_fee_start ||
        searchParams.pay_good_fee_end
    ) {
      message.error('请输入完整的订单金额区间')
      return {
        data: [],
        success: false,
      }
    }
    //实付金额
    if (searchParams.pay_fee_start && searchParams.pay_fee_end) {
      obj.pay_fee_start = searchParams.pay_fee_start
      obj.pay_fee_end = searchParams.pay_fee_end
    } else if (searchParams.pay_fee_start || searchParams.pay_fee_end) {
      message.error('请输入完整的实付金额区间')
      return {
        data: [],
        success: false,
      }
    }
    setSearchParams(obj)
    func()
  }
  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20},
  }
  const viewLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
  }
  const viewLayout2 = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
  }
  const visibleAfterfh = () => {
    setVisibleAfter(!visibleAfter)
  }
  const afterSale = (item) => {
    setListItem(item)
    visibleAfterfh()
    form3.setFieldsValue({refuse_desc: ''})
  }
  const afterChange = (v) => {
    setAfterValue(v)
  }
  const formSubmit2 = (formVal: any) => {
    formVal.order_id = IsItem.order_id
    Request.refundOrder(formVal).then(() => {
      visibleAfterfh()
      func()
      message.success('操作成功')
    })
  }
  return (
      <>
        <PageHeader title="平台订单列表"
                    className="site-page-header"
                    breadcrumb={
                      <Breadcrumb>
                        <Breadcrumb.Item>订单</Breadcrumb.Item>
                        <Breadcrumb.Item>平台订单</Breadcrumb.Item>
                      </Breadcrumb>
                    }
                    footer={
                      <Tabs defaultActiveKey="0" onChange={tabsChange}>
                        {GoodStatus.map((statusItem: any, index: number) => {
                          return (<TabPane closable={false} tab={statusItem.title} key={index}/>)
                        })}
                      </Tabs>}/>
        <div className="wrapContent waterwrap">
          <div className="wrap mb16">
            <Form
                {...formItemLayout}
                requiredMark={false}
                onFinish={SearchSubmit}
                form={form}>
              <Row>
                <Col span={6}>
                  <Form.Item labelCol={{span: 0}} wrapperCol={{span: 22}} label="" name={searchKeys}>
                    <Input addonBefore={selectBefore}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item labelCol={{span: 0}} wrapperCol={{span: 22}} label="" name="time">
                    <div style={{display: 'flex'}}>
                      <Dropdown overlay={menu} trigger={['click']}>
                        <Button onClick={(e) => e.preventDefault()}>
                          {timeType.timeName} <DownOutlined/>
                        </Button>
                      </Dropdown>
                      <RangePicker
                          showTime={{format: 'HH:mm'}}
                          format="YYYY-MM-DD HH:mm"
                          value={[timeSlot[0], timeSlot[1]]}
                          onChange={(d) => {
                            d ? setTimeSlot([d[0], d[1]]) : setTimeSlot([])
                          }}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item {...viewLayout2} label="商品名" name="goods_name">
                    <Input placeholder="请输入商品搜索"></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button type="primary" htmlType="submit">查询</Button>
                  <Button style={{margin: '0 8px'}} onClick={() => {
                    form.resetFields()
                    setTimeSlot([])
                    setSearchParams({
                      current: 1,
                      pageSize: searchParams.pageSize,
                    })
                    func()
                  }}>
                    重置
                  </Button>
                  <a onClick={() => {
                    setExpand(!expand)
                  }}>
                    {expand ? (<>收起<UpOutlined/></>) : (<>展开<DownOutlined/></>)}
                  </a>
                </Col>

              </Row>
              {expand ? (
                  <Row>
                    <Col span={6}>
                      <Form.Item{...viewLayout2} label="实付金额" name="order_source4">
                        <Input
                            type="number"
                            style={{width: 80}}
                            defaultValue={searchParams.pay_fee_start}
                            onChange={(e) => {
                              changeParams('pay_fee_start', e.target.value)
                            }}
                        />
                        &nbsp; - &nbsp;
                        <Input
                            type="number"
                            style={{width: 80}}
                            defaultValue={searchParams.pay_fee_end}
                            onChange={(e) => {
                              changeParams('pay_fee_end', e.target.value)
                            }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
              ) : (
                  ''
              )}
            </Form>
          </div>
          <div className="wrap">
            <div className="mb16">
              <div className="mb16">
                {(Utils.getMenuRole("order/exportOrderList", props.allRole) ? (
                    <Button type="primary" key="order/exportOrderList" onClick={exportsDown}>导出</Button>) : '')}
                {(Utils.getMenuRole("order/importOrderUpdate", props.allRole) ? (
                    <Button className="ml15" onClick={() => visibleModalImport()}>批量发货</Button>) : '')}
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://ewhjx.oss-cn-beijing.aliyuncs.com/upload/%E5%BF%AB%E9%80%92%E7%BC%96%E7%A0%81.xlsx">快递编码下载</a>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://ewhjx.oss-cn-beijing.aliyuncs.com/upload/%E5%AF%BC%E5%85%A5excel%E6%A8%A1%E7%89%88.xlsx">导入excel模版下载</a>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="font5 colorB">(导入数据只读取到D列，D列之后的数据不再读取。行数据结束之后，不要有多余数据、空格、调试行高)</span>
              </div>
            </div>
            <Row className="tabtr">
              <Col span={9}>商品信息</Col>
              <Col span={3}>支付信息</Col>
              <Col span={9}>配送信息</Col>
              <Col span={3}>操作</Col>
            </Row>
            <div className="goodList">
              <CheckboxGroup value={checkedList} onChange={onChange}>
                {goodList.map((item: any, index: any) => {
                  return (
                      <div className="good" key={index + item.order_no}>
                        <Row className="goodhead">
                          <Col span={3}>手机号：{item.mobile}</Col>
                          <Col span={4}>订单号：{item.order_no}</Col>
                          <Col span={5}>下单时间：{item.created_at}</Col>
                          <Col className="textcenter" span={3}>订单状态： {ORDER_STATUS[item.order_status]}</Col>
                        </Row>
                        <Row className="goodcontent">
                          <Col className="goodmsg" span={9}>
                            <div className="content">
                              <img src={item.good_main_media_url} alt="" style={{height: '100px', marginTop: "12px"}}/>
                              <div>
                                <div className="good-title" title={item.good_title}>{item.good_title}</div>
                                <div>规格:{item.spec_title}</div>
                                <div>
                                  <span style={{color: 'red', marginRight: '20px'}}>{item.pay_unit_fee}</span>
                                  <span>X&nbsp;{item.purchase_count}</span>
                                </div>
                                <div className="font5">售卖店铺：{item.m_store_name}</div>
                                <div className="font5">发货店铺：平台</div>
                                <div className="font5">
                                  商家冻积分：{item.lock_store_integral}
                                  &nbsp;&nbsp;&nbsp;
                                  用户冻积分：{item.lock_integral}
                                  &nbsp;&nbsp;&nbsp;
                                  状态：{item.is_sh == 1 ? '已发放' :  (item.order_status == 7 ? '已退款' : '未发放')}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col span={3}>
                            <div>
                              <div style={{color: 'red'}}>实付款:¥{item.pay_fee}</div>
                              <div className="font5">订单总金额:{item.pay_fee_all}</div>
                              <div className="font5">支付方式:{PAY_METHOD[item.pay_method]}</div>
                              <div style={{color: 'green'}} className="font5">绿色积分:{item.green_integral}</div>
                              <div style={{color: '#fe6673'}} className="font5">幸福值:{item.happy_integral}</div>
                            </div>
                          </Col>
                          <Col className="goodmsg" span={9}>
                            <div className="content">
                              <img
                                  src={require('../../../resources/assets/icon4.png')}
                                  alt=""
                                  style={{
                                    width: '25px',
                                    marginRight: '10px',
                                    height: '25px',
                                  }}
                              />
                              <div>
                                <div>收货人：{item.address_receiver}</div>
                                <div>收货人电话：{item.address_mobile}</div>
                                <div>收货地址：{item.province_name} {item.city_name} {item.district_name} {item.address_detail}</div>
                              </div>
                            </div>
                          </Col>
                          <Col span={3}>
                            {(Utils.getMenuRole("order/getStoreOrderInfo", props.allRole) ? (
                                <a className="mr10" onClick={() => {
                                  todetail(item.order_id)
                                }}>查看</a>) : '')}

                            {
                              item.order_status >= 3 &&
                              item.order_status != 9 &&
                              item.refund_order_status != 2 &&
                              ((Utils.getMenuRole("order/getExpress", props.allRole) ? (
                                <a className="mr10" onClick={() => {
                                  toexpressDetail(item.order_id)
                                }}>物流追踪</a>) : ''))}
                            {item.order_status == 2 && ((Utils.getMenuRole("order/orderDelivery", props.allRole) ? (
                                <a className="mr10" onClick={() => {
                                  deliver(item, true)
                                }}>去发货</a>) : ''))}
                            {item.order_status == 6 && (
                                <a className=" mr10" onClick={() => {
                                  afterSale(item)
                                }}>
                                  处理退款
                                </a>
                            )}
                          </Col>
                        </Row>
                        <div className="goodbottom ellipsis">{' '}
                          备注：{item.order_remark}
                        </div>
                      </div>
                  )
                })}
              </CheckboxGroup>
            </div>
            <div className="page mb16 mt15">
              <Pagination
                  total={total}
                  showSizeChanger
                  current={searchParams.current}
                  defaultCurrent={1}
                  onChange={pagingChange}
                  showQuickJumper
                  showTotal={(total) => `第 1-${total} 条/总共 ${total} 条`}
              />
            </div>
          </div>
        </div>
        <Modal
            destroyOnClose
            title="导入订单"
            visible={visibleImport}
            onCancel={visibleModalImport}
            onOk={visibleModalImportOk}
            width={1200}>
          {fileList.length == 0 ? <div style={{padding: '0 20% 40px 20%'}}>
            <Spin spinning={spinning}/>
            <Dragger {...UPLOAD_PROPS}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined/>
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
              <p className="ant-upload-hint">
                支持扩展名：.excel，.XLS，.XLSX...
              </p>
            </Dragger>
          </div> : <>
            <Button type="primary" onClick={() => {
              setFileList([])
            }}>重新选择</Button>
            <ProTable<ListItem>
                headerTitle="导入订单发货识别校验列表"
                rowKey="order_id"
                columns={columns}
                scroll={{x: 300}}
                actionRef={ref}
                search={false}
                dataSource={fileList}
                options={{density: true, fullScreen: false, reload: false, setting: false}}
            /></>}
        </Modal>
        <Modal
            destroyOnClose
            title={title}
            visible={visiblefh}
            onCancel={visibleModalfh}
            footer={false}
            width={500}>
          <Form
              {...viewLayout}
              requiredMark={false}
              onFinish={formSubmit}
              form={form2}>
            <Row>
              <Col span={24}>
                <Form.Item label="订单号">{IsItem?.order_no}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="付款时间">{IsItem?.created_at}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="收货人姓名">
                  {IsItem?.address_receiver}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="收货人电话">{IsItem?.address_mobile}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    label="收货地址">{IsItem?.province_name} {IsItem?.city_name} {IsItem?.district_name} {IsItem?.address_detail}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    label="物流公司"
                    name="express_company_id"
                    rules={[{required: true}]}>
                  <Select
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      placeholder="请选择">
                    {/* {Utils.getOptionList(expressList, "express_id", "material_name")} */}

                    {(() => {
                      return expressList.map((item) => {
                        return (
                            <Select.Option
                                key={item.express_id}
                                label={item.express_name}
                                value={item.express_id}>
                              {item.express_name}
                            </Select.Option>
                        )
                      })
                    })()}
                  </Select>
                </Form.Item>
                <Form.Item
                    label="物流单号"
                    name="express_no"
                    rules={[{required: true}]}>
                  <Input placeholder="请输入物流单号"/>
                </Form.Item>
                <Form.Item {...{wrapperCol: {offset: 3}}}>
                  <Button type="primary" htmlType="submit">立即提交</Button>
                  <Button className="resetbtn" onClick={onReset}>重置</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
            destroyOnClose
            title="退款"
            visible={visibleAfter}
            onCancel={visibleAfterfh}
            footer={false}
            width={500}>
          <Form
              {...viewLayout}
              requiredMark={false}
              onFinish={formSubmit2}
              form={form2}>
            <Form.Item
                label="处理方式"
                name="order_status"
                rules={[{required: true}]}>
              <Select
                  onChange={afterChange}
                  optionFilterProp="label"
                  placeholder="请选择">
                <Select.Option key={1} value={1}>
                  退款
                </Select.Option>
                <Select.Option key={2} value={2}>
                  驳回
                </Select.Option>
              </Select>
            </Form.Item>
            {afterValue == 2 && (''
                // <Form.Item
                //     label="驳回理由"
                //     name="refuse_desc"
                //     rules={[{required: true}]}>
                //   <TextArea rows={4} placeholder="请输入驳回理由"/>
                // </Form.Item>
            )}
            {afterValue == 1 && (
                <span>
                  <Form.Item label="退款金额">{IsItem.pay_fee}元</Form.Item>
                  <Form.Item label="退款绿色积分">{IsItem.green_integral}元</Form.Item>
                  <Form.Item label="退款幸福值">{IsItem.happy_integral}元</Form.Item>
                </span>
            )}
            <Form.Item {...{wrapperCol: {offset: 3}}}>
              <Button type="primary" htmlType="submit">
                确认
              </Button>
              <Button className="resetbtn" onClick={visibleAfterfh}>
                取消
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
  )
}

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole,
  }
}

export default connect(mapStateToProps)(OrderList)
