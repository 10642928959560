import React, {useEffect, useRef, useState,} from 'react';
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {
  Button,
  message,
  Modal,
  Tabs,
  PageHeader,
  TreeSelect,
  Select,
  Breadcrumb, Form, Row, Col, Input, Tooltip, Image, Divider,
} from 'antd';
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import Utils from "../../../utils/utils"
import {ListItem} from "./data"
import Skeleton from '../../../components/Skeleton';
import {STORE_STATUSY, iS_FREEZE, GOOD_STATUSY} from "@src/config/statusConfig";
import {getCategoryAll} from '@src/config/getAsyncConfig';
import {MonitorOutlined} from '@ant-design/icons'

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any
}

let TEMP_CHECK_ROW: any[] = [];
let exportsFilter: any = {};
const GoodStatus = [
  {title: "待审核", param: {status: 0}},
  {title: "已审核", param: {status: 1}},
  {title: "已驳回", param: {status: 2}},
];
let categoryList: any[] = []

const ActivityshareGoodList: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  const [tabKey, setTabKey] = useState<any>("0");
  const [editvisible, setEditVisible] = useState<boolean>(false);
  const [IsItem, setIsItem] = useState<ListItem>();
  const [form] = Form.useForm()
  const [categoryName, SetCategoryName] = useState<any>()
  const [visible2, setVisible2] = useState<boolean>(false);
  const [adminDescValue, setAdminDescValue] = useState<string>('平台下架');
  const [goodVisible, setGoodVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [storeInfo, setStoreInfo] = useState<any>({});
  const [auditDesc, setAuditDesc] = useState<boolean>(false);


  const redref = useRef<ActionType>();

  useEffect(() => {
    getCategoryAll().then(res => {
      categoryList = res.data
    });
    //获取标签
  }, [])

  const tabChange = (key: any) => {
    setTabKey(key)
    TEMP_CHECK_ROW = [];
    ref.current.reset();
  }

  //操作按钮
  const getMenu = (_: any, item: ListItem) => {
    const {allRole} = props
    const {getMenuRole, menuRender} = Utils
    let menu = []
    if (item.status === 0) {
      if (getMenuRole("store/auditStatus", allRole)) {
        menu.push(<a className="color-danger" onClick={() => {
          view(item);
        }}>审核</a>)
      }
    } else {
      if (getMenuRole("store/updateStoreFreeze", allRole)) {
        menu.push(<a className={item.is_freeze == 0 ? 'color-warning' : 'color-primary'} onClick={() => {
          updateStoreFreeze({store_id: item.store_id, is_freeze: item.is_freeze})
        }}>
          <Tooltip
              title="冻结和解冻是对用户资金操作，冻结之后用户不能操作任何资金动作，包括商家锁仓，同时，任何锁定积分不再释放。不影响用户正常登录和微信支付宝付款下单！">{item?.is_freeze == 0 ? '冻结' : '解冻'}</Tooltip>
        </a>)
      }
    }

    if (getMenuRole("store/updateStore", allRole)) {
      menu.push(<a className="color-success" onClick={() => {
        edit(item);
      }}>编辑</a>)
    }
    if (getMenuRole("store/getStoreGoodList", allRole)) {
      menu.push(<a className={item.good_status === 1 ? "color-warning" : "color-success"} onClick={() => {
        getStoreGoodList(item)
      }}>商品</a>)
    }
    if (getMenuRole("store/updateStoreAdminStatus", allRole)) {
      menu.push(<a className={item.admin_status === 1 ? "color-warning" : "color-success"} onClick={() => {
        updateStoreAdminStatus(item)
      }}>{item.admin_status === 1 ? "下架" : "上架"}</a>)
    }
    if (getMenuRole("store/deleteStore", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item)
      }}>删除</a>)
    }
    if (getMenuRole("store/getStoreList", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        view(item);
      }}>查看</a>)
    }
    return menuRender(menu)
  }


  const view = async (item) => {
    setVisible(true)
    setStoreInfo(item)
  }

  //编辑店铺
  const edit = (item: any) => {
    setIsItem(item)
    SetCategoryName(item.category_name)
    setEditVisible(true);
  }

  //上下架店铺
  const updateStoreAdminStatus = (item: ListItem) => {
    if (item.admin_status === 1) {
      setVisible2(!visible2)
      setIsItem(item)
    } else {
      Modal.confirm({
        title: "你确定要上架店铺商品吗?",
        okType: 'danger',
        onOk() {
          Request.updateStoreAdminStatus({
            store_id: item.store_id,
            admin_status: 1
          }).then(() => {
            ref.current!.reload()
            message.success("操作成功")
          })
        }
      })
    }
  }

  const deleteData = (item: ListItem) => {
    Modal.confirm({
      title: "你确定要删除数据吗?",
      okType: 'danger',
      onOk() {
        Request.deleteStore({store_id: item.store_id}).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }

  //商品
  const getStoreGoodList = (item) => {
    setIsItem(item)
    setGoodVisible(true)
  }

  const updateStoreFreeze = (item) => {
    item.is_freeze = item.is_freeze == 0 ? 1 : 0
    Modal.confirm({
      title: "你确定要冻结/解冻【" + item.store_id + "】用户吗?",
      okType: 'danger',
      content: '冻结和解冻是对用户资金操作，冻结之后用户不能操作任何资金动作，同时，任何锁定积分不再释放。不影响用户正常登录和微信支付宝付款下单！',
      onOk() {
        Request.updateStoreFreeze(item).then(() => {
          ref.current!.reload()
          message.success("操作成功")
        })
      }
    })
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', width: 40, dataIndex: 'store_id', search: false, fixed: 'left'},
    {title: '关键词', width: 1, dataIndex: 'q', hideInTable: true},
    {title: '会员昵称', width: 100, dataIndex: 'nickname', search: false},
    {title: '会员手机号', align: 'center', width: 80, dataIndex: 'mobile', search: false},
    {title: '报单级别', align: 'center', width: 50, dataIndex: 'level', search: false, render: (_, record) => {
        return record.level + '星'
      }},
    {title: '店铺名称', width: 120, dataIndex: 'store_name', search: false},
    {
      title: '店铺头像', width: 80, search: false, align: "center", render: (_, record) => {
        return <img src={record.store_image} alt="" style={{height: "50px"}}/>
      }
    },
    {
      title: "分类",
      width: 10,
      dataIndex: "category_id",
      hideInTable: true,
      renderFormItem: () => {
        return (
            <TreeSelect allowClear treeData={categoryList} placeholder="请选择"/>
        );
      },
    },
    {title: '联系电话', width: 80, dataIndex: 'phone', search: false},
    {title: '分类', width: 80, dataIndex: 'category_name', search: false},
    {title: '店铺余额', width: 80, dataIndex: 'store_balance', search: false},
    {title: '审核状态', width: 60, dataIndex: 'status', valueEnum: STORE_STATUSY, search: false,},
    {
      title: '营业时间', width: 110, dataIndex: 'updated_at', search: false, align: "center", render: (_, record) => {
        return <span> {record.business_hours_start}-{record.business_hours_end}</span>
      }
    },
    {title: '冻结状态', width: 60, dataIndex: 'is_freeze', valueEnum: iS_FREEZE, search: true,},
    {
      title: '后台状态', width: 60, dataIndex: 'admin_status', search: false, render: (_, record) => {
        return record.admin_status == 1 ? <span className="color-success">上架中</span> :
            <Tooltip title={record.admin_desc}><MonitorOutlined/><span className="color-danger">已下架#</span></Tooltip>;
      }
    },
    //
    {title: '添加时间', width: 110, dataIndex: 'created_at', search: false, align: "center"},
    {title: '操作', align: 'center', fixed: 'right', width: 100, dataIndex: 'id', search: false, render: getMenu},
  ];

  const editvisibleModal = () => {
    setEditVisible(!editvisible);
  }

  const editStoreCategory = async (item: any) => {
    item.store_id = IsItem.store_id
    item.category_name = categoryName
    Request.updateStore(item).then(() => {
      message.success("修改成功")
      ref.current!.reload()
      editvisibleModal()
    })
  }
  const selectVla = (v, s) => {
    SetCategoryName(s.children)
  }

  const visibleModal2 = () => {
    setVisible2(!visible2)
  }

  const dowOnOk = () => {
    Modal.confirm({
      title: "你确定要下架店铺商品吗?",
      okType: 'danger',
      onOk() {
        Request.updateStoreAdminStatus({
          store_id: IsItem.store_id,
          admin_status: IsItem.admin_status === 1 ? 2 : 1,
          admin_desc: adminDescValue,
        }).then(() => {
          ref.current!.reload()
          message.success("操作成功")
          setVisible2(false)
        })
      }
    })
  }
  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20}
  }
  const formSubmit = (formVal: any) => {
    const callback = () => {
      message.success("操作成功")
      ref.current!.reload()
    }
    formVal.store_id = IsItem.store_id
    Request.updateStoreAdminStatus(formVal).then(callback)
  }

  const formSubmit2 = (formVal: any) => {
    const callback = () => {
      message.success("操作成功")
      setVisible(false)
      ref.current!.reload()
    }
    formVal.store_id = storeInfo.store_id
    Request.auditStatus(formVal).then(callback)
  }
  const adminDesc = (v) => {
    setAdminDescValue(v.target.value)
  }
  const redModalcolumns = [
    {title: 'ID', width: 60, dataIndex: 'store_good_id', search: false},
    {title: '商品名称', width: 120, dataIndex: 'good_title', search: false},
    {
      title: '商品主图', width: 80, search: false, render: (_, record) => {
        return <img src={record.good_main_media_url} alt="" style={{width: "50px", height: "50px"}}/>
      }
    },
    {title: '总库存', width: 80, dataIndex: 'all_spec_stock', search: false},
    {title: '最小规格价', width: 80, dataIndex: 'min_spec_price', search: false},
    {title: '最小规格原价', width: 80, dataIndex: 'min_spec_original_price', search: false},
    {title: '最小规格成本价', width: 80, dataIndex: 'min_spec_cost_price', search: false},
    {title: '销量', width: 80, dataIndex: 'sale_volume', search: false},
    {
      title: '审核状态', width: 70, dataIndex: 'audit_status', search: false, render: (_, record) => {
        if (record.audit_status == 0) {
          return <span className="color-danger">待审核</span>;
        } else if (record.audit_status == 1) {
          return <span className="color-success">审核成功</span>;
        } else if (record.audit_status == 2) {
          return <span className="color-danger">审核失败</span>;
        }
        return '-';
      }
    },
    {
      title: '后台状态', width: 70, dataIndex: 'admin_status', search: false, render: (_, record) => {
        if (record.admin_status == 1) {
          return <span className="color-success">上架中</span>;
        } else {
          return <span className="color-danger">已下架</span>;
        }
      }
    },
    {
      title: '店铺状态', width: 70, dataIndex: 'good_status', search: false, render: (_, record) => {
        if (record.good_status == 1) {
          return <span className="color-success">上架中</span>;
        } else {
          return <span className="color-danger">已下架</span>;
        }
      }
    },
    {
      title: '公域池', width: 50, dataIndex: 'good_status', search: false, render: (_, record) => {
        if (record.level5_store_id == record.store_id) {
          if (record.is_upload_public == 1) {
            return '是';
          }
          return '否'
        }
        return '-';
      }
    },
    {
      title: '商品类型', width: 90, dataIndex: 'is_upload_public', search: false, render: (_, record) => {
        if (record.good_id > 0) {
          if (record.level5_store_id > 0) {
            return '拉取五星店主商品';
          } else {
            return '拉取平台库商品';
          }
        } else {
          return '店铺商品';
        }
      }
    },
    {title: '状态', width: 60, dataIndex: 'good_status', valueEnum: GOOD_STATUSY, search: false,},
    {title: '添加时间', width: 110, dataIndex: 'created_at', search: false,},
  ];


  const visibleModal = () => {
    setVisible(!visible)
  }

  //审核选择
  const typeChange = (v) => {
    if (v == 2) {
      setAuditDesc(true)
    } else {
      setAuditDesc(false)
    }
  }

  return (
      <>
        <Skeleton loading={false}>
          <PageHeader
              title='店铺列表'
              className="site-page-header"
              breadcrumb={<Breadcrumb><Breadcrumb.Item>店铺</Breadcrumb.Item>
                <Breadcrumb.Item>店铺管理</Breadcrumb.Item></Breadcrumb>}
              footer={
                <Tabs defaultActiveKey="0" onChange={tabChange}>
                  {GoodStatus.map((statusItem: any, index: number) => {
                    return (<TabPane closable={false} tab={statusItem.title} key={index}></TabPane>);
                  })}
                </Tabs>
              }
          >
          </PageHeader>

          <div className="wrapContent">
            <ProTable<ListItem>
                rowKey="store_id"
                tableStyle={{padding: '0 24px 0 24px'}}
                columns={columns}
                actionRef={ref}
                scroll={{x: 2000}}
                request={async (params: any) => {
                  params = Object.assign(
                      JSON.parse(JSON.stringify(GoodStatus[+tabKey].param)),
                      params
                  );
                  exportsFilter = JSON.parse(JSON.stringify(params));
                  const res = await Request.getList(params);
                  return {
                    data: res.data.list,
                    total: res.data.count,
                    success: true,
                    pageSize: params.pageSize,
                    current: params.pageSize
                  }
                }
                }
                pagination={{
                  defaultPageSize: 20,
                }}
            />
          </div>
        </Skeleton>
        <Modal
            destroyOnClose
            title={'编辑'}
            visible={editvisible}
            onCancel={editvisibleModal}
            footer={false}
            width={800}
        >
          <Form requiredMark={false} form={form} initialValues={{...IsItem}} onFinish={editStoreCategory}>

            <Form.Item labelCol={{span: 3}} label="店铺名称" name="store_name" rules={[{required: true}]}>
              <Input placeholder="请输入店铺名"/>
            </Form.Item>

            <Form.Item labelCol={{span: 3}} label="分类" name="category_id" rules={[{required: true}]}>
              <Select allowClear showSearch optionFilterProp="children" placeholder="请选择" onChange={selectVla}>
                {Utils.getOptionList(categoryList, 'category_id', 'category_name')}
              </Select>
            </Form.Item>

            <Row>
              <Col span={24} className="text-center">
                <Button type="primary" htmlType="submit">保存</Button>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
            destroyOnClose
            title="下架"
            visible={visible2}
            onCancel={visibleModal2}
            onOk={dowOnOk}
            width={800}
        >
          <Form{...formItemLayout} requiredMark={false} form={form} onFinish={formSubmit}>
            <Row>
              <Col span={24}>
                <Form.Item label="下架原因" labelCol={{span: 2, offset: 1}} name="admin_desc" rules={[{required: true}]}>
                  <Input.TextArea placeholder="请输入下架原因 " defaultValue="平台下架" onChange={adminDesc} rows={5}/>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Modal>
        <Modal
            destroyOnClose
            title="商品列表"
            visible={goodVisible}
            onCancel={() => setGoodVisible(!goodVisible)}
            footer={false}
            width={1300}>

          <ProTable
              rowKey="store_id"
              tableStyle={{padding: '0 24px 0 24px'}}
              columns={redModalcolumns}
              actionRef={redref}
              search={false}
              scroll={{x: 2000}}
              request={async (params: any) => {
                params.store_id = IsItem.store_id
                const res = await Request.getStoreGoodList(params);
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.pageSize
                }
              }
              }
              toolBarRender={false}
              pagination={{
                defaultPageSize: 10,
              }}
              options={{density: false, fullScreen: false, reload: false, setting: false}}
          />

        </Modal>
        <Modal
            destroyOnClose
            title='商品详情'
            visible={visible}
            onCancel={visibleModal}
            footer={false}
            width={1000}
        >
          <Row>
            <Col span={8}>
              <Form.Item label="会员昵称">{storeInfo.nickname}</Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="会员手机号" labelAlign="right">{storeInfo.mobile}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报单级别">{storeInfo.level}星</Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="店铺名称">{storeInfo.store_name}</Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="联系方式" labelAlign="right">
                {storeInfo.phone}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="姓名" labelAlign="right">
                {storeInfo.owners_name}
              </Form.Item>
            </Col>


          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="身份证号" labelAlign="right">
                {storeInfo?.id_card}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="店铺分类" labelAlign="right">
                {storeInfo?.category_name}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Form.Item label="经营地址" labelAlign="right">
                {storeInfo.province_name + storeInfo.city_name + storeInfo?.district_name + storeInfo.address_detail}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="店铺头像" labelAlign="right">
                <Image.PreviewGroup>
                  <Image key='1' width={140} style={{padding: "0 10px 0 0"}} src={storeInfo.store_image}/>
                </Image.PreviewGroup>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="营业执照" labelAlign="right">
                <Image.PreviewGroup>
                  <Image key='2' width={140} style={{padding: "0 10px 0 0"}} src={storeInfo.business_license}/>
                </Image.PreviewGroup>
              </Form.Item>
            </Col>
            {storeInfo.food_license &&
            <Col span={8}>
                <Form.Item label="食品经营许可证" labelAlign="right">
                    <Image.PreviewGroup>
                        <Image key='3' width={140} style={{padding: "0 10px 0 0"}} src={storeInfo.food_license}/>
                    </Image.PreviewGroup>
                </Form.Item>
            </Col>
            }
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="后台店铺状态" labelAlign="right">
                {storeInfo?.admin_status == 1 ? '上架中' : "下架中"}
              </Form.Item>
            </Col>
            {storeInfo.admin_status == 2 &&
            <Col span={14}>
                <Form.Item label="后台下架原因" labelAlign="right">
                    <span className="color-danger">{storeInfo?.admin_desc}</span>
                </Form.Item>
            </Col>
            }
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item label="店铺审核状态" labelAlign="right">
                {storeInfo.status == 0 ? '未审核' : (storeInfo.status == 1 ? <span className="color-success">已通过</span> :
                    <span className="color-primary">已驳回</span>)}
              </Form.Item>
            </Col>
            {storeInfo?.status == 2 &&
            <Col span={14}>
                <Form.Item label="驳回原因" labelAlign="right">
                    <span className="color-danger">{storeInfo?.fail_desc}</span>
                </Form.Item>
            </Col>
            }
          </Row>
          {storeInfo?.status == 0 &&
          <Form{...formItemLayout} requiredMark={false} form={form} onFinish={formSubmit2}>
              <Row>
                  <Col span={24}>
                      <Form.Item label="审核选择" labelCol={{span: 2, offset: 1}} name="status"
                                 rules={[{required: true}]}>
                          <Select placeholder="请选择审核状态" style={{width: "300px"}} onChange={typeChange}>
                              <Select.Option value={1}>通过</Select.Option>
                              <Select.Option value={2}>驳回</Select.Option>
                          </Select>
                      </Form.Item>

                  </Col>
              </Row>
            {auditDesc &&
            <Row>
                <Col span={24}>
                    <Form.Item label="驳回原因" labelCol={{span: 2, offset: 1}} name="fail_desc" rules={[{required: true}]}>
                        <Input.TextArea placeholder="请输入板块介绍 " rows={5}/>
                    </Form.Item>
                </Col>
            </Row>
            }
              <Form.Item {...{wrapperCol: {offset: 3}}}>
                  <Button type="primary" htmlType="submit">立即提交</Button>
              </Form.Item>
          </Form>
          }
        </Modal>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(ActivityshareGoodList)