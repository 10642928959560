import axios from "../../../axios"

function getList() {
    return axios.ajax({
        url: '/sys/get_func_list',
        method: "post",
        isShowLoading: false
    })
}

function add(params: any, level: number) {
    return axios.ajax({
        url:  "/sys/add_func",
        method: "post",
        data: params
    })
}

function edit(params: any, level: number) {
    return axios.ajax({
        url:  "/sys/edit_func",
        method: "post",
        data: params
    })
}

function deleteData(params: { id: number | string }, level: number) {
    return axios.ajax({
        url: "/sys/delete_func",
        method: "post",
        data: params
    })
}

export default {
    getList,
    add,
    edit,
    deleteData
}