import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { RouteComponentProps } from "react-router-dom";
import MyHeader from "./components/Header";
import NavLeft from "./components/NavLeft";
import './style/common.less'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
const { Sider, Content } = Layout;

interface IsProps extends RouteComponentProps {
  children?: any;
}

const Admin: React.FC<IsProps> = (props: IsProps) => {
  const [collapsed, setSollapsed] = useState<boolean>(false)

  useEffect(() => {
    // @ts-ignore
    window.reactHistory = props.history;
  }, [])

  const toggle = () => {
    setSollapsed(!collapsed)
  }

  return (
    <>
    <MyHeader toggle={
          React.createElement((collapsed ? MenuUnfoldOutlined : MenuFoldOutlined), { onClick: toggle })
        } />
    <Layout style={{ height: "calc(100vh - 55px)" }}>
      {/* <Header> */}
        
      {/* </Header> */}
      <Sider trigger={null} collapsible collapsed={collapsed} width="250px" >
        <NavLeft />
      </Sider>
      <Layout style={{ overflow: "hidden",overflowY: "scroll" }}>
        <Content style={{ position: "relative" }}>
          {props.children}
        </Content>
      </Layout>
    </Layout>
    </>
  )
}

export default Admin