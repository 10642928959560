import React, {useState, useEffect} from 'react';
import {Card, message} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import Utils from "../../../../utils/utils"
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import {connect} from 'react-redux';
import EditTable from "@src/components/myEditable";

interface IProps extends RouteComponentProps {
  allRole: any[]
}
const GeneralConfig: React.FC<IProps> = (props: IProps) => {
  const [listData, setListData] = useState<any>([])
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const initList = async () => {
    const res = await Request.getData()
    let {data} = res
    data.forEach((element: any) => {
      if (element.weight) element.weight = parseInt(element.weight)
    });
    let listdata = data.sort(compare('weight', false));
    setListData(listdata)
    setShowSkeleton(false)
  }
  const compare = (property: any, rev: any) => {
    //第二个参数没有传递 默认升序排列
    if (rev == undefined) {
      rev = 1;
    } else {
      rev = (rev) ? 1 : -1;
    }
    return function (a: any, b: any) {
      a = a[property];
      b = b[property];
      if (a < b) {
        return rev * -1;
      }
      if (a > b) {
        return rev * 1;
      }
      return 0;
    }
  }

  useEffect(() => {
    initList()
  }, [])
  const edit = Utils.getMenuRole("config/editGoodHotKeyword", props.allRole)
  return (
      <Card>
        <Skeleton loading={showSkeleton}>
          <EditTable
              edit={{modify: edit, add: true, del: edit,}}
              originData={listData}
              isrowSelection={false}
              onChange={(data) => {
                var lists = [...data.sort(compare('weight', false))]
                Request.edit({config_value: JSON.stringify(lists)}).then(res => {
                  message.success(res.msg)
                })
                setListData([])
                setListData(lists)
              }}
              columns={[
                {title: '名称', dataIndex: 'hot_keyword', width: '40%', editable: "TEXT"},
                {title: '权重', dataIndex: 'weight', width: '40%', editable: "TEXT"}
              ]
              }/>
        </Skeleton>
      </Card>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(GeneralConfig)