// src\redux\action\index.tsx
import axios from "../../axios"
import Utils from "../../utils/utils"
/*
* Action类型:用户事件操作 
*/

export const type = {
    SWITCH_USERS: 'SWITCH_USERS',
    SAVE_CONFIG: 'SAVE_CONFIG',
    SWITCH_TABS: 'SWITCH_TABS',
    REFRESH_TABS: 'REFRESH_TABS'
};

// 用户点击登录,切换用户名称
export function switchUsers(params) {
    Utils.setToken(params.token)
    axios.setToken(params.token)
    return {
        type: type.SWITCH_USERS,
        params
    }
}

// 保存权限列表
export function saveConfig(params) {
    return {
        type: type.SAVE_CONFIG,
        params
    }
}

// 添加tabs
export function switchTabs(params) {
    return {
        type: type.SWITCH_TABS,
        params
    }
}
// 删除tabs
export function refreshTabs(params) {
    return {
        type: type.REFRESH_TABS,
        params
    }
}