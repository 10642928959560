import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Card, message } from 'antd';
import EditTable from '../../../../components/Editable';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import Utils from "../../../../utils/utils"
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const AppForce: React.FC<IProps> = (props: IProps) => {

  const [listData, setListData] = useState<any>([])
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)

  const initList = async () => {
    const res = await Request.getList()
    let { data } = res
    let dataSource = []
    dataSource.push({
      ...data.ios
    })
    dataSource.push({
      ...data.android
    })
    setListData(dataSource)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initList()
  }, [])

  const edit = (data: any[]) => {
    data.forEach((item: any) => {
      delete item.key
      item.locking = item.locking ? 1 : 0
    })
    let config_value = {
      ios: data[0],
      android: data[1]
    }
    
    Request.edit({ config_value:JSON.stringify(config_value) }).then(() => {
      message.success("保存成功")
    })
  }

  const columns = [
    {
      title: '设备',
      width: 100,
      dataIndex: 'name'
    }, {
      title: '标题',
      width: 260,
      dataIndex: 'title',
      editable: "TEXT"
    }, {
      title: '更新内容',
      width: 260,
      dataIndex: 'content',
      editable: "TEXTAREA"
    }, {
      title: '下载地址',
      width: 260,
      dataIndex: 'download',
      editable: "TEXT",
      rules: false
    }, {
      title: '版本',
      width: 100,
      dataIndex: 'version',
      editable: "TEXT"
    }, {
      title: '版本编号',
      width: 100,
      dataIndex: 'version_code',
      editable: "TEXT"
    }, {
      title: 'app大小(M)',
      width: 120,
      dataIndex: 'apk_size',
      editable: "TEXT",
      rules: false
    }, {
      title: 'app名称',
      width: 150,
      dataIndex: 'apk_name',
      editable: "TEXT",
      rules: false
    }
  ]

  const editCol = Utils.getMenuRole("config/editConfigForceUpdate", props.allRole)

  return (
    <Card>
      <Skeleton loading={showSkeleton}>
        {listData.length > 0 && (
          <EditTable
            edit={{ modify: editCol, add: false, del: false }}
            originData={listData}
            columns={columns}
            onChange={(newData) => {
              edit(newData)
            }}
          />
        )}
      </Skeleton>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(AppForce);