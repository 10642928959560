import React, {useState, useRef} from 'react'
import Request from "./Request"
import {connect} from "react-redux";
import {Button, Breadcrumb, PageHeader, Modal, message, Tooltip} from 'antd';
import Utils from "../../../utils/utils"
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table'
import {ListItem} from "./data"
import {RouteComponentProps} from 'react-router-dom';
import {MEMBER_LEVEL, iS_FREEZE} from "@src/config/statusConfig";

interface IProps extends RouteComponentProps {
  allRole: any[],
  dispatch: any
}

let exportsFilter: any = {}
const UserList: React.FC<IProps> = (props: IProps) => {

  const ref = useRef<ActionType>();
  const {allRole} = props
  const [soter, setSoter] = useState<any>({})
  const toDetails = (id: string) => {
    window.open(`/#/user/detail/${id}`)
  };

  const getMenu = (_: any, item: any) => {
    const {getMenuRole, menuRender} = Utils
    let menu = []

    if (getMenuRole("member/updateMemberWalletFreeze", allRole)) {
      menu.push(<a className={item.is_freeze == 0 ? 'color-warning' : 'color-primary'} onClick={() => {
        updateStatus({mid:item.mid, is_freeze:item.is_freeze})
      }}>
        <Tooltip title="冻结和解冻是对用户资金操作，冻结之后用户不能操作任何资金动作，包括店铺资金，同时，任何锁定积分不再释放。不影响用户正常登录和微信支付宝付款下单！" >{item?.is_freeze == 0 ? '冻结' : '解冻'}</Tooltip>
      </a>)
    }

    if (getMenuRole("member/deleteMember", allRole)) {
      menu.push(<a className="color-danger" onClick={() => {
        deleteData(item?.mid)
      }}>删除</a>)
    }

    if (getMenuRole("member/getMemberInfo", allRole)) {
      menu.push(<a className="color-success" onClick={() => {
        toDetails(item?.mid)
      }}>详情</a>)
    }
    return menuRender(menu)
  }

  const updateStatus = (item) => {
    item.is_freeze = item.is_freeze == 0 ? 1 : 0
    Modal.confirm({
      title: "你确定要冻结/解冻【" + item.mid + "】用户吗?",
      okType: 'danger',
      content:'冻结和解冻是对用户资金操作，冻结之后用户不能操作任何资金动作，同时，任何锁定积分不再释放。不影响用户正常登录和微信支付宝付款下单！',
      onOk() {
        Request.updateStatus(item).then(() => {
          ref.current!.reload()
          message.success("操作成功")
        })
      }
    })
  }

  const deleteData = (mid: number) => {
    Modal.confirm({
      title: "你确定要删除【" + mid + "】吗?",
      okType: 'danger',
      onOk() {
        Request.deleteData(mid).then(() => {
          ref.current!.reload()
          message.success("删除成功")
        })
      }
    })
  }


  const exportsDown = () => {
    let url = process.env.REACT_APP_API_URL + '/member/exportMember?token=' + Utils.getToken()
    delete exportsFilter.current
    delete exportsFilter.pageSize
    for (const key in exportsFilter) {
      if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
        const element = exportsFilter[key];
        url += `&${key}=${element}`
      }
    }
    window.open(url, '_blank');
  }

  const columns: ProColumns<ListItem>[] = [
    {title: 'ID',align: 'center', width: 80, dataIndex: 'mid', search: false, fixed: 'left',},
    {title: '手机号',align: 'center', width: 100, dataIndex: 'mobile', search: false},
    {title: '昵称', width: 140, dataIndex: 'nickname', search: false},
    {title: '关键词', width: 0, dataIndex: 'q', search: true, hideInTable: true},
    {title: '上级手机号', align: 'center',width: 100, dataIndex: 'parent_mobile', search: false},
    {title: '余额', width: 100, dataIndex: 'balance', search: false},
    {title: '用户冻积分', width: 100, dataIndex: 'lock_integral', search: false},
    {title: '商家冻积分', width: 100, dataIndex: 'lock_store_integral', search: false},
    {title: '开通店主积分', width: 100, dataIndex: 'lock_customer_integral', search: false},
    {title: '绿色积分', width: 100, dataIndex: 'green_integral', search: false},
    {title: '幸福值', width: 100, dataIndex: 'happy_integral', search: false},
    {title: '星级别',align: 'center', width: 80, dataIndex: 'level', valueEnum: MEMBER_LEVEL},
    {title: '冻结状态',align: 'center', width: 80, dataIndex: 'is_freeze', valueEnum: iS_FREEZE},
    {
      title: '注册时间', width: 210, dataIndex: 'created_at', valueType: "dateTimeRange", hideInTable: true,
      render: (_, record) => record.created_at
    },
    {
      title: '邀请码',align: 'center', width: 100, dataIndex: 'is_auth_group', search: false, render: (_, record) => {
        return record.level > 0 ? record.invitation_number : <span className="color-danger">无报单</span>
      }
    },
    {
      title: '用户注册时间',align: 'center', width: 120, dataIndex: 'created_at', search: false, valueType: "dateTimeRange",
      render: (_, record) => record.created_at
    },
    {title: '操作', align: 'center', width: 120, fixed: 'right', dataIndex: 'id', render: getMenu, search: false}
  ]

  return (
      <>
        <PageHeader title='会员列表' className="site-page-header" extra=''
                    breadcrumb={<Breadcrumb><Breadcrumb.Item>会员管理</Breadcrumb.Item>
                      <Breadcrumb.Item>会员列表</Breadcrumb.Item></Breadcrumb>}></PageHeader>
        <div className="wrapContent">
          <ProTable<ListItem>
              rowKey="mid"
              columns={columns}
              scroll={{x: 2200}}
              actionRef={ref}
              // rowSelection={rowSelection}
              tableStyle={{padding: '0 24px 0 24px'}}
              onChange={(_: any, __: any, sorter: any) => {
                setSoter(sorter)
              }}
              request={async (params: any) => {
                if (params.created_at) {
                  params.start_time = params.created_at[0]
                  params.end_time = params.created_at[1]
                  delete params.created_at
                }
                if (params.balance) {
                  params.start_balance = params.balance[0] || ""
                  params.end_balance = params.balance[1] || ""
                  delete params.balance
                }
                if (params.red_envelope) {
                  params.red_envelope_start = params.red_envelope[0] || ""
                  params.red_envelope_end = params.red_envelope[1] || ""
                  delete params.red_envelope
                }
                if (params.integral) {
                  params.start_integral = params.integral[0] || ""
                  params.end_integral = params.integral[1] || ""
                  delete params.integral
                }
                params.order = soter.order
                params.field = soter.field
                exportsFilter = params
                const res = await Request.getList(params);
                return {
                  data: res.data.list,
                  total: res.data.count,
                  success: true,
                  pageSize: params.pageSize,
                  current: params.current
                }
              }
              }
              toolbar={{
                title: [(Utils.getMenuRole("member/exportMember", allRole) && (
                    <Button type="primary" key="member/exportMember" onClick={exportsDown}>导出</Button>))],
                filter: ''
              }}
              // options={{density: false, fullScreen: false, reload: false, setting: false}}
          />
        </div>
      </>
  );

}

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(UserList);