import axios from "../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/sys/get_admin_list',
        method: "post",
        data: params,
        isShowLoading: false
    })
}

function add(params: any) {
    return axios.ajax({
        url: "/sys/add_admin",
        method: "post",
        data: params
    })
}

function edit(params: any) {
    return axios.ajax({
        url: "sys/edit_admin",
        method: "post",
        data: params
    })
}

function deleteData(params: { admin_id: number | string }) {
    return axios.ajax({
        url: "/sys/delete_admin",
        method: "post",
        data: params
    })
}

export default {
    getList,
    add,
    edit,
    deleteData
}