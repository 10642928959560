import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  PageHeader,
  Breadcrumb,
  Row,
  Steps,
  Popover,
} from "antd";
import Request from "./Request";
import {RouteComponentProps} from "react-router-dom";
import {withAliveScope} from "react-activation";
import moment from "moment";
import Skeleton from '../../../components/Skeleton';
import utils from "../../../utils/utils";
import {PAY_METHOD, REFUND_YIBAO_STATUS} from '@src/config/statusConfig'

import './detail.less'
import Utils from "@src/utils/utils";

const {Step} = Steps;

interface IProps extends RouteComponentProps {
  allRole: any[];
  dispatch: any;
  tabs: any;
  dropScope: any;
}


const ActivityDetails: React.FC<IProps> = (props: IProps) => {
  //@ts-ignore
  let order_id = props?.match?.params?.id || "new";

  const [orderId] = useState<string | number>(order_id);
  const [orderData, setOrderData] = useState<any>(order_id);
  const [Hour, setHour] = useState<string | number>();
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)

  useEffect(() => {
    func()

  }, []);

  const func = utils.useSyncCallback(() => {
    Request.getActDetails({order_id: orderId}).then((res) => {
      let data = res.data
      if (data.pay_unit_fee && data.pay_good_fee) {
        data.pay_yh_fee = data.pay_good_fee - data.pay_unit_fee
      }
      setOrderData(data)
      if (data.order_status == 2) {
        let newtime = moment()
        let starttime = moment(data.created_at)
        let num = newtime.diff(starttime, 'hours')
        if (num > 0) {
          setHour(num)
        }
      }
      setShowSkeleton(false)
    });
  });

  //查看物流详情
  const todetail = () => {
    const {history, dispatch} = props;
    let path = `/order/store_order_express/${orderId}`;
    history.push({
      pathname: path,
    });
  };
  const customDot = (dot, {status, index}) => (
      <Popover
          content={
            <span>
          step {index} status: {status}
        </span>
          }
      >
        {dot}
      </Popover>
  );

  const formItemLayout = {
    labelCol: {span: 5, offset: 0},
    wrapperCol: {span: 9},
  };

  return (
      <div className="detailwrap">
        <Skeleton loading={showSkeleton}>
          <PageHeader
              breadcrumb={<Breadcrumb><Breadcrumb.Item>订单</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a>店铺订单</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>店铺订单详情</Breadcrumb.Item></Breadcrumb>}
              className="site-page-header-responsive mb15"
              title='店铺订单详情'
          >
          </PageHeader>
          <div className="wrapContent">
            <Card className="mb15">
              <Row className="font16">
                <Col className="mr20">用户昵称:{orderData.purchase_name}</Col>
                <Col className="mr20">手机号:{orderData.purchase_mobile}</Col>
                <Col className="mr20">订单编号:{orderData.order_no}</Col>
              </Row>
            </Card>
            <Card className="mb15">
              <Row>
                <Col span={8} className="border">
                  {(orderData?.order_status == 1 || orderData?.order_status == 2) && <div>
                      <div className="font26 font-weight text-center mb20">等待商户发货</div>
                      <div className="font20 text-center mb20"><span className="colorH">请尽快处理发货,</span><span
                          className="red">{Hour ? ('已过' + Hour + '小时未发货') : ''}</span></div>
                  </div>}
                  {(orderData?.order_status >= 3 && orderData?.order_status != 5 && orderData?.refund_order_status != 2) && <div>
                      <div className="font26 font-weight text-center mb20">商户已发货，等待收货</div>
                      <div className="font20 text-center mb20"><span className="colorH">若发现物流问题请及时修改物流信息保障用户权益,</span>
                      </div>
                      <div className="text-center">
                        {((Utils.getMenuRole("order/getExpress", props.allRole) ? (
                            <Button type="primary" onClick={() => {
                              todetail()
                            }}>查看物流</Button>) : ''))}
                      </div>
                  </div>
                  }
                  {orderData?.order_status == 5 && <div>
                      <div className="font26 font-weight text-center mb20">买家已确认到货</div>
                      <div className="font20 text-center mb20"><span className="colorH">该笔订单已完成交易,</span></div>

                      <div className="text-center">
                        {((Utils.getMenuRole("order/getExpress", props.allRole) ? (
                            <Button type="primary" onClick={() => {
                              todetail()
                            }}>查看物流</Button>) : ''))}
                      </div>
                  </div>}
                </Col>
                <Col span={16}>
                  <div className="pd30">
                    <Steps current={orderData?.step} progressDot={customDot}>
                      <Step title="下单" description={orderData?.created_at}/>
                      <Step title="付款" description={orderData?.paid_at}/>
                      {(orderData?.step_type == 1 || orderData?.step_type == 3) && <Step title="发货" description={orderData?.send_express_at != '0000-00-00 00:00:00' && orderData?.send_express_at}/>}
                      {(orderData?.step_type == 2 || orderData?.step_type == 3) && <Step title="申请退款" description={orderData?.paid_at != '0000-00-00 00:00:00' && orderData?.paid_at}/>}
                      {(orderData?.step_type == 2 || orderData?.step_type == 3) && <Step title="退款" description={orderData?.refund_at!= '0000-00-00 00:00:00' && orderData?.refund_at}/>}
                      {orderData?.step_type == 1 && <Step title="交易完成" description={orderData?.order_status == 5 && orderData?.receive_at}/>}
                    </Steps>
                  </div>
                </Col>
              </Row>
            </Card>
            {(orderData?.order_status >= 3 && orderData?.refund_order_status != 2) && <Card className="mb15">
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={12}>
                            <div className="item">
                                <div className="font16 mb15">物流信息</div>
                                <Form.Item label="物流公司">{orderData?.express_name}</Form.Item>
                                <Form.Item label="快递单号">{orderData?.express_no}
                                  {((Utils.getMenuRole("order/getExpress", props.allRole) ? (
                                      <a onClick={todetail}>&nbsp;&nbsp;物流追踪</a>) : ''))}
                                </Form.Item>
                                <Form.Item
                                    label="发货时间">{orderData?.order_status >= 3 && orderData?.send_express_at}</Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>}
            <Card className="mb15">
              <Form {...formItemLayout}>
                <Row>
                  <Col span={12} className="border">
                    <div className="font16 mb15">收货信息</div>
                    <Form.Item label="收货人">{orderData?.address_receiver}</Form.Item>
                    <Form.Item label="联系电话">{orderData?.address_mobile}</Form.Item>
                    <Form.Item
                        label="收货地址">{orderData?.province_name} {orderData?.city_name} {orderData?.district_name} {orderData?.address_detail}</Form.Item>
                    <Form.Item label="用户留言">{orderData.order_remark}</Form.Item>
                  </Col>
                  <Col span={12}>
                    <div className="item2">
                      <div className="font16 mb15">付款信息</div>
                      <Form.Item label="付款">
                        {orderData?.pay_fee}
                        &nbsp;
                        <span style={{color: 'green'}} className="font5">绿色积分:{orderData.green_integral}</span>
                        &nbsp;
                        <span style={{color: '#fe6673'}} className="font5">幸福值:{orderData.happy_integral}</span>
                      </Form.Item>
                      <Form.Item label="付款方式">{}{PAY_METHOD[orderData?.pay_method]}</Form.Item>
                      <Form.Item label="下单时间">{orderData?.order_status >= 1 && orderData?.created_at}</Form.Item>
                      <Form.Item label="付款时间">{orderData?.order_status >= 2 && orderData?.created_at} </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Card title="商品信息" className="mb15">
              <Form {...formItemLayout}>
                <Row>
                  <Col span={4}>
                    <div className="item">
                      <Form.Item label=""><img src={orderData?.good_main_media_url} height={180} alt=""/></Form.Item>
                    </div>
                  </Col>
                  <Col span={18}>
                    <div>
                      <Form.Item labelCol={{span: 2}} label="商品名称">{orderData?.good_title}</Form.Item>
                      <Form.Item labelCol={{span: 2}} label="购买数量">{orderData?.purchase_count}</Form.Item>
                      <Form.Item labelCol={{span: 2}} label="商品单价">{orderData?.spec_info?.spec_price}</Form.Item>
                      <Form.Item labelCol={{span: 2}} label="商品成本价">{orderData?.spec_info?.spec_cost_price}</Form.Item>
                      <Form.Item labelCol={{span: 2}}
                                 label="商品原价">{orderData?.spec_info?.spec_original_price}</Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
            {(orderData?.order_status == 6 || orderData?.order_status == 7 ?
            <Card title="退款信息" className="mb15">
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={18}>
                            <div>
                                <Form.Item labelCol={{span: 2}} label="退款状态">
                                  {REFUND_YIBAO_STATUS[orderData?.refund_yibao_status]}
                                </Form.Item>
                                <Form.Item labelCol={{span: 2}} label="退款金额">
                                  {orderData?.pay_fee}
                                </Form.Item>
                                <Form.Item labelCol={{span: 2}} label="申请退款时间">
                                  {orderData?.refund_apply_at}
                                </Form.Item>
                                <Form.Item labelCol={{span: 2}} label="退款时间">
                                  {orderData?.refund_at}
                                </Form.Item>
                                <Form.Item labelCol={{span: 2}} label="退款原因">
                                  {orderData?.refund_reason}
                                </Form.Item>
                                <Form.Item labelCol={{span: 2}} label="退款说明">
                                  {orderData?.refund_explain}
                                </Form.Item>

                              {orderData?.refund_yibao_status == 'FAILED' &&
                              <span>
                                <Form.Item labelCol={{span: 3}} label="店铺显示退款失败原因">
                                  {orderData?.refund_error_desc}
                                </Form.Item>
                                  <Form.Item labelCol={{span: 2}} label="实退款失败原因">
                                  {orderData?.refund_yibao_error_desc}
                                  </Form.Item>
                              </span>
                              }
                                {/*<Form.Item labelCol={{span: 3}} label="退款发起json数据">*/}
                                {/*    <span className="font5">{orderData?.refund_yibao_return_json}</span>*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item labelCol={{span: 3}} label="退款回调json数据">*/}
                                {/*    <span className="font5">{orderData?.refund_yibao_notify_json}</span>*/}
                                {/*</Form.Item>*/}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
            :'')}
          </div>
        </Skeleton>
      </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole,
    tabs: state.tabs,
  };
};

export default connect(mapStateToProps)(withAliveScope(ActivityDetails));
