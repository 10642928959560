import axios from "../../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/member/getMemberFansList',
        method: "post",
        data: params,
        isShowLoading: false
    })
}
export default {
    getList,
}