import React from 'react';
import {Card, Tabs} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import IntegralServerFee from './integral_server_fee';
import InviteFriends from './withdraw_fee';
import WaterConfig from './water_config';
import utils from '@src/utils/utils';
import {connect} from 'react-redux';

const {TabPane} = Tabs;

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const ProportionConfig: React.FC<IProps> = (props: IProps) => {

  const {getMenuRole} = utils
  const {allRole} = props

  return (
      <Card>
        <Tabs defaultActiveKey="1" hideAdd type="editable-card">
          {getMenuRole("config/get_withdraw_server_fee", allRole) && (
              <TabPane closable={false} tab="提现手续费配置" key="1">
                <InviteFriends {...props} />
              </TabPane>
          )}
          {getMenuRole("config/get_integral_server_fee", allRole) && (
              <TabPane closable={false} tab="钻石服务费配置" key="2">
                <IntegralServerFee {...props} />
              </TabPane>
          )}
          {getMenuRole("config/get_water_config", allRole) && (
              <TabPane closable={false} tab="水滴服务费配置" key="3">
                <WaterConfig {...props} />
              </TabPane>
          )}
        </Tabs>
      </Card>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(ProportionConfig)