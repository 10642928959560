import axios from "../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/config/getMessageList',
        method: "post",
        data: params,
        isShowLoading: false
    })
}

export default {
    getList,
}