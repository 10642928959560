import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {RouteComponentProps} from 'react-router-dom'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  PageHeader,
  Breadcrumb,
  Select,
} from 'antd'
import Request from './Request'
import Utils from '../../../utils/utils'
import {GOOD_STATUS} from '@src/config/statusConfig'
import UploadOSS from '../../../components/UploadOSS'
import EditTable from '../../../components/myEditable'
import SkeletonDetail from '../../../components/Skeleton/SkeletonDetail'
import {withAliveScope} from 'react-activation'
import {getSupplierAllList} from '@src/config/getAsyncConfig';

import './detail.less'

interface IProps extends RouteComponentProps {
  allRole: any[]
  dispatch: any
  tabs: any
  dropScope: any
  type: any
  id: any
}

const GoodsDetail: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm()
  //@ts-ignore
  const [params] = useState<any>(props?.match?.params)
  const [goodID] = useState<string | number>()
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [Listdata, setListdata] = useState<any[]>([])
  const [SupplierAllList, setSupplierAllList] = useState<any[]>([])

  const [goodInfo] = useState<any>({
    good_id: 'new',
    good_no: '', //商品货号
    good_status2: 1,
    category_first_id: '', //分类1级编号
    good_title: '', //商品标题
    good_status: 0, //商品状态 0=>未上架|1=>已上架|2=>已下架
    min_spec_price: '', //红包价格
    good_main_media_url: '', //商品主图
    good_media_url_gather: [], //商品其他图片
    good_detail_html: [], //商品详情 html
    good_spec: '', //规格详情
  })
  useEffect(() => {
    // 获得分类
    getSupplierAllList().then((res) => {
      setSupplierAllList(res.data.list)
    })
    // 获得标签
    if (params.type !== 'new') {
      getInfo()
    } else {
      setShowSkeleton(false)
    }
  }, [])
  //同步hooks
  const getInfo = Utils.useSyncCallback(() => {
    Request.getGoodInfo({good_id: params.id}).then((res) => {
      // 处理规格
      setListdata(res.data.spec_list)
      res.data.good_media_url_gather = JSON.parse(
          res.data.good_media_url_gather
      )
      res.data.good_detail_html = JSON.parse(
          res.data.good_detail_html
      )
      let goodInfoTemp = {
        ...res.data,
      }
      form.setFieldsValue(goodInfoTemp)
      setShowSkeleton(false)
    })
  })

  const ListDataChange = (data) => {
    setListdata(data)
  }

  const goodFinish = (formVal: any) => {
    SupplierAllList.forEach(item => {
      if (item.id == formVal.supplier_id) {
        formVal.supplier_name = item.store_name
      }
    })
    formVal.spec_json = JSON.stringify(Listdata)
    formVal.good_main_media_url = Utils.getUploadOSSUrl(
        formVal.good_main_media_url
    )[0]
    formVal.good_media_url_gather = JSON.stringify(
        Utils.getUploadOSSUrl(formVal.good_media_url_gather)
    )
    formVal.good_detail_html = JSON.stringify(
        Utils.getUploadOSSUrl(formVal.good_detail_html)
    )
    const callback = () => {
      // @ts-ignore
      if (typeof props?.location?.state?.refreshGood === 'function') {
        // @ts-ignore
        props.location.state.refreshGood()
      }
      message.success('操作成功')
      window.close()
    }
    if (params.type !== 'new') {
      formVal.good_id = params.id
      Request.edit(formVal).then(callback)
    } else {
      Request.add(formVal).then(callback)
    }
  }
  const columns = [
    {
      title: '规格名称',
      dataIndex: 'spec_title',
      editable: 'TEXT',
      rules: true,
    },
    {
      title: '规格销售价',
      dataIndex: 'spec_price',
      editable: 'NUMBER',
      rules: true,
    }, {
      title: '规格原价',
      dataIndex: 'spec_original_price',
      editable: 'NUMBER',
      rules: true,
    }, {
      title: '规格成本价',
      dataIndex: 'spec_cost_price',
      editable: 'NUMBER',
      rules: true,
    }, {
      title: '规格库存',
      dataIndex: 'spec_stock',
      editable: 'NUMBER',
      rules: true,
    }
  ]
  const formItemLayout = {
    labelCol: {span: 5, offset: 2},
    wrapperCol: {span: 12},
  }
  const ItemLayout = {
    labelCol: {span: 2, offset: 1},
    wrapperCol: {span: 18},
  }
  return (
      <>
        <PageHeader
            title='添加/编辑平台商品'
            className="site-page-header"
            breadcrumb={
              <Breadcrumb><Breadcrumb.Item>商品</Breadcrumb.Item>
                <Breadcrumb.Item>平台商品管理</Breadcrumb.Item></Breadcrumb>
            }></PageHeader>
        <div className="wrapContent">
          <SkeletonDetail loading={goodID === 'new' ? false : showSkeleton}>
            {(goodID === 'new' ? true : !showSkeleton) && (
                <Form
                    requiredMark={false}
                    {...formItemLayout}
                    onFinish={goodFinish}
                    scrollToFirstError={true}
                    form={form}
                    initialValues={{...goodInfo}}>
                  <Card className="mb15" title="基本信息">
                    <Row>
                      <Col span={24}>
                        <Form.Item
                            labelCol={{span: 2, offset: 1}}
                            labelAlign="right" label="商品名称"
                            name="good_title"
                            rules={[{required: true}]}
                        >
                          <Input placeholder="请输入商品名称 必填"/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                            label="商品封面"
                            name="good_main_media_url"
                            valuePropName="filelist"
                            labelCol={{span: 2, offset: 1}}
                            labelAlign="right"
                            rules={[{required: true, message: '请上传商品封面'}]}>
                          <UploadOSS/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                            label="商品主图"
                            name="good_media_url_gather"
                            valuePropName="filelist"
                            labelCol={{span: 2, offset: 1}}
                            labelAlign="right"
                            rules={[{required: true, message: '请上传商品主图'}]}>
                          <UploadOSS
                              filelength={5}
                              multiple={true}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item labelCol={{span: 2, offset: 1}} label="供应链" name="supplier_id">
                          <Select showSearch optionFilterProp="children" allowClear={true} placeholder="请选择">
                            {Utils.getOptionList(SupplierAllList, "id", "store_name")}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="mb15" title="价格库存">
                    <>
                      <div style={{padding: 20}}>
                        <EditTable
                            edit={{modify: true, add: true, del: true}}
                            defaultvalarr={{red_deduction_max_fee: 0}}
                            originData={Listdata}
                            columns={columns}
                            onChange={(newData, index, callback) =>
                                ListDataChange(newData)
                            }
                        />
                      </div>
                    </>
                  </Card>
                  <Card title="商品详情" className="mb15">

                    <Row>
                      <Col span={24}>
                        <Form.Item
                            label="商品详情"
                            name="good_detail_html"
                            valuePropName="filelist"
                            labelCol={{span: 2, offset: 1}}
                            labelAlign="right"
                            rules={[{required: true, message: '请上传商品详情图片'}]}>
                          <UploadOSS
                              filelength={15}
                              multiple={true}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card title="上架">
                    <Form.Item
                        {...ItemLayout}
                        label="上架方式"
                        name="good_status"
                        rules={[{required: true, message: '请选择上架方式'}]}>
                      <Radio.Group>
                        {(() => {
                          let radioArr = []
                          for (const key in GOOD_STATUS) {
                            if (Object.prototype.hasOwnProperty.call(GOOD_STATUS, key)) {
                              //@ts-ignore
                              radioArr.push(
                                  <Radio value={Number(key)} key={key}>
                                    {GOOD_STATUS[key].text}
                                  </Radio>
                              )
                            }
                          }
                          return radioArr
                        })()}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item {...{wrapperCol: {offset: 3}}}>
                      <Button type="primary" htmlType="submit">立即提交</Button>
                    </Form.Item>
                  </Card>
                </Form>
            )}
          </SkeletonDetail>
        </div>
      </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole,
    tabs: state.tabs,
  }
}

export default connect(mapStateToProps)(withAliveScope(GoodsDetail))
