import React from 'react';

import {HashRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {AliveScope} from 'react-activation';
import Login from "./pages/login/index";
import Admin from "./admin";
//404
import NotPower from "./components/NotPower"
//换成页面
import PrivateRoute from "./components/PrivateRoute"
import Home from "./pages/home";
import Workbench from "./pages/survey/workbench"

//会员
import UserList from "./pages/user/list"
import UserDetail from "./pages/user/userDetail"

//运营
import Banner from "./pages/operate/banner"
import App from "./pages/operate/app"
import AppPinpaiDetail from "./pages/operate/app/pinpai_detail"
import AppJingxuanDetail from "./pages/operate/app/jingxuan_detail"
import AppHaopingDetail from "./pages/operate/app/haoping_detail"
import AppResouDetail from "./pages/operate/app/resou_detail"

//财务
import RecordWithdraw from "./pages/record/withdraw"
import Recordfund from "./pages/record/revenue_record/fund"
import RevenueRecord from './pages/record/revenue_record'
import RecordBalanceWithdraw from "./pages/record/balance_withdraw"

//配置
import AppConfig from "./pages/config/app_config"
import GeneralConfig from "./pages/config/general_config"
import ProportionConfig from "./pages/config/proportion_config"
import AttributeConfig from "./pages/config/attribute_config"
import MessageConfig from "./pages/config/message"
import LogConfig from "./pages/config/log"

//权限
import FunManage from "./pages/setting/function"
import Role from "./pages/setting/role"
import AdminManage from "./pages/setting/admin"

//订单
import Order from "./pages/order/order"
import OrderDetail from "./pages/order/order/detail"
import OrderExpress from "./pages/order/order/express"
import StoreOrder from "./pages/order/store"
import StoreOrderDetail from "./pages/order/store/detail"
import StoreOrderExpress from "./pages/order/store/express"
import CustomsOrder from "./pages/order/customs"
import CustomsOrderDetail from "./pages/order/customs/detail"
import CustomsOrderExpress from "./pages/order/customs/express"
import OnlineOrder from "./pages/order/online_order"

//商品
import Cgood from "./pages/good/good"
import CgoodDetail from "./pages/good/good/detail"
import StoreGood from "./pages/good/store_good"
import Supplier from "./pages/good/supplier"

//店铺
import Store from "./pages/store/store"
import Category from "./pages/store/category"


export default class IRouter extends React.Component {
  render() {
    return (
        <Router>
          <Switch>
            <Route path="/login" component={Login}/>
            <Route path="/"
                   render={(props) => (
                       <AliveScope>
                         <Admin {...props}>
                           <Switch>

                             <PrivateRoute path="/home" component={Home}/>
                             <PrivateRoute path="/survey/workbench" component={Workbench}/>

                             <PrivateRoute path="/user/list" component={UserList}/>
                             <PrivateRoute path="/user/detail/:id" component={UserDetail}/>

                             <PrivateRoute path="/operate/banner" component={Banner}/>
                             <PrivateRoute path="/operate/app" component={App}/>
                             <PrivateRoute path="/operate/pinpai_detail" component={AppPinpaiDetail}/>
                             <PrivateRoute path="/operate/jingxuan_detail" component={AppJingxuanDetail}/>
                             <PrivateRoute path="/operate/haoping_detail" component={AppHaopingDetail}/>
                             <PrivateRoute path="/operate/resou_detail" component={AppResouDetail}/>

                             <PrivateRoute path="/good/good" component={Cgood}/>
                             <PrivateRoute path="/good/detail/:type/:id" component={CgoodDetail}/>
                             <PrivateRoute path="/good/store_good" component={StoreGood}/>
                             <PrivateRoute path="/good/supplier" component={Supplier}/>


                             <PrivateRoute path="/record/balance_withdraw" component={RecordBalanceWithdraw}/>
                             <PrivateRoute path="/record/withdraw" component={RecordWithdraw}/>
                             <PrivateRoute path="/record/fund" component={Recordfund}/>
                             <PrivateRoute path="/record/revenue_record" component={RevenueRecord}/>


                             <PrivateRoute path="/config/app_config" component={AppConfig}/>
                             <PrivateRoute path="/config/general_config" component={GeneralConfig}/>
                             <PrivateRoute path="/config/proportion_config" component={ProportionConfig}/>
                             <PrivateRoute path="/config/attribute_config" component={AttributeConfig}/>
                             <PrivateRoute path="/config/message" component={MessageConfig}/>
                             <PrivateRoute path="/config/log" component={LogConfig}/>

                             <PrivateRoute path="/setting/function" component={FunManage}/>
                             <PrivateRoute path="/setting/role" component={Role}/>
                             <PrivateRoute path="/setting/admin" component={AdminManage}/>


                             <PrivateRoute path="/order/order" component={Order}/>
                             <PrivateRoute path="/order/order_express/:id" component={OrderExpress}/>
                             <PrivateRoute path="/order/order_detail/:id" component={OrderDetail}/>
                             <PrivateRoute path="/order/store" component={StoreOrder}/>
                             <PrivateRoute path="/order/store_order_express/:id" component={StoreOrderExpress}/>
                             <PrivateRoute path="/order/store_order_detail/:id" component={StoreOrderDetail}/>
                             <PrivateRoute path="/order/customs" component={CustomsOrder}/>
                             <PrivateRoute path="/order/customs_order_express/:id" component={CustomsOrderExpress}/>
                             <PrivateRoute path="/order/customs_order_detail/:id" component={CustomsOrderDetail}/>
                             <PrivateRoute path="/order/online_order" component={OnlineOrder}/>

                             <PrivateRoute path="/store/store" component={Store}/>
                             <PrivateRoute path="/store/category" component={Category}/>

                             <Route path="/notpower" component={NotPower}/>
                             <Redirect to="/home"/>
                           </Switch>
                         </Admin>
                       </AliveScope>
                   )}
            />
          </Switch>
        </Router>
    );
  }
}

