import axios from "../../../axios"


//获取app板块列表
function getModule() {
  return axios.ajax({
    url: '/config/getAppPlate',
    method: "post",
    isShowLoading: false
  })
}


//获取潮流范配置
function getTrendGan() {
  return axios.ajax({
    url: '/config/get_trend_fan',
    method: "post",
    isShowLoading: false
  })
}

//编辑潮流范配置
function editTrendFan(params: any) {
  return axios.ajax({
    url: "/config/edit_trend_fan",
    method: "post",
    data: params,
  });
}

//获取热销榜单配置
function getHostSale() {
  return axios.ajax({
    url: '/config/get_host_sale',
    method: "post",
    isShowLoading: false
  })
}

//编辑限时促销配置
function editBigCoupoin(params: any) {
  return axios.ajax({
    url: "/config/edit_big_coupon",
    method: "post",
    data: params,
  });
}


//编辑活动商品规格
function updateGood(params: any) {
  return axios.ajax({
    url: "/config/edit_good_red_new",
    method: "post",
    data: params,
  });
}

//获取9.9配置
function getLowPrice() {
  return axios.ajax({
    url: '/activity/get_low_price',
    method: "post",
    isShowLoading: false
  })
}

//编辑店铺排行权重
function editSort(params: any) {
  return axios.ajax({
    url: '/config/updateSortPlateStore',
    method: "post",
    data: params,
    isShowLoading: false
  })
}

//获取红包商品列表
//获取品牌店铺选取店铺列表
function getGoodsData(params: any) {
  return axios.ajax({
    url: '/config/getAppPlate',
    method: "post",
    data: params,
    isShowLoading: false
  })
}

function getStoreList1(params: any) {
  return axios.ajax({
    url: '/config/getStoreList1',
    method: "post",
    data: params,
    isShowLoading: false
  })
}

//获取大牌补贴配置
function getBrandBubsidy() {
  return axios.ajax({
    url: '/config/get_brand_subsidy',
    method: "post",
    isShowLoading: false
  })
}


//编辑防疫专区配置
function editEpidemicPrefecture(params: any) {
  return axios.ajax({
    url: "/config/edit_epidemic_prefecture",
    method: "post",
    data: params,
  });
}

//获取多多内购配置
function getMorePurchase() {
  return axios.ajax({
    url: '/config/get_more_purchase',
    method: "post",
    isShowLoading: false
  })
}

//编辑多多内购配置
function editMorePurchase(params: any) {
  return axios.ajax({
    url: "/config/edit_more_purchase",
    method: "post",
    data: params,
  });
}


//编辑百亿补贴配置
function editTenBillion(params: any) {
  return axios.ajax({
    url: "/config/edit_group_billon",
    method: "post",
    data: params,
  });
}

//获取app板块详情
function getAppConfigInfo(params: any) {
  return axios.ajax({
    url: '/config/getAppConfigInfo',
    method: "post",
    data: params,
    isShowLoading: false
  })
}

//获取新人专区和爆款列表
function getGoodRedNewList(params: any) {
  return axios.ajax({
    url: '/config/getAppPlateStoreList',
    method: "post",
    data: params,
  })
}

//添加新人专区和爆款列表
//添加品牌店铺数据
function editGoodRedNewList(params: any) {
  return axios.ajax({
    url: '/config/addSortPlateStore',
    method: "post",
    data: params,
  })
}

//编辑红包商品权重
function editRedSort(params: any) {
  return axios.ajax({
    url: '/activity/edit_red_all_sort',
    method: "post",
    data: params,
    isShowLoading: false
  })
}

//删除红包商品权重
//删除品牌店铺数据
function deleteRedGood(params: any) {
  return axios.ajax({
    url: '/config/deleteAppPlateStore',
    method: "post",
    data: params,
    isShowLoading: false
  })
}


//品牌店铺
function editPingPaiDianPuConfig(params: any) {
  return axios.ajax({
    url: '/config/editPingPaiDianPuConfig',
    method: "post",
    data: params,
  })
}

//
function editJinXuanHaoPuConfig(params: any) {
  return axios.ajax({
    url: '/config/editJinXuanHaoPuConfig',
    method: "post",
    data: params,
  })
}

//编辑啊app板块配置详情
function editAppConfig(params: any) {
  return axios.ajax({
    url: '/config/editAppConfig',
    method: "post",
    data: params,
  })
}

export default {
  getModule,
  getTrendGan,
  editTrendFan,
  getHostSale,
  editBigCoupoin,
  updateGood,
  getLowPrice,
  getGoodsData,
  editSort,
  getBrandBubsidy,
  editEpidemicPrefecture,
  getMorePurchase,
  editMorePurchase,
  editTenBillion,
  getAppConfigInfo,
  getGoodRedNewList,
  editGoodRedNewList,
  editRedSort,
  deleteRedGood,
  editPingPaiDianPuConfig,
  getStoreList1,
  editJinXuanHaoPuConfig,
  editAppConfig
}