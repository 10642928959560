import React, {useState} from "react";
import {Breadcrumb, Tabs, PageHeader} from "antd";
import {RouteComponentProps} from "react-router-dom";
import {connect} from "react-redux";
import Fund from "./fund"
import LockIntegral from "./lock_integral"
import LockStoreIntegral from "./lock_store_integral"
import LockCustomerIntegral from "./lock_customer_integral"
import GreenIntegral from "./green_integral"
import HappyIntegral from "./happy_integral"
import StoreFund from "./store_fund"
import utils from "@src/utils/utils";

interface IProps extends RouteComponentProps {
  allRole: any[];
}

const {TabPane} = Tabs;
const RecordStar: React.FC<IProps> = (props: IProps) => {
  const GoodStatus = [];
  const {getMenuRole} = utils
  const {allRole} = props
  if (getMenuRole("record/getRecordFundList", allRole)) {
    GoodStatus[0] = {
      title: "余额记录",
      param: {},
    };
  }
  if (getMenuRole("record/getRecordLockIntegralList", allRole)) {
    GoodStatus[1] = {
      title: "用户冻积分",
      param: {
        is_get: 1,
      },
    };
  }
  if (getMenuRole("record/getRecordLockStoreIntegralList", allRole)) {
    GoodStatus[2] = {
      title: "商家冻积分",
      param: {
        is_get: 1,
      },
    };
  }
  if (getMenuRole("record/getRecordLockCustomerIntegralList", allRole)) {
    GoodStatus[3] = {
      title: "开通店主积分",
      param: {
        is_get: 1,
      },
    };
  }

  if (getMenuRole("record/getRecordGreenIntegralList", allRole)) {
    GoodStatus[4] = {
      title: "绿色积分",
      param: {
        is_get: 1,
      },
    };
  }
  if (getMenuRole("record/getRecordHappyIntegralList", allRole)) {
    GoodStatus[5] = {
      title: "幸福值",
      param: {
        is_get: 1,
      },
    };
  }
  if (getMenuRole("record/getRecordStoreFundList", allRole)) {
    GoodStatus[6] = {
      title: "店铺余额",
      param: {
        is_get: 1,
      },
    };
  }
  const [tabKey, setTabKey] = useState<any>("0");
  const tabChange = (key: any) => {
    setTabKey(key)
  }
  return (
      <>
        <PageHeader
            title='收益记录'
            className="site-page-header"
            breadcrumb={<Breadcrumb><Breadcrumb.Item>财务管理</Breadcrumb.Item>
              <Breadcrumb.Item>收益记录</Breadcrumb.Item></Breadcrumb>}
            footer={
              <Tabs defaultActiveKey="0" onChange={tabChange}>
                {GoodStatus.map((statusItem: any, index: number) => {
                  return (
                      <TabPane
                          closable={false}
                          tab={statusItem.title}
                          key={index}
                      ></TabPane>
                  );
                })}
              </Tabs>
            }
        >
        </PageHeader>
        <div className="wrapContent">
          {tabKey == 0 && <Fund {...props}/>}
          {tabKey == 1 && <LockIntegral {...props}/>}
          {tabKey == 2 && <LockStoreIntegral {...props} />}
          {tabKey == 3 && <LockCustomerIntegral {...props} />}
          {tabKey == 4 && <GreenIntegral {...props} />}
          {tabKey == 5 && <HappyIntegral {...props} />}
          {tabKey == 6 && <StoreFund {...props} />}
        </div>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole,
  };
};

export default connect(mapStateToProps)(RecordStar);
