import React, {useRef} from 'react';
import {connect} from "react-redux";
import ProTable, {ActionType, ProColumns} from '@ant-design/pro-table';
import Request from "./Request"
import {ListItem} from "./data"
import {FUND_TARGET_SUBTYPE} from '@src/config/statusConfig';
import {Button} from 'antd';
import Utils from "../../../../utils/utils"
import {RouteComponentProps} from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

let exportsFilter: any = {};
const Recordfund: React.FC<IProps> = (props: IProps) => {
  const ref = useRef<ActionType>();
  //导出
  const exportsDown = () => {
    let url = process.env.REACT_APP_API_URL + "/record/exportRecordFund?token=" + Utils.getToken();
    delete exportsFilter.current;
    delete exportsFilter.pageSize;
    for (const key in exportsFilter) {
      if (Object.prototype.hasOwnProperty.call(exportsFilter, key)) {
        const element = exportsFilter[key];
        url += `&${key}=${element}`;
      }
    }
    window.location.href = url;
  };
  const columns: ProColumns<ListItem>[] = [
    {title: 'ID', align:"center", width: 80, dataIndex: 'fund_id', search: false},
    {title: '会员昵称', width: 120, dataIndex: 'nickname', search: false},
    {title: '手机号', align:"center", width: 100, dataIndex: 'mobile'},
    {
      title: '金额', width: 100, dataIndex: 'fund_fee', search: false, render: (_, record) => {
        if (record.fund_status == 1) {
          return <span className="color-success">{"+" + record.fund_fee}</span>
        } else {
          return <span className="color-danger">{"-" + record.fund_fee}</span>
        }
      }
    },

    {title: '类型', width: 120, align: 'left', dataIndex: 'fund_target_type', valueEnum: FUND_TARGET_SUBTYPE,},
    {title: '备注信息', width: 300, dataIndex: 'desc', search: false,},
    {title: '来源ID', width: 80, dataIndex: 'fund_target_id', search: false},
    {
      title: '时间',
      width: 120,
      align:"center",
      dataIndex: 'created_at',
      valueType: "dateTimeRange",
      render: (_, record) => record.created_at
    }
  ];

  return (
      <>
        <ProTable<ListItem>
            rowKey="fund_id"
            tableStyle={{padding: '0 24px 0 24px'}}
            columns={columns}
            actionRef={ref}
            request={async (params: any) => {
              if (params.created_at) {
                params.start_time = params.created_at[0]
                params.end_time = params.created_at[1]
                delete params.created_at
              }
              exportsFilter = JSON.parse(JSON.stringify(params));
              const res = await Request.getList(params);
              return {
                data: res.data.list,
                total: res.data.count,
                success: true,
                pageSize: params.pageSize,
                current: params.current
              }
            }}
            toolbar={{
              title: (<>{Utils.getMenuRole("record/exportRecordFund", props.allRole) && (
                  <Button type="primary" className="mr10" key="record/exportFund"
                          onClick={exportsDown}>导出</Button>)}</>),
              filter: ''
            }}
            options={{density: false, fullScreen: false, reload: false, setting: false}}
        />
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(Recordfund);