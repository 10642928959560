import React, {useState, useEffect} from 'react';
import {Card, message, Button, Input, Form, Divider, Row, Col, InputNumber, Radio, Space} from 'antd';
import {RouteComponentProps} from 'react-router-dom';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import {connect} from 'react-redux';
import './index.css'

//s1234
interface IProps extends RouteComponentProps {
  allRole: any[]
}

const GeneralConfig: React.FC<IProps> = (props: IProps) => {
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)
  const [integralData, setintegralData] = useState<any>({});
  const [rateValue1, setRateValue1] = useState<number>(0);
  const [rateValue2, setRateValue2] = useState<number>(0);
  const [rateValue3, setRateValue3] = useState<number>(0);

  const initData = async () => {
    const res = await Request.getData()
    let {data} = res
    setintegralData(data)
    setRateValue1(100 - res.data.member_green_integral_ratio_happy_integral_rate)
    setRateValue2(100 - res.data.bd_green_integral_ratio_happy_integral_rate)
    setRateValue3(100 - res.data.store_green_integral_ratio_happy_integral_rate)
    setShowSkeleton(false)
  }

  useEffect(() => {

    initData()
  }, [])
  const onFinish = (formVal: any) => {
    let obj = {"config_value": JSON.stringify(formVal)}
    Request.edit(obj).then((res) => {
      if (res.code == 0) {
        message.success("保存成功")
        initData()
      } else {
        message.error(res.msg)
      }
    })
  };

  const onChange1 = (v) => {
    if (v > 100) {
      message.error('最大值100！')
      setRateValue1(0)
      return;
    }
    setRateValue1(100 - v)
  }

  const onChange2 = (v) => {
    if (v > 100) {
      message.error('最大值100！')
      setRateValue2(0)
      return;
    }
    setRateValue2(100 - v)
  }

  const onChange3 = (v) => {
    if (v > 100) {
      message.error('最大值100！')
      setRateValue3(0)
      return;
    }
    setRateValue3(100 - v)
  }


  return (
      <>
        <div className="diamonds">
          <Skeleton loading={showSkeleton}>
            <Card className="mb15">
              <Form
                  labelCol={{span: 8}}
                  wrapperCol={{span: 16}}
                  onFinish={onFinish}
                  requiredMark={false}
                  initialValues={{...integralData}}
              >
                <div className="public-title">
                  <span>积分配置</span>
                  <Button type="primary" htmlType="submit">保存</Button>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>用户冻积分每日释放比例 ：</Col>
                  <Col>
                    <Form.Item name="member_lock_integral_today_release_green_integral_rate">
                      <Input style={{width: "150px", margin: "0 8px"}} suffix="%"/>
                    </Form.Item>
                  </Col>
                  <Col>,用户冻积分释放成绿色积分和幸福值比例 ：</Col>
                  <Col>
                    <Form.Item name="member_green_integral_ratio_happy_integral_rate">
                      <InputNumber min={0} max={100} style={{width: "150px", margin: "0 8px"}} onChange={onChange1}/>
                    </Form.Item>
                  </Col>
                  <Col>:</Col>
                  <Col>
                    <InputNumber disabled={true} style={{width: "150px", margin: "0 8px"}} defaultValue={0}
                                 value={rateValue1}/>
                  </Col>
                  <Col>
                    <span className="color-danger font5">(比例单框最大值100)</span>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col>开通店主积分每日释放比例 ：</Col>
                  <Col>
                    <span className="font5 color-danger">根据报单级别释放（在级别奖励配置里面）</span>
                  </Col>
                  <Col>,开通店主积分释放成绿色积分和幸福值比例 ：</Col>
                  <Col>
                    <Form.Item name="bd_green_integral_ratio_happy_integral_rate">
                      <InputNumber min={0} max={100} style={{width: "150px", margin: "0 8px"}} onChange={onChange2}/>
                    </Form.Item>
                  </Col>
                  <Col>:</Col>
                  <Col>
                    <InputNumber disabled={true} style={{width: "150px", margin: "0 8px"}} defaultValue={0}
                                 value={rateValue2}/>
                  </Col>
                  <Col>
                    <span className="color-danger font5">(比例单框最大值100)</span>
                  </Col>
                </Row>
                <Row className="sign">
                  <Col>商家冻积分每日释放比例 ：</Col>
                  <Col>
                      <span className="font5 color-danger">根据报单级别释放（在级别奖励配置里面）</span>
                  </Col>
                  <Col>,商家冻积分释放成绿色积分和幸福值比例 ：</Col>
                  <Col>
                    <Form.Item name="store_green_integral_ratio_happy_integral_rate">
                      <InputNumber min={0} max={100} style={{width: "150px", margin: "0 8px"}} onChange={onChange3}/>
                    </Form.Item>
                  </Col>
                  <Col>:</Col>
                  <Col>
                    <InputNumber disabled={true} style={{width: "150px", margin: "0 8px"}} defaultValue={0}
                                 value={rateValue3}/>
                  </Col>
                  <Col>
                    <span className="color-danger font5">(比例单框最大值100)</span>
                  </Col>
                </Row>
                <br/>
                <div className="public-title">
                  <span>支付使用绿色积分和幸福值比例设置</span>
                </div>
                <Divider/>
                <Row className="sign">
                  <Col>支付使用 绿色积分 最大比例 ：</Col>
                  <Col>
                    <Form.Item name="pay_max_green_integral_rate">
                      <InputNumber min={0} max={100} style={{width: "150px", margin: "0 8px"}}/>
                    </Form.Item>
                  </Col>
                  <Col>%</Col>
                </Row>
                <Row className="sign">
                  <Col>支付使用 幸福值 最大比例 ：</Col>
                  <Col>
                    <Form.Item name="pay_max_happy_integral_rate">
                      <InputNumber min={0} max={100} style={{width: "150px", margin: "0 8px"}}/>
                    </Form.Item>
                  </Col>
                  <Col>%</Col>
                </Row>

                <Row className="sign">
                  <Col>用户冻积分获得选项-线上消费：</Col>
                  <Col>
                    <Form.Item name="lock_integral_pay_select_type">
                      <Radio.Group style={{width: "650px", margin: "0 8px"}}>
                        <Space direction="vertical">
                          <Radio value={"1"}>商品售价总额&nbsp;&nbsp;<span className="color-danger font5">（付款多少就获得多少用户冻积分）</span></Radio>
                          <Radio value={"2"}>实付款金额&nbsp;&nbsp;<span className="color-danger font5">（使用 余额、支付宝、微信 付款金额。付款多少就获得多少用户冻积分）</span></Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider/>
              </Form>
            </Card>
          </Skeleton>
        </div>
      </>
  );
};

const mapStateToProps = (state: { allRole: any[] }) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(GeneralConfig)