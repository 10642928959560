import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  Button,
  Modal,
  Form,
  PageHeader,
  Breadcrumb,
  Select,
  Tabs,
  Input,
  message,
  Radio,
  Row,
  Col,
} from "antd";

import {RouteComponentProps} from "react-router-dom";
import Request from "./Request"
import {withAliveScope} from "react-activation";
import Utils from "../../../utils/utils"
import validate from "../../../utils/validate"
import DirectPush from './direct_push'
import UserDetail from './detail'
import {ListItem} from "./data"
import {IDENTITY} from "@src/config/statusConfig"
import './index.less'

interface IProps extends RouteComponentProps {
  allRole: any[];
  dispatch: any;
  tabs: any;
  dropScope: any;
}

const ActivityDetails: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm();

  //@ts-ignore
  let id = props?.match?.params?.id || "new";
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [addRadioValue, setAddRadioValue] = useState<string>("0");
  const [mid, setMid] = useState<string | number>(id);
  const [modelType, setModelType] = useState<"PREVIEW" | "WALLET" | "Credit_Score" | "IDENTITY" | "MEMBER_TYPE" | "EDIT_STAR_CHANGE" | "ADD_STAR_CHANGE" | "STAR_SUB_LIST">("PREVIEW");
  const [userInfo, setUserInfo] = useState<ListItem>()
  const [areaName, setAreaName] = useState<any>([])
  const [agentList, setAgentList] = useState<any>([])
  const {TabPane} = Tabs;
  const {allRole} = props
  useEffect(() => {
    Request.getUserDetails({mid: mid}).then((res) => {
      if (res.data.is_shoppers != 0) {
        res.data.identityifo = "e网导购"
        if (res.data.pay_is_card_password == 6) {
          res.data.identityifo = "e网导购（卡密支付）"
        } else {
          res.data.identityifo = "e网导购（人民币支付）"
        }
      }
      setUserInfo(res.data)
      setAgentList(res.data.agent_area_list)
    });
  }, []);
  let extra = [];
  if (Utils.getMenuRole("member/addMemberBalance", allRole) ||
      Utils.getMenuRole("member/addMemberLockIntegral", allRole) ||
      Utils.getMenuRole("member/addMemberLockStoreIntegral", allRole) ||
      Utils.getMenuRole("member/addMemberLockCustomerIntegral", allRole) ||
      Utils.getMenuRole("member/addMemberGreenIntegral", allRole) ||
      Utils.getMenuRole("member/addMemberHappyIntegral", allRole)
  ) {
    extra.push(<Button type="primary" key='1' onClick={() => {
      wallet(userInfo)
    }}>钱包</Button>)
  }
  if (Utils.getMenuRole("member/editMemberIdentity", allRole)) {
    extra.push(<Button className="ml20 button-green" key='2' onClick={() => {
      identity(userInfo)
    }}>身份</Button>);
  }

  const visibleModal = () => {
    setVisible(!visible)
  }
  const visibleAddModal = () => {
    setVisibleAdd(!visibleAdd)

    setAddRadioValue("0")
    setAreaName([])
  }

  //确认添加
  const areaformSubmit = (formVal: any) => {
    console.log(formVal, 123123);
    if (addRadioValue == "0" && (areaName[0] == "" || areaName.length == 0)) {
      message.error("请选择地区")
      return
    }
    if (addRadioValue == "1" && (areaName[0] == "" || areaName[1] == "" || areaName.length == 0)) {
      message.error("请选择地区")
      return
    }
    if (addRadioValue == "2" && (areaName[0] == "" || areaName[1] == "" || areaName[2] == "" || areaName.length == 0)) {
      message.error("请选择地区")
      return
    }
    if (addRadioValue == "0") {
      setAgentList(agentList.concat({
        id: agentList.length + 1,
        itype: 1,
        province_name: areaName[0],
        city_name: "",
        district_name: "",
        province_code: formVal.area[0],
        city_code: "",
        district_code: ""
      }))
    } else if (addRadioValue == "1") {
      setAgentList(agentList.concat({
        id: agentList.length + 1,
        itype: 2,
        province_name: areaName[0],
        city_name: areaName[1],
        district_name: "",
        province_code: formVal.area[0],
        city_code: formVal.area[1],
        district_code: ""
      }))
    } else if (addRadioValue == "2") {
      setAgentList(agentList.concat({
        id: agentList.length + 1,
        itype: 3,
        province_name: areaName[0],
        city_name: areaName[1],
        district_name: areaName[2],
        province_code: formVal.area[0],
        city_code: formVal.area[1],
        district_code: formVal.area[2]
      }))
    }
    visibleAddModal()
  };
  const wallet = (item: ListItem) => {
    setTitle("钱包")
    setVisible(true)
    setModelType("WALLET")
  }
  const identity = (item: ListItem) => {
    setTitle("身份")
    setModelType("IDENTITY")
    setVisible(true)
    setMid(item.mid)
    let arr = []
    if (item.is_promoters == 1) arr.push("promoters")
    if (item.alliance_level == 1) arr.push("one_alliance")
    if (item.alliance_level == 2) arr.push("new_alliance")
    if (item.alliance_team == 1) arr.push("team")
    form.setFieldsValue({
      level: userInfo.level + "",
      is_big: userInfo.is_big + "",
      vip: userInfo.vip + "",
      is_allow_set_auth_group: userInfo.is_allow_set_auth_group + "",
    })
  }

  const formSubmit = async (formVal: any) => {
    const callback = () => {
      visibleModal()
      message.success("操作成功")
      Request.getUserDetails({mid: mid}).then((res) => {
        setUserInfo(res.data)
      });
    }
    formVal.mid = mid
    if (modelType === "WALLET") {
      const url = formVal.wallet_type
      delete formVal.wallet_type
      Request.editWallet(formVal, url).then(callback)
    }
    if (modelType === "IDENTITY") {
      let params = {
        ...formVal,
        area_json: JSON.stringify(agentList)
      }
      Request.editMemberIdentity(params).then(callback);
    }
  }
  const getPower = () => {
    let power = []
    const {allRole} = props
    if (allRole.length > 0) {
      if (Utils.getMenuRole("member/addMemberBalance", allRole)) {
        power.push({id: "member/addMemberBalance", name: "余额"})
      }
      if (Utils.getMenuRole("member/addMemberLockIntegral", allRole)) {
        power.push({id: "member/addMemberLockIntegral", name: "用户冻积分"})
      }
      if (Utils.getMenuRole("member/addMemberLockStoreIntegral", allRole)) {
        power.push({id: "member/addMemberLockStoreIntegral", name: "商家冻积分"})
      }
      if (Utils.getMenuRole("member/addMemberLockCustomerIntegral", allRole)) {
        power.push({id: "member/addMemberLockCustomerIntegral", name: "开通店主积分"})
      }
      if (Utils.getMenuRole("member/addMemberGreenIntegral", allRole)) {
        power.push({id: "member/addMemberGreenIntegral", name: "绿色积分"})
      }
      if (Utils.getMenuRole("member/addMemberHappyIntegral", allRole)) {
        power.push({id: "member/addMemberHappyIntegral", name: "幸福值"})
      }
    }
    return power
  }

  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 20}
  }

  return (
      <div className="detailwrap">
        <PageHeader
            title={<div>
              <div>
                <span className="ant-page-header-heading-sub-title2">{userInfo?.nickname}</span>
                <span
                    className="ant-page-header-heading-sub-title2"> 邀请码：{userInfo?.level > 0 ? userInfo.invitation_number : "无报单"}</span>
              </div>
            </div>}
            className="site-page-header"
            extra={extra}
            avatar={{src: 'https://avatars1.githubusercontent.com/u/8186664?s=460&v=4'}}
            breadcrumb={<Breadcrumb><Breadcrumb.Item>会员管理</Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="#">会员列表</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>会员详情</Breadcrumb.Item></Breadcrumb>}
        >
        </PageHeader>
        <Tabs defaultActiveKey="11212" className="tabs">
          <TabPane tab="详情" key="11">
            <UserDetail {...props} userInfo={userInfo}></UserDetail>
          </TabPane>
          <TabPane tab="APP(直推粉丝)" key="124">
            <DirectPush {...props}></DirectPush>
          </TabPane>
        </Tabs>
        <Modal
            destroyOnClose
            title={title}
            visible={visible}
            onCancel={visibleModal}
            footer={false}
            width={800}>
          <Form form={form}{...formItemLayout} requiredMark={false} onFinish={formSubmit}>
            {modelType === "WALLET" && (
                <>
                  <Form.Item
                      label="充值类型"
                      name="wallet_type"
                      rules={[{required: true}]}
                  >
                    <Select placeholder="请选择">
                      {Utils.getOptionList(getPower())}
                    </Select>
                  </Form.Item>
                  <Form.Item
                      label="操作类型"
                      name="type"
                      rules={[{required: true}]}
                  >
                    <Select placeholder="请选择">
                      {Utils.getOptionList({"1": "充值", "2": "扣除"})}
                    </Select>
                  </Form.Item>
                  <Form.Item
                      label="数量"
                      name="value"
                      rules={[{validator: validate.checLessThan1}]}
                  >
                    <Input placeholder="请输入数量" type="number"/>
                  </Form.Item>
                  <Form.Item
                      label="备注"
                      name="remark"
                      rules={[{required: true}]}
                  >
                    <Input.TextArea placeholder="请输入备注"/>
                  </Form.Item>
                </>
            )}
            {modelType === "IDENTITY" && (<>
              <Row>
                <Col>
                  <Form.Item label="星店主" name="level" style={{width: '500px'}}
                             help={<span className="color-danger font5">⚠️注意：后台调整星店主身份，无法做到触发上级任何奖励️</span>}>
                    <Radio.Group>
                      <Radio value="0">普通</Radio>
                      <Radio value="1">1星</Radio>
                      <Radio value="2">2星</Radio>
                      <Radio value="3">3星</Radio>
                      <Radio value="4">4星</Radio>
                      <Radio value="5">5星</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

            </>)}
            {modelType === "PREVIEW" || modelType === "STAR_SUB_LIST" ? null : (
                <Form.Item {...{wrapperCol: {offset: 3}}} style={{marginTop: '20px'}}>
                  <Button type="primary" htmlType="submit">立即提交</Button>
                </Form.Item>)}
          </Form>
        </Modal>
      </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole,
    tabs: state.tabs,
  };
};

export default connect(mapStateToProps)(withAliveScope(ActivityDetails));
