import React, {useState, useImperativeHandle, useEffect} from "react";
import {connect} from "react-redux";
import {Upload, Modal, message} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Utils from "../../utils/utils";

interface IsProps {
  cRef?: any;
  ossConfig: {
    accessKeyId: string;
    accessKeySecret: string;
    region: string;
    bucket: string;
    folder?: string;
  };
  filelist?: any[] | string;
  filelength?: number;
  disabled?: boolean;
  multiple?: boolean;
  onChange?: (data: any) => void;
}

const UploadOSS: React.FC<IsProps> = (props: IsProps) => {
  let {
    ossConfig,
    disabled = false,
    filelist = [],
    multiple = false,
    onChange = (list) => {
    },
    filelength = 1,
    cRef,
    ...restProps
  } = props;
  let F_list: any[] = [];
  if (typeof filelist === "string") {
    F_list = [filelist];
  } else {
    F_list = filelist;
  }
  let tempList: any[] = [];
  F_list.forEach((item) => {
    if (item) {
      tempList.push({
        uid: Number(Math.random().toString().substr(3, 10) + Date.now()).toString(36),
        url: Utils.getUploadOSSUrl(item)[0],
      });
    }
  });

  const [listData, setData] = useState<any[]>(tempList);
  const [loading, setLoading] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");

  useEffect(() => {
    setData([...tempList])
    console.log(tempList)
  }, [])
  useImperativeHandle(cRef, () => ({
    changeVal: (newVal: any) => {
      if (typeof newVal === "string") {
        newVal = [newVal];
      }
      let tempList: any[] = [];
      newVal.forEach((item: any) => {
        tempList.push({
          uid:
              Date.now() +
              "" +
              Math.ceil(Math.random() * 100) +
              Math.ceil(Math.random() * 100),
          url: Utils.getUploadOSSUrl(item)[0],
        });
      });
      onChange!(newVal);
      setData(tempList);
    },
  }));

  const beforeUpload = (file: any) => {
    setLoading(true);
    // 使用ossupload覆盖默认的上传方法
    Utils.UploadOSS(file, ossConfig).then((data) => {
      setData((prevState: any) => {
        if (prevState.length >= filelength) {
          message.error(`最多上传${filelength}张`);
          return prevState;
        }
        const newData = [...prevState];
        let url = data.res.requestUrls[0].replace("https://ywshapp.oss-cn-hangzhou.aliyuncs.com/", "https://oss.ywys888.cn/")
        console.log(file, data, '返回数据', url)
        newData.push({
          uid: file.uid,
          name: file.name,
          url: url,
        });
        Promise.resolve().then(() => onChange!(newData))
        return newData;
      });

      setLoading(false);
    });
    return false; // 不调用默认的上传方法
  };

  const handlePreview = (file: any) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
  };

  const removeFile = (file: any) => {
    const newData = [...listData];
    if (newData && newData.length > 0) {
      let index = newData.findIndex((item) => {
        return item.url === file.url;
      });
      newData.splice(index, 1);
      setData(newData);
      let resData: any[] = [];
      newData.forEach((item) => {
        resData.push(item.url);
      });
      props.onChange!(resData);
    }
  };
  const handleCancel = () => setPreviewVisible(false);
  return (
      <>
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            accept=".jpge,.jpg,.png,.webp"
            fileList={listData}
            disabled={disabled}
            multiple={multiple}
            beforeUpload={beforeUpload}
            onPreview={handlePreview}
            onRemove={removeFile}
            {...restProps}
        >
          {listData.length >= filelength || disabled ? null : (
              <>
                {loading ? <LoadingOutlined/> : <PlusOutlined/>}
                <div className="ant-upload-text" style={{userSelect: "none"}}>上传</div>
              </>
          )}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{width: "100%"}} src={previewImage}/>
        </Modal>
      </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ossConfig: state.oss_conf,
  };
};

export default connect(mapStateToProps)(UploadOSS);
