import axios from "../../../axios"

function getList(params: any) {
  return axios.ajax({
    url: '/member/getMemberList',
    method: "post",
    data: params,
    isShowLoading: false
  })
}

function editWallet(params: { mid: number, type: number | string, value: number | string, remark: string }, url: string) {
  return axios.ajax({
    url,
    method: "post",
    data: params
  })
}


function updateStatus(params: any) {
  return axios.ajax({
    url: '/member/updateMemberWalletFreeze',
    method: "post",
    data: params
  })
}

function deleteData(mid: number) {
  return axios.ajax({
    url: '/member/deleteMember',
    method: "post",
    data: {mid: mid}
  })
}

export default {
  getList,
  editWallet,
  updateStatus,
  deleteData
}