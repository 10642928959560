import axios from "../../../axios"

function getList(params: any) {
    return axios.ajax({
        url: '/order/getCustomsOrderList',
        method: "post",
        data: params,
    })
}
function importOrderUpdateData(params: any) {
    return axios.ajax({
        url: '/order/importCustomsOrderUpdate',
        method: "post",
        data: params,
    })
}
function getActDetails(params: any) {
    return axios.ajax({
        url: '/order/getCustomsOrderInfo',
        method: "post",
        data: params,
    })
}
function getLogisticsData(params: any) {
    return axios.ajax({
        url: '/order/getCustomsExpress',
        method: "post",
        data: params,
    })
}
//发货
function editOrderExpress(params: any) {
    return axios.ajax({
        url: '/order/customsOrderDelivery',
        method: "post",
        data: params,
    })
}
function updateOrderExpress(params: any) {
    return axios.ajax({
        url: '/order/updateCustomsExpress',
        method: "post",
        data: params,
    })
}
//修改发货地址
function editaddress(params: any) {
    return axios.ajax({
        url: '/order/editCustomsOrderAddress',
        method: "post",
        data: params,
    })
}
export default {
    getList,
    getActDetails,
    getLogisticsData,
    editOrderExpress,
    updateOrderExpress,
    editaddress,
    importOrderUpdateData
}