import * as React from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import {withAliveScope} from 'react-activation'
import Utils from "../../utils/utils"
import {connect} from 'react-redux';


interface IsProps extends RouteProps {
  tabs: { panes: [], activeKey: string },
  dispatch: any,
  dropScope?: any
}

const PrivateRoute: React.FC<IsProps> = (props: IsProps) => {

  const isAuthenticated = Utils.getToken() ? true : false
  let {component: Component, path = "/", exact = false, strict = false} = props
  let {tabs, dispatch, dropScope} = props
  let {panes = [], activeKey = "/home"} = tabs

  return isAuthenticated ? (
      // KeepAlive 缓存页面, id 按特定条件分成多份缓存
      <Route path={path} exact={exact} strict={strict} render={(props_route) => {
        return (
            <Component {...props_route} />
        )
      }}/>
  ) : <Redirect to={{pathname: "/login"}}/>;
}

const mapStateToProps = (state: { tabs: any; }) => {
  return {
    tabs: state.tabs
  };
};

export default connect(mapStateToProps)(withAliveScope(PrivateRoute))
