import { Row, Col, Select } from 'antd'
import React, { useEffect, useState } from 'react'
// import { PROVINCE, CITY, DISTRICT,STREET } from "../../config/areaConfig"
import Request from "./Request"
import utils from "../../utils/utils";
const { Option } = Select;

let PROVINCE=[]
let CITY=[]
let DISTRICT=[]
let STREET=[]
interface IsProps {
  value?: [string, string, string,string]
  disabled?: boolean
  Township?:boolean
  city?:boolean
  district?:boolean
  type?:number
  valueName?: [string, string, string,string]
  disabledProvince?: string[]
  disabledCity?: string[]
  disabledDistrict?: string[]
  onChange?: (key?: string[], name?: string[]) => void
}

const AreaPicker: React.FC<IsProps> = (props: IsProps) => {

  const {
    value = [undefined, undefined, undefined],
    disabled = false,
    Township = false,
    city = true,
    district = true,
    type = 0,
    valueName= [undefined, undefined, undefined],
    disabledProvince = [],
    disabledCity = [],
    disabledDistrict = [],
    onChange = () => { }
  } = props

  const [provinceKey, setProvinceKey] = useState<string | undefined>(undefined)
  const [provinceName, setProvinceName] = useState<string>("")
  const [provinceList, setProvinceList] = useState<any[]>([])
  const [cityKey, setCityKey] = useState<string | undefined>(undefined)
  const [cityName, setCityName] = useState<string>("")
  const [districtKey, setDistrictKey] = useState<string | undefined>(undefined)
  const [districtName, setDistrictName] = useState<string>("")
  // const [districtName, setDistrictName] = useState<string>("")
  const [townShipKey, setTownShipKey] = useState<string | undefined>(undefined)
  const [townShipList, settownShipList] = useState<any[]>([])
  const [cityList, setcityList] = useState<any[]>([])
  const [districtList, setdistrictList] = useState<any[]>([])

  useEffect(() => {
    console.log(value,"valuevaluevalue")
    if(value[3]){
      getArea(3,value[2])
      setProvinceName(valueName[0])
      setCityName(valueName[1])
      setDistrictName(valueName[2])
    }
    else if(!value[3]&&value[2]){
      getArea(2,value[1])
      setProvinceName(valueName[0])
      setCityName(valueName[1])
    }else{
      getArea(0,' ')
      
    }
    
    // if (value[0] && value[1] && value[2]) {


    //   setProvinceName(getName(PROVINCE, value[0]))
    //   setCityName(getName(cityList, value[1]))

    // }
  }, [])
  const getArea = (t,v) => {
    let params={level:t,code:v}
    console.log(type,12123);
    if (type == 1) {
      Request.getAreaInfo2(params).then(res => {
        setProvinceList(res.data.province_list)
        setcityList(res.data.city_list)
        setdistrictList(res.data.district_list)
        settownShipList(res.data.street_list)
        func()
    })
    }else {
      Request.getAreaInfo(params).then(res => {
        setProvinceList(res.data.province_list)
        setcityList(res.data.city_list)
        setdistrictList(res.data.district_list)
        settownShipList(res.data.street_list)
        func()
    })
    }
  };
  const getName = (list: any[], key: string) => {
    let name = ""
    list.some(item => {
      if (item.key === key) {
        name = item.name
        return true
      }
    })
    return name
  }
  //同步hooks
  const func = utils.useSyncCallback(() => {
    if(value[0]&&value[1]&&value[2]){
      setProvinceKey(value[0]+'')
      setCityKey(value[1]+'')
      setDistrictKey(value[2]+'')
    }
    if(value[3]){
      setTownShipKey(value[3]+'')
    }
  });
  const provinceChange = (value: string, e: any,num) => {
    setProvinceKey(value)
    setProvinceName(e?.children || "")

    setCityKey(undefined)
    setCityName("")
    setDistrictKey(undefined)
    setTownShipKey(undefined)
    // setDistrictName("")
    getArea(num,value)
    //@ts-ignore
    // setcityList(CITY[value] || [])
    //@ts-ignore
    // setdistrictList(DISTRICT[value] || [])
    console.log(value,e?.children)
    onChange([value || "", "", ""], [e?.children || "", "", ""])
  }

  const cityChange = (value: string, e: any,num) => {
    setCityKey(value)
    setCityName(e?.children || "")

    setDistrictKey(undefined)
    setTownShipKey(undefined)
    // setDistrictName("")

    //@ts-ignore
    // setdistrictList(DISTRICT[value] || [])
    getArea(num,value)
    //@ts-ignore
    onChange([provinceKey, value || "", ""], [provinceName, e?.children || "", ""])
  }

  const districtChange = (value: string, e: any,num) => {
    setDistrictKey(value)
    if(Township==true){
      setTownShipKey(undefined)
      setDistrictName(e?.children || "")
  
      //@ts-ignore
      // settownShipList(STREET[value] || [])
      getArea(num,value)
    }
    //@ts-ignore
    onChange([provinceKey, cityKey, value || ""], [provinceName, cityName, e?.children || ""])
  }
  const townShipChange = (value: string, e: any) => {
    setTownShipKey(value)
    //@ts-ignore
    onChange([provinceKey, cityKey,districtKey, value || ""], [provinceName, cityName,districtName, e?.children || ""])
  }
  return (
    <>
      <Row>
        <Col span={6}>
          <Select placeholder="请选择"
            onChange={(v,e)=>provinceChange(v,e,1)}
            value={provinceKey}
            disabled={disabled}
            allowClear
          >
            {
              (() => {
                return provinceList.map(item => {
                  return <Option key={item.code} value={item.code} disabled={disabledProvince.indexOf(item.code) === -1 ? false : true}>{item.name}</Option>
                })
              })()
            }
          </Select>
        </Col>
        {city==true?<Col span={6}>
          <Select placeholder="请选择"
            onChange={(v,e)=>cityChange(v,e,2)}
            value={cityKey}
            disabled={disabled}
            allowClear
          >
            {
              (() => {
                return cityList.map(item => {
                  return <Option key={item.code} value={item.code} disabled={disabledCity.indexOf(item.code) === -1 ? false : true}>{item.name}</Option>
                })
              })()
            }
          </Select>
        </Col>:''}
        {district==true?<Col span={6}>
          <Select placeholder="请选择"
            onChange={(v,e)=>districtChange(v,e,3)}
            value={districtKey}
            disabled={disabled}
            allowClear
          >
            {
              (() => {
                return districtList.map(item => {
                  return <Option key={item.code} value={item.code} disabled={disabledDistrict.indexOf(item.code) === -1 ? false : true}>{item.name}</Option>
                })
              })()
            }
          </Select>
        </Col>:''}
        {Township==true?<Col span={6}>
          <Select placeholder="请选择"
            onChange={townShipChange}
            value={townShipKey}
            disabled={disabled}
            allowClear
          >
            {
              (() => {
                return townShipList.map(item => {
                  return <Option key={item.code} value={item.code}>{item.name}</Option>
                })
              })()
            }
          </Select>
        </Col>:''}
      </Row>
    </>
  )
}

export default AreaPicker