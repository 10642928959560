import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Card, message, Tag } from 'antd';
import EditTable from '../../../../components/Editable';
import Skeleton from '../../../../components/Skeleton';
import Request from "./Request"
import Utils from "../../../../utils/utils"
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  allRole: any[]
}

const UpVersion: React.FC<IProps> = (props: IProps) => {

  const [listData, setListData] = useState<any>([])
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)

  const initList = async () => {
    const res = await Request.getList()
    let { data } = res
    let dataSource = []
    dataSource.push({
      ...data.ios,
      locking: data?.ios?.locking === 1 || data?.mini?.locking === "1" ? true : false
    })
    dataSource.push({
      ...data.android,
      locking: data?.android?.locking === 1 || data?.mini?.locking === "1" ? true : false
    })
    setListData(dataSource)
    setShowSkeleton(false)
  }

  useEffect(() => {
    initList()
  }, [])

  const edit = (data: any[]) => {
    data.forEach((item: any) => {
      delete item.key
      item.locking = item.locking ? 1 : 0
    })
    let config_value: any = {
      ios: data[0],
      android: data[1]
    }
    if (data[2]) {
      config_value.mini = data[2]
    }
    if (data[3]) {
      config_value.alliance = data[3]
    }
    Request.edit({ config_value: JSON.stringify(config_value) }).then(() => {
      message.success("保存成功")
    })
  }

  const columns = [
    {
      title: '名称',
      dataIndex: 'type',
      width: '30%',
    },
    {
      title: '是否锁定',
      dataIndex: 'locking',
      width: '30%',
      editable: "SWITCH",
      render: (locking: boolean) => {
        return locking ? <Tag color="green">是</Tag> : <Tag color="red">否</Tag>
      }
    },
    {
      title: '版本号',
      dataIndex: 'version',
      width: '30%',
      editable: "TEXT"
    }
  ]

  const editCol = Utils.getMenuRole("config/editConfigLockVersion", props.allRole)

  return (
    <Card>
      <Skeleton loading={showSkeleton}>
        {listData.length > 0 && (
          <EditTable
            edit={{ modify: editCol, add: false, del: false }}
            originData={listData}
            columns={columns}
            onChange={(newData) => {
              edit(newData)
            }}
          />
        )}
      </Skeleton>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    allRole: state.allRole
  };
};

export default connect(mapStateToProps)(UpVersion);