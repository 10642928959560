// ------通用配置APP广告类型------
const CONFIG_TARGET_TYPE = {
  brand: "品牌店铺专区",
  haoping: '好评店铺专区',
  jingxuan: "精选好店专区",
  jumpstore: "报单板块专区",
  web: "外部链接",
  good_detail: "商品详情",
  none: "纯展示",
  store: "店铺内页"
};


// ------广告配置------
const SHOW_REGION_TYPE = {
  "1": "首页Banner",
  "2": "首页胶囊",
};

// ------用户列表------
const IDENTITY = {
  "promoters": "推广者",
  "one_alliance": "初级推广员",
  "new_alliance": "高级推广员",
  "team": "团队长",
};

const MEMBER_TYPE = {"0": "普通", "1": "销售"};


// ------订单列表------
const ORDER_STATUS = {
  "1": "待付款",
  "2": "待发货",
  "3": "待收货",
  "4": "已签收",
  "5": "已完成",
  "6": "待退款",
  "7": "已退款",
  "9": "已取消",
};


const GOOD_STATUS = {
  "1": {text: "立即上架", status: "Success"},
  "0": {text: "放入仓库", status: "Processing"},
  "2": {text: "下架", status: "Error"},
};

//提现方式
const IS_WITHDRAW_STATUS = {
  1: {text: "银行卡", status: "Success"},
}

const GOOD_STATUSY = {
  "1": {text: "销售中", status: "success"},
  "0": {text: "仓库中", status: "processing"},
  "2": {text: "已下架", status: "default"},
};

//是否公域池
const IS_UPLOAD_PUBLIC = {
  "1": '是',
  "0": '否',
};


const MEMBER_IS_BIG = {
  0: {text: "否", status: "default"},
  1: {text: "是", status: "Success"},
}
const MEMBER_LEVEL = {
  0: {text: "普通", status: "default"},
  1: {text: "1星", status: "Success"},
  2: {text: "2星", status: "Success"},
  3: {text: "3星", status: "Success"},
  4: {text: "4星", status: "Success"},
  5: {text: "5星", status: "Success"},
};
const iS_FREEZE = {
  0: {text: "正常", status: "Success"},
  1: {text: "冻结", status: "Error"},
};
const MEMBER_VIP = {
  0: {text: "普通", status: "default"},
  1: {text: "VIP", status: "Success"},
  2: {text: "LVIP", status: "Success"},
  3: {text: "SVIP", status: "Success"},
};

const MEMBER_IS_NEW_MEMBER = {
  0: {text: "否", status: "default"},
  1: {text: "是", status: "Success"},
};
const IS_SELECT_RECOMMEND = {
  0: {text: "否", status: "default"},
  1: {text: "是", status: "Success"},
};

// ------资金报表------
const FUND_TARGET_SUBTYPE = {
  101: '+系统充值',
  102: '+提现驳回',
  103: '+商品退货',
  104: '+充值',
  105: '+绿色积分转化余额获得',
  201: '-系统扣除',
  202: '-提现',
  203: '-提现手续费',
  204: '-购买商品',
  205: '-报单',
  206: '-转化绿色积分',
  207: '-转化绿色积分手续费',
};

// ------用户冻积分------
const INTEGRAL_TARGET_SUBTYPE = {
  101: '+系统充值',
  // 102: '+提现驳回',
  103: '+购买商品获得',
  104: '+线下付款获得',

  201: '-系统扣除',
  // 202: '-提现',
  203: '-购买商品退货扣除',
  204: '-每日释放',
};

// ------绿色积分------
const SWEET_BEAN_FUND_TARGET_TYPE = {
  101: '+系统充值',
  102: '+提现驳回',
  103: '+商品退货',
  104: '+报单直推获得',
  105: '+报单管理奖',
  106: '+报单级差奖',
  107: '+报单平级奖励',
  108: '+(线上、线下)级差收益',
  109: '+(线上、线下)五星平级奖励',
  110: '+余额转绿色积分获得',
  111: '+好友转化获得',
  112: '+店铺余额转化获得',
  113: '+用户冻积分释放',
  114: '+开通店主积分释放',
  115: '+商家冻积分释放',

  201: '-系统扣除',
  202: '-提现',
  203: '-提现手续费',
  204: '-购买商品',
  205: '-购买报单',
  206: '-(线上)级差收益-商品退货',
  207: '-(线上)五星平级奖励-商品退货',
  208: '-绿色积分转化余额',
  209: '-绿色积分转化余额手续费',
  210: '-转化好友消耗',
  211: '-转化好友服务费',

};

// ------幸福值------
const BAI_E_JIN_FUND_TARGET_TYPE = {
  101: '+系统充值',
  102: '+提现驳回',
  103: '+商品退货',
  104: '+用户冻积分释放',
  105: '+开通店主积分释放',
  106: '+商家冻积分释放',

  201: '-系统扣除',
  // 202: '-提现',
  // 203: '-提现手续费',
  204: '-购买商品',
  205: '-报单',
};

// ------店铺余额类型------
const RECORD_STORE_FUND_TARGET_TYPE = {
  // 101: '+系统充值',
  102: '+提现驳回',
  103: '+线上售卖商品获得',
  104: '+线下收款',
  // 201: '-系统扣除',
  202: '-提现',
  203: '-线上售卖商品退款扣除',
  204: '-店铺余额转积分',
  205: '-店铺余额转积分手续费',
  206: '-提现手续费'
};


// ------开通店主积分------
const PRODUCT_COUPON_FUND_TARGET_TYPE = {
  101: '+系统充值',
  102: '+提现驳回',
  103: '+报单获得',
  201: '-系统扣除',
  // 202: '-提现',
  203: '-每日释放',
};

// ------商家冻积分积分------
const PREMIUM_COUPON_FUND_TARGET_TYPE = {
  101: '+系统充值',
  // 102: '+提现驳回',
  103: '+售卖买商品获得',
  104: '+线下收款获得',

  201: '-系统扣除',
  // 202: '-提现',
  203: '-售卖买商品退货扣除',
  204: '-每日释放',
};

const GOOD_ITYPE = {
  1: "民族品牌",
  3: "厂家直销",
  4: "兑换专区",
  5: "拼团",
}
const PAY_METHOD = {
  '1': "账户余额",
  '3': "支付宝",
  '4': "微信",
}


//易宝打款(付款)状态
const YIBAO_PAY_STATUS = {
  'REQUEST_RECEIVE': '请求已接收',
  'REQUEST_ACCEPT': '请求已受理',
  'REMITING': '银行处理中',
  'FAIL': '失败',
  'SUCCESS': '成功',
  'CANCELED': '订单撤销',
};

// ------平台分类显示区域------
const CATREGORY_SHOW_TYPE = {
  1: {text: "首页icon区域", status: "Processing"},
  2: {text: "分类店铺", status: "Success"},
  3: {text: "全部", status: "default"},
};

const ONLINE_STATUS = {
  "0": {text: "未付款", status: "default"},
  "1": {text: "已付款", status: "success"},
};


const STORE_STATUSY = {
  "0": {text: "待审核", status: "Error"},
  "1": {text: "审核成功", status: "processing"},
  "2": {text: "审核失败", status: "default"},
};

//易宝打款(付款)状态
const ZLB_PAY_STATUS = {
  0: '未打款',
  1: '打款中',
  2: '打款成功',
  3: '打款失败',
};

//易宝打款退款状态
const REFUND_YIBAO_STATUS = {
  'PROCESSING': '退款处理中',
  'SUCCESS': '退款成功',
  'FAILED': '退款失败',
  'CANCEL': '退款关闭',
  'SUSPEND': '退款中断',
};

export {
  CONFIG_TARGET_TYPE,
  SHOW_REGION_TYPE,
  IDENTITY,
  MEMBER_TYPE,
  ORDER_STATUS,
  GOOD_STATUS,
  FUND_TARGET_SUBTYPE,
  GOOD_STATUSY,
  IS_WITHDRAW_STATUS,
  MEMBER_IS_BIG,
  MEMBER_LEVEL,
  iS_FREEZE,
  MEMBER_VIP,
  MEMBER_IS_NEW_MEMBER,
  IS_SELECT_RECOMMEND,
  GOOD_ITYPE,
  PAY_METHOD,
  INTEGRAL_TARGET_SUBTYPE,
  YIBAO_PAY_STATUS,
  SWEET_BEAN_FUND_TARGET_TYPE,
  BAI_E_JIN_FUND_TARGET_TYPE,
  PRODUCT_COUPON_FUND_TARGET_TYPE,
  PREMIUM_COUPON_FUND_TARGET_TYPE,
  CATREGORY_SHOW_TYPE,
  ONLINE_STATUS,
  STORE_STATUSY,
  ZLB_PAY_STATUS,
  IS_UPLOAD_PUBLIC,
  RECORD_STORE_FUND_TARGET_TYPE,
  REFUND_YIBAO_STATUS
};
